import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';
import { TruckLoadRequestFormData } from '../../data/formdata/truck-load-request-form.data';

@Component({
  selector: 'app-truck-load-request-form-common',
  templateUrl: './truck-load-request-form-common.component.html',
  styleUrls: ['./truck-load-request-form-common.component.css'],
  providers:[AppServiceService]
})
export class TruckLoadRequestFormCommonComponent implements OnInit {

  //private subscription:Subscription;
  region !: string;
  
  constructor(appService:AppServiceService){
this.region=sessionStorage['selectedRegion'];
  }


  ngOnInit():void{
console.log(this.region);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppServiceService } from '../../app/services/app-service.service';
import { UserManagementMasterModel } from '../admin/user-management/user-master-data.model';
import { DropDownMaster } from '../shared/dropdown-master.data';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [UserManagementMasterModel]
})
export class HomeComponent implements OnInit, OnDestroy {
  accessObj = {
    "appAccess": false
  };
  isVendorUser: boolean = sessionStorage.getItem('isVendorUser') == 'true';

  regionsDD: DropDownMaster[] = [];

  selectedRegion: any;
  modesbasedonRegion: any[] = [];
  modes!: any[];
  arrmodes: any = [];
  selectedLanguage: any = sessionStorage.getItem('selectedLanguage');
  private homeSubscriptionName: Subscription; //important to create a subscription
  messageReceived: any;

  constructor(private _appServ: AppServiceService, private router: Router, private userMasterModel: UserManagementMasterModel,
    private translate: TranslateService, private spinners: NgxSpinnerService) {

    this.homeSubscriptionName = this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      this.selectedLanguage = sessionStorage.getItem('selectedLanguage');
      this.bindtransporttypes(sessionStorage.getItem('regionId'));
    });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.homeSubscriptionName.unsubscribe();
  }
  ngOnInit() {
    this.spinners.show()
    this.accessObj = this._appServ.getAccess();
    let bulkTMSUser = sessionStorage.getItem('bulkTMSUser');
    if (bulkTMSUser == null || bulkTMSUser == undefined || bulkTMSUser == "" || bulkTMSUser == "null" || bulkTMSUser == "undefined") {
      let url = "";
      url = window.location.href;
      sessionStorage.setItem('requestPath', url);
      this.router.navigate(['/login']);
    }
    else {
      this.accessObj.appAccess = (sessionStorage.getItem("appAccess") == "true");
      this.spinners.hide();
    }
    if (!this.accessObj.appAccess) {
      this.router.navigate(['/login']);
    }
    this.spinners.hide();
  }

  bindtransporttypes(selectedRegion: any) {
    this.spinners.show();
    this.modesbasedonRegion = [];
    this.modes = [];
    this.arrmodes = [];
    this._appServ.getModesbasedonRegion(Number(sessionStorage['userId']), Number(selectedRegion)).subscribe(response => {
      let apiResponse: any = response;
      let apiResponseResult: any = apiResponse.result;
      if (apiResponseResult.status_id === 200) {
        this.modesbasedonRegion = apiResponse.data;
        this.modes = [];
        this.modes = Array.from(new Set(this.modesbasedonRegion.map((item) => item.ModeType)));

        this.arrmodes = [];
        this.modes.forEach((element: any) => {

          this.arrmodes.push(this.modesbasedonRegion.filter(i => i.ModeType == element))

        });
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinners.hide();
        }, 1000);

      }

    });
  }

  onChange(event: any) {
    this.bindtransporttypes(event.value);
    sessionStorage.setItem('selectedRegion', event.value.label)
    if (this.isVendorUser) {
      this.router.navigate(['/request-list']);
    }
  }
  navigateRaterequest(transportId: number) {
    switch (transportId) {
      case 1:
        this.router.navigate(['/home/truck-load-request-form']);
        break;
      case 2:
        this.router.navigate(['/home/less-than-truckload-request-form']);
        break;
      case 3:
        this.router.navigate(['/home/package-intermodal-request-form']);
        break;
      case 4:
        this.router.navigate(['/home/app-package-container-box-drayage-request-form']);
        break;
      case 5:
        this.router.navigate(['/home/liquid-request-form']);
        break;

      case 6:
        this.router.navigate(['/home/dry-request-form']);
        break;
      case 9:
        this.router.navigate(['/home/rail-rate-request-form']);
        break;
      case 8:
        this.router.navigate(['/home/iso-dryage-truck-request-form']);
        break;

      case 13:
        window.open('https://forms.office.com/r/aKKW7ujLKU', "_blank", "noopener");
        break;
    }

  }
}

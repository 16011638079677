import { Component, HostListener, Injector, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { TransportTypeMasterModel } from "src/app/shared/transport-type-master/transport-type-master.model";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidTruckRequestFormModel } from "../liquid-request-form.model";
import { RequestListModel } from "./request-list.model";
import * as moment from "moment";
import { DryTruckRequestFormModel } from "../dry-request-form.model";
import { TruckLoadRequestFormModel } from "../truck-load-request-form.model";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { RailRequestFormModel } from "../rail-rate-request-form.model";
import { ISODryageTruckFormModel } from "../iso-dryage-truck.model";
import { PackagaContainerBoxRequestFormModel } from "../package-container-box-drayage-form.model";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";
import { Table } from "primeng/table";
import { isNullOrUndefined } from 'src/app/shared/utils/nullorundefined';
import { LessThanTruckloadRequestFormModel } from "../less-than-truckload-request-form.model";
import { PackageIntermodalRequestFormModel } from "../package-intermodal-request-form.model";


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Component(
    {
        selector: 'app-liquid-list-form',
        templateUrl: '..\\shared-templates\\request-list-form-template.html',
        styleUrls: ['..\\shared-templates\\request-list-form-template.css'],
        encapsulation: ViewEncapsulation.None,
        providers: [LoggedInUserInfoModel, TransportTypeMasterModel,
            LiquidTruckRequestFormModel,
            DryTruckRequestFormModel,
            TruckLoadRequestFormModel,
            RailRequestFormModel,
            ISODryageTruckFormModel,
            PackagaContainerBoxRequestFormModel,
            LessThanTruckloadRequestFormModel,
            PackageIntermodalRequestFormModel
        ]
    }
)


export class RequestListComponent implements OnInit {
    @ViewChild('dt1', { read: false }) dt: Table | undefined;
    userAccessPermission!: LoggecInUserInfo;
    transportTypeMaster: DropDownMaster[] = [];
    selectedTransportType!: number;
    requestModel!: RequestListModel;
    filterDate: any;
    isExternalUser: boolean = sessionStorage.getItem("isVendorUser") == "true";
    requestlistCols: any[] = [];
    requestListData: any;
    statusList: any[] = [{ label: 'BU Submitted', value: 'BU Submitted' }, { label: 'Bid In Progress', value: 'Bid In Progress' }, { label: 'Bid Finalized', value: 'Bid Finalized' }]
    private subscriptionName: Subscription; //important to create a subscription
    messageReceived: any;
    paddingvalue: string = '5px';
    screenWidth: number = window.innerWidth;

    constructor(private injector: Injector, private userAccessModel: LoggedInUserInfoModel, private transportTypeModel: TransportTypeMasterModel,
        private router: Router, private spinner: NgxSpinnerService, private _appServ: AppServiceService,) {

        this.subscriptionName = this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
            this.messageReceived = message;
            this.ngOnInit();
        });

    }

    ngOnInit(): void {

        this.spinner.show();
        this.validateloggedInUser();
        this.retrievetransportMaster();
        let region = sessionStorage.getItem('selectedRegion');
        let selectedTransportTypestr = sessionStorage.getItem('list-TransportType')

        if (selectedTransportTypestr == undefined || selectedTransportTypestr == "") { this.selectedTransportType = 1; }
        else {

            this.selectedTransportType = Number(selectedTransportTypestr);
            if (region != 'NA' && this.selectedTransportType > 2) {
                this.selectedTransportType = 1;
            }
        }

        this.retrievedatacollection();

    }


    onTransportTypeChange(event: any) {
        this.spinner.show();
        this.retrievedatacollection();
        sessionStorage.setItem('list-TransportType', this.selectedTransportType.toString());
    }

    retrievedatacollection() {

        this.retrieveformModel();
        this.retrieveHeader();
        this.retrieveRequestData();
    }

    retrieveRequestData() {
        this.requestModel.retrieveRequetcollection(this.selectedTransportType, this.isExternalUser, this.userAccessPermission.userId).subscribe(response => {
            this.requestListData = response;
            console.debug(this.requestListData);
            this.requestListData.forEach((request: any) => {
                request.requestedDate = moment(request.requestedDate).format('YYYY-MM-DD');
                if (this.isExternalUser) {
                    delete request.requestor
                }
            });
            this.dt?.reset();
            this.spinner.hide();
        });

    }
    displayRequestInfo(data: any) {
        if (data.transportTypeId == 1) {
            this.router.navigate(['/request-list/liquid-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 2) {
            this.router.navigate(['/request-list/dry-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 3) {
            this.router.navigate(['/request-list/truck-load-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 4) {
            this.router.navigate(['/request-list/rail-rate-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 5) {
            this.router.navigate(['/request-list/iso-dryage-truck-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 6) {
            this.router.navigate(['/request-list/package-container-box-drayage-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else if (data.transportTypeId == 7) {
            this.router.navigate(['/request-list/less-than-truckload-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        } 
        else if (data.transportTypeId == 8) {
            this.router.navigate(['/request-list/package-intermodal-request-details'], { queryParams: { requestId: btoa(data.requestId) } })
        }
        else {
            this.router.navigate(['/request-list/liquid-request-details'], { queryParams: { requestId: btoa(data.requestId) } })

        }
    }
    retrieveformModel() {
        switch (this.selectedTransportType) {
            case 1:
                this.requestModel = this.injector.get(LiquidTruckRequestFormModel)
                break;
            case 2:
                this.requestModel = this.injector.get(DryTruckRequestFormModel)
                break;
            case 3:
                this.requestModel = this.injector.get(TruckLoadRequestFormModel)
                break;
            case 4:
                this.requestModel = this.injector.get(RailRequestFormModel)
                break;
            case 5:
                this.requestModel = this.injector.get(ISODryageTruckFormModel)
                break;
            case 6:
                this.requestModel = this.injector.get(PackagaContainerBoxRequestFormModel)
                break;
            case 7:
                this.requestModel = this.injector.get(LessThanTruckloadRequestFormModel)
                break;
            case 8:
                this.requestModel = this.injector.get(PackageIntermodalRequestFormModel)
                break;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.retrieveHeader();
    }

    private retrieveHeader() {
        this.requestlistCols = this.requestModel.retrieveListHeader();
        this.screenWidth = window.innerWidth;

        if (this.screenWidth < 1420) {
            this.requestlistCols.forEach((col: any) => {
                col.width = '100px';
                if (col.field == 'status' || col.field == 'id') {
                    col.width = '126px';
                }

            })
        }
        if (this.isExternalUser) {
            this.requestlistCols = this.requestlistCols.filter((col: any) => col.field != 'requestor')
        }
    }
    private retrievetransportMaster() {
        this.transportTypeModel.RetrieveTransportTypeMaster().subscribe(response => {
            this.transportTypeMaster = response;
            if (this.transportTypeMaster.length == 1) {
                this.selectedTransportType = this.transportTypeMaster[0].value;
            }
            this.retrievedatacollection();
        })
    }
    private validateloggedInUser() {
        this.userAccessPermission = this.userAccessModel.retrieveLoggedInUserSitePermission();
        let url1 = "";
        url1 = window.location.href;
        sessionStorage.setItem('requestPath', url1);

        if (this.userAccessPermission.username === undefined || this.userAccessPermission.username === null && this.userAccessPermission.username === "" || this.userAccessPermission.username === " ") {
            this.router.navigate(['/login']);
        }

        this.ValidateUseraccessPermission();

    }

    private ValidateUseraccessPermission() {
        if (!this.userAccessPermission.site_access.appAccess && !this.userAccessPermission.site_access.lpAccess) {
            if (this.userAccessPermission.username == null || this.userAccessPermission.username == undefined
                || this.userAccessPermission.username == "" || this.userAccessPermission.username == "null"
                || this.userAccessPermission.username == "undefined"
                || this.userAccessPermission.business_unit == null || this.userAccessPermission.business_unit == undefined
                || this.userAccessPermission.business_unit == "" || this.userAccessPermission.business_unit == "null"
                || this.userAccessPermission.business_unit == "undefined") {

                this.router.navigate(['/login']);
            } else {
                this.router.navigate(['/home']);
            }
        }

    }
    onStatusChange(event: any) {
        console.log(event);
        const screenWidth = window.innerWidth;
        if (event != undefined) {


            if (screenWidth < 1420 && event.value.length > 0) {
                this.paddingvalue = '7px';
            }
            else {
                this.paddingvalue = '5px !important';
            }
        }
        else if (screenWidth < 1420) {
            this.paddingvalue = '3px';
        }
        else {
            this.paddingvalue = '1px !important';
        }


    }
    onCalendarChange(value: any) {

        this.filterDate = moment(value).format('YYYY-MM-DD').toString();

    }

    exportExcel() {
        this.requestModel.exportRequestList(this.selectedTransportType).subscribe((response: any) => {
            console.log("")
        });
    }

    exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
        let fieldValues: any[] = columnValues.map(field => field.field);
        let headerValues: string[] = columnValues.map(header => header.header);
        let displayedJson: any[] = [];
        if (json != null && json.length > 0) {
            for (let originalValue of json) {
                let jsonObject: any = {};
                for (let fieldValue of fieldValues) {
                    jsonObject[fieldValue] = originalValue[fieldValue];
                }
                let jsonString = JSON.stringify(jsonObject);
                displayedJson.push(JSON.parse(jsonString));
            }
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
            let range = XLSX.utils.decode_range(!isNullOrUndefined(worksheet['!ref']) ? JSON.stringify(worksheet['!ref']) : '');
            for (let C = range.s.c; C <= range.e.c; ++C) {
                let address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
                if (!worksheet[address]) continue;
                worksheet[address] = {
                    v: headerValues[C],
                    t: 's',
                    s: {
                        alignment: { textRotation: 90 },
                        font: { bold: true }
                    }
                };
            }
            XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
            const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, excelFileName);
        }

    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

}

import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { RequestFormData } from "./request-common.data";
import { TruckLoadRequestFormData } from "./truck-load-request-form.data";

export class LessThanTruckloadRequestFormData extends RequestFormData{

  
    equipmentTypeId: number=0;
    equipmentTypeSelectedList: DropDownMaster[]=[];
    equipmentTypeSelectedIds:string="";
        naCountry :string="";
        internModel: string="";
        //Service provider
        days: number=0;
        dropTrail: string="";
        hazardous: string="";
        tempControl: string="";
        unNumber: string="";
        tankerEndorsement: string="";
    
      
      
    
    seaFreightPreLeg:string="";
    seaFreightOnCarriage:string="";
    generalHazmatLoadId:number=0;
    tempControlGeneralHazmat:string="";
    //Service provider
   
    averageWeightOfShipment:number=0;
    averageNumberOfPalletsOrTotesPerShipment:number=0;
    palletDimensions:string="";
   

}

import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { DataFieldVisiblity } from "../data/common/datafieldvisiblity.data";
import { RequestFormData } from "../data/formdata/request-common.data";
import { RequestFormModel } from "../request-form.model";

export abstract class RequestDetailsComponent {
  userAccessPermission: LoggecInUserInfo = new LoggecInUserInfo;
  title: string = "";
  transportType: number = 0;
  description: string = "";
  requestData: RequestFormData = new RequestFormData;
  params: any;
  requestId: number = 0;
  fileDownloaded: boolean = false;
  disableEditMode: boolean = false;
  startDateLable: string = "START_DATE";
  prefferedCarriear: string = "BU/Customer Preferred Carrier";

  isVendorUser: boolean = sessionStorage.getItem("isVendorUser") == "true";

  fieldVisiblity: DataFieldVisiblity = new DataFieldVisiblity();
  constructor(private userAccessModel: LoggedInUserInfoModel, private requestModel: RequestFormModel,
    private toast: ToastrService, private router: Router,
    private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    console.log("")

  }

  downloadAttachment(documentType: any) {
    this.fileDownloaded = false;
    switch (documentType) {
      case "sds":
        this.openpdfEdge(this.requestData.sdsAttachment, this.requestData.sdsFileName, this.requestData.sdsAttchmentFileExtn);
        break;
      case "additionalAttachment":
        this.openpdfEdge(this.requestData.additionalAttchment, this.requestData.additionalFileName, this.requestData.additionalFileExtn);
        break;
      case "additionalAttachment2":
        this.openpdfEdge(this.requestData.additionalAttchment2, this.requestData.additionalFileName2, this.requestData.additionalFileExtn2);
        break;
    }
    this.fileDownloaded = true;
  }

  openpdfEdge(data: any, fileName: any, dataType: any) {

    'use strict';

    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: dataType });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else { // much easier if not IE
      this.downLoadCAttachment(fileName, dataType, data);
    }
  }

  downLoadCAttachment(fName: any, fType: any, fAttachment: any) {
    const downloadLink = document.createElement("a");
    const fileName = fName;

    downloadLink.href = `data:${fType};base64,${fAttachment}`;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  editRequest() {
    this.spinner.show()
    switch (this.transportType) {
      case 1:
        this.router.navigate(['/request-list/liquid-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 2:
        this.router.navigate(['/request-list/dry-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 3:
        this.router.navigate(['/request-list/truck-load-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 4:
        this.router.navigate(['/request-list/rail-rate-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 5:
        this.router.navigate(['/request-list/iso-dryage-truck-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 6:
        this.router.navigate(['/request-list/app-package-container-box-drayage-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 7:
        this.router.navigate(['/request-list/less-than-truckload-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
      case 8:
        this.router.navigate(['/request-list/package-intermodal-request-form/edit'], { queryParams: { reqref: btoa(this.requestId.toString()) } })
        break;
    }

  }
  readQueryString() {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.params.requestId != undefined && this.params.requestId != '') {
        this.requestId = Number(atob(this.params.requestId));
      }

    });


    this.retrieveRequestDetails(this.transportType, this.requestId);

  }


  backToList() {
    this.router.navigate(['/request-list'], { queryParams: { val: 'all' } })
  }
  abstract downLoadFISPQFile(): any;
  reload(event: any) {
    this.retrieveRequestDetails(this.transportType, this.requestId);
  }

  abstract bindConditionalFields(): any

  retrieveRequestDetails(transportType: number, requestId: number) {
    this.spinner.show()
    this.requestModel.retrieveRequestDetails(transportType, requestId).then(response => {
      this.requestData = response;
      this.requestData.costVsService = this.requestData.isCost ? "Cost" : "Service";

      if (this.requestData.requestStatusId == 1 || this.requestData.requestStatusId == 2 || this.requestData.requestStatusId == 5 || this.requestData.requestStatusId == 6) {
        this.disableEditMode = false;
      } else {
        this.disableEditMode = true;
      }

      if (!this.userAccessPermission.site_access.vendorAccess) {
        this.disableEditMode = true;
      }

      if (this.userAccessPermission.site_access.adminAccess && this.requestData.requestStatusId == 7) {
        this.disableEditMode = false;
      }

      if (this.requestData.volumeType == 'Annual') {
        this.fieldVisiblity.annualVolume = true;
      }

      this.bindConditionalFields();
      this.spinner.hide()
    });

  }
  protected validateloggedInUser() {
    this.userAccessPermission = this.userAccessModel.retrieveLoggedInUserSitePermission();

    let url1 = "";
    url1 = window.location.href;
    sessionStorage.setItem('requestPath', url1);

    if (this.userAccessPermission.username === undefined || this.userAccessPermission.username === null && this.userAccessPermission.username === "" || this.userAccessPermission.username === " ") {
      this.router.navigate(['/login-qa']);
    }

    this.ValidateUseraccessPermission();

  }

  private ValidateUseraccessPermission() {
    if (!this.userAccessPermission.site_access.appAccess && !this.userAccessPermission.site_access.lpAccess) {
      if (this.userAccessPermission.username == null || this.userAccessPermission.username == undefined
        || this.userAccessPermission.username == "" || this.userAccessPermission.username == "null"
        || this.userAccessPermission.username == "undefined"
        || this.userAccessPermission.business_unit == null || this.userAccessPermission.business_unit == undefined
        || this.userAccessPermission.business_unit == "" || this.userAccessPermission.business_unit == "null"
        || this.userAccessPermission.business_unit == "undefined") {

        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/home']);
      }
    }
    else {
      this.retrieveLoggedUserInformation()
    }
  }

  private retrieveLoggedUserInformation() {
    let userAccessPermission = this.userAccessModel.retrieveLoggedInUserSitePermission();
    this.requestData.requestor = userAccessPermission.firstname + " " + userAccessPermission.lastname;
    this.requestData.requesterMailId = userAccessPermission.email;
    this.requestData.requestorUserid = userAccessPermission.username;
    this.requestData.businessUnit = userAccessPermission.business_unit;
    this.requestData.modifiedBy = userAccessPermission.userId;
    this.requestData.requestedBy = userAccessPermission.userId;

  }

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PackageIntermodalRequestFormModel } from 'src/app/requests/package-intermodal-request-form.model';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { RequestDetailsComponent } from '../../request-details-form.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppServiceService } from 'src/app/services/app-service.service';
import { PackageIntermodalRequestDetails } from 'src/app/requests/data/formdata/package-intermodal-request-details.data';

@Component({
  selector: 'app-package-intermodal-request-detail-form-na',
   templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
  
    providers: [LoggedInUserInfoModel,
      { provide: RequestFormModel, useClass: PackageIntermodalRequestFormModel }
    ]
  })
  
export class PackageIntermodalRequestDetailFormNaComponent extends RequestDetailsComponent implements OnInit,OnDestroy{
private subscriptionName: Subscription; 
  messageReceived: any;
  pageLanguage:string="";
    override requestData: any; 
 
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { 
          this.messageReceived = message;        
          let newLang=sessionStorage.getItem('selectedLanguage');
          if(this.pageLanguage!=newLang && this.pageLanguage!=""){
            this.ngOnInit();
          }
          });
    }

    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
        this.requestData = new PackageIntermodalRequestDetails();
        this.transportType=8;
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }

    ngOnDestroy() { 
      this.subscriptionName.unsubscribe();
  }
  downLoadFISPQFile(){console.log('')}
  downLoadSpecialReqAttachment(){console.log('')};
    bindfiledVisiblity()
  {
    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.equipmentType=true;
    this.fieldVisiblity.naCountry=true;
    this.fieldVisiblity.articleNumber=true;

    // Service Provider
    this.fieldVisiblity.volumeType=true;
    this.fieldVisiblity.monthlyVolume=true;
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.tempControl=true;
    this.fieldVisiblity.dropTrail=true;
    this.fieldVisiblity.days=true;
    this.fieldVisiblity.hazardous=true;
    this.fieldVisiblity.seasonal=true;
    this.fieldVisiblity.seasonalComments=true;
    this.fieldVisiblity.unNumber=true;
    this.fieldVisiblity.tankerEndorsement=true;

    //Shipping->Orgin
  
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    //shipping-> destination
    
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier=true;
    this.fieldVisiblity.costVsService=true;
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;

    if(this.isVendorUser){
      this.fieldVisiblity.requestor=false;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
    }
    
  }
    retrieveHeaderValues() {
        this.title = "Package Intermodal";
      }
    bindConditionalFields(){
        console.log("")
    }
}

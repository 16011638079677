import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { RequestMasterData } from "./data/masterdata/request-master.data";

import { RequestMasterModel } from "./request-master.model";
import { RequestMasterService } from "./request-master.service";
import { LessThanTruckloadRequestMasterData } from "./data/masterdata/less-than-truckload-request-master.data";
import { DropDownMaster, DropDownRegionMaster } from "../shared/dropdown-master.data";


@Injectable()

export class LessThanTruckloadRequestMasterModel extends  RequestMasterModel
{

    constructor( service:RequestMasterService)
    {
        super(service);
        console.log("constructor")
    }
    public responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
        let datacollection: LessThanTruckloadRequestMasterData = new LessThanTruckloadRequestMasterData();
        datacollection.equipmentTypeMasterCombined=this.convertResponsetoCompleteArray(apiResponse.data.equipementTypeMaster,false);
        datacollection.generalHazmatLoadMaster=this.convertResponsetoArray(apiResponse.data.generalHazmatLoadMaster,false);
        datacollection = this.processCommonResult(apiResponse, datacollection) as LessThanTruckloadRequestMasterData;
        datacollection.generalCountryMaster= datacollection.countryMaster.filter((element:DropDownMaster)=>element.label==='Mexico' || element.label==='Canada');
        datacollection.originCountryMaster= datacollection.generalCountryMaster;
        datacollection.destinationCountryMaster= datacollection.countryMaster;
        subject.next(datacollection);
    }

    protected  convertResponsetoCompleteArray(response:any, isIsasString:boolean):DropDownRegionMaster[]
    {
       let result:DropDownRegionMaster[]=[];
       response.forEach((element:any) => {
          let resultdata=new DropDownRegionMaster();
          if(!isIsasString)
          {
           resultdata.value=Number(element.masterId);
          }
          else
          {
            resultdata.value=(element.masterId);
          }
           resultdata.label=element.masterValue;
           resultdata.name=element.masterName;
           result.push(resultdata);
    
        });
        return result;
    }

}



<div *ngIf="region=='NA'">
    <app-truck-load-request-details-form-na></app-truck-load-request-details-form-na>
  </div>
  <div *ngIf="region=='SA'">
    <app-truck-load-request-details-form-sa></app-truck-load-request-details-form-sa>
  </div>
  <div *ngIf="region=='EMEA'">
    <app-truck-load-request-details-form-emea></app-truck-load-request-details-form-emea>
  </div>
  <div *ngIf="region=='AP'">
    <app-truck-load-request-details-form-ap></app-truck-load-request-details-form-ap>
  </div>

import { Injectable } from "@angular/core";
import { RequestFormModel } from "./request-form.model";
import { RequestListModel } from "./request-list/request-list.model";
import { RequestFormService } from "./request-form.services";
import { LessThanTruckloadRequestFormData } from "./data/formdata/less-than-truckload-request-form.data";
import { Observable, Subject } from "rxjs";
import { RequestFormData } from "./data/formdata/request-common.data";
import { LessThanTruckloadRequestDetails } from "./data/formdata/less-than-truckload-request-details.data";
import { RequestCommonListData } from "./data/request-list/common-request-list.data";
import { LessThanTruckloadRequestListData } from "./data/request-list/less-than-truckload-request-list.data";


@Injectable()
export class LessThanTruckloadRequestFormModel extends RequestFormModel implements RequestListModel
{
    constructor(service: RequestFormService) {
        super(service);
        console.log("constructor")
    }
    retrieveListHeader():any {
        let  requestDetailCols:any = [
              
              { field: "referenceNumber", header: "ID", width: "5vw" },
              { field: "equipmentType", header: "EQUIPMENT_TYPE", width: "6vw" },
              { field: "requestType", header: "REQUEST_TYPE", width: "8vw" },
              { field: "requestor", header: "REQUESTOR", width: "7vw" },
              { field: "businessUnit", header: "BU", width: "4vw" },
              { field: "product", header: "PRODUCT", width: "8vw" },
              { field: "orgin", header: "ORIGIN", width: "8vw" },
              { field: "destination", header: "DESTINATION", width: "8vw" },
              { field: "status", header: "STATUS", width: "8vw" },
             { field: "requestedDate", header: "DATE", width: "6vw"}
            ];
            return requestDetailCols;
    }
    createRequest(data: LessThanTruckloadRequestFormData): Observable<string> {
        let subject = new Subject<string>();
        this.insertRequest(data).subscribe({
            next:result => { subject.next(result) },
            error:error => { subject.error(error) }
        }
        );
        return subject.asObservable();
    }

    OnRequestDetailSuccess(apiResponse: any,
        resolve: (value: RequestFormData | PromiseLike<RequestFormData>) => void) {
       let data: LessThanTruckloadRequestDetails = apiResponse.data as LessThanTruckloadRequestDetails;
       resolve(data);
   }

      
   responseOnSuccessRequestList(apiResponse: any, subject: Subject<RequestCommonListData[]>) {
    let requestCollection: LessThanTruckloadRequestListData[] = [];
   
    apiResponse.data.forEach((element:any) => {
        requestCollection.push(element as LessThanTruckloadRequestListData);
    });

    subject.next(requestCollection);
}


  


}

import { Subject } from "rxjs";
import { RequestMasterData } from "./data/masterdata/request-master.data";
import { RequestMasterModel } from "./request-master.model";
import { Injectable } from "@angular/core";
import { RequestMasterService } from "./request-master.service";
import { PackageIntermodalRequestMasterData } from "./data/masterdata/package-intermodal-request-master.data";

@Injectable()
export class PackageIntermodalRequestMasterModel extends RequestMasterModel {


    constructor( service:RequestMasterService)
        {
            super(service);
            console.log("constructor")
        }


    public responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
          let datacollection: PackageIntermodalRequestMasterData = new PackageIntermodalRequestMasterData ();
                datacollection.equipementTypeMaster=this.convertResponsetoArray(apiResponse.data.equipementTypeMaster,false);
                datacollection = this.processCommonResult(apiResponse, datacollection) as PackageIntermodalRequestMasterData ;
                subject.next(datacollection);
    }


}

import { PackageIntermodalVendorResponseData } from "./package-intermodal-vendor-response.data";

export class PackageIntermodalVendorResponseFormData extends PackageIntermodalVendorResponseData {
    
    disable: boolean = false;
    validBidAmount: boolean = true;
    validComments: boolean = true;
    validCurrency: boolean = true;
    validRank: boolean = true;
    validRank2 :boolean =true;
    validVendor: boolean = true;
    validDueDate:boolean = true;
}

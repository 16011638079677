import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-less-than-truckload-request-form-common',
  templateUrl: './less-than-truckload-request-form-common.component.html',
  styleUrls: ['./less-than-truckload-request-form-common.component.css'],
  providers:[AppServiceService]
})
export class LessThanTruckloadRequestFormCommonComponent implements OnInit,OnDestroy {
  private subscriptionName: Subscription; 
  messageReceived: any;
  region !: string;

  constructor(private _appServ: AppServiceService) { 
     this.subscriptionName= this._appServ.getUpdate().subscribe(message => {
    this.messageReceived = message;
    this.region=sessionStorage['selectedRegion'];
    

    });
  }
  ngOnDestroy() { 
    this.subscriptionName.unsubscribe();
}
  ngOnInit() {
    console.log('')
  }


}

import { LessThanTruckloadVendorResponseData } from "./less-than-truckload-vendor-response.data";

export class LessThanTruckloadVendorResponseFormData extends LessThanTruckloadVendorResponseData {
    disable: boolean = false;
    validBidAmount: boolean = true;
    validComments: boolean = true;
    validCurrency: boolean = true;
    validRank: boolean = true;
    validRank2 :boolean =true;
    validVendor: boolean = true;
    validDueDate:boolean = true;
}

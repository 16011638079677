import { DropDownMaster, DropDownRegionMaster } from "src/app/shared/dropdown-master.data";
import { RequestMasterData } from "./request-master.data";

export class LessThanTruckloadRequestMasterData extends RequestMasterData {

    equipmentTypeMasterCombined:DropDownRegionMaster[]=[];
    equipementTypeMaster:DropDownMaster[]=[];
    generalHazmatLoadMaster:DropDownMaster[]=[];
    generalCountryMaster:DropDownMaster[]=[];
    originCountryMaster:DropDownMaster[]=[];
    destinationCountryMaster:DropDownMaster[]=[];
}

<div *ngIf="userAccessPermission.site_access.requestAccess" class="container-fluid">

    <div class="container container-Top">
        <div class="req-detail-form">
            <!-- Heading -->
            <h5>
                {{title | translate}} {{'REQ_DETAILS' | translate}}
            </h5>
            <div class="row align-items-center req-hint-div">
                <div class="col-md-12 row justify-content-between pe-0">

                    <span *ngIf="requestData.requestRefNumber != ''" class="ms-3 mt-1 col-md-4"
                        style="font-weight: 500; font-size: 1.1em;">{{requestData.requestRefNumber}}</span>

                    <span class="me-1 col-md-4 pe-0">
                        <button *ngIf="requestData.requestRefNumber != ''" pButton type="button"
                            label="{{'BACK' | translate}}" class="p-button-outlined p-button-secondary me-1 float-right"
                            icon="pi pi-arrow-left" (click)="backToList()"></button>
                        <button pButton type="button" label="{{'EDIT' | translate}}" *ngIf="((requestData.requestRefNumber != '') && 
                    ((requestData.requestStatusId == 1)))"
                            class="p-button-outlined p-button-secondary me-1 float-right" icon="pi pi-pencil"
                            (click)="editRequest()"></button>
                    </span>
                    <!-- || (userAccessPermission.site_access.adminAccess == true && requestData.requestStatusId == 7) -->


                </div>
            </div>
            <!--End of Heading-->

            <div class="formDiv">

                <p-accordion [multiple]="true">
                    <!-- General Info Start -->
                    <p-accordionTab header="{{'GEN_INFO' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <div class="form-group wrap-div">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="requestTypelable" class="col-form-label cust-label ">
                                                {{'REQ_STATUS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="requestTypeStatus" class="col-form-label cust-label-val">
                                                {{ requestData.status}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- left -->


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requestType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="requestTypelable" class="col-form-label cust-label ">
                                                {{'REQUEST_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="requestType" class="col-form-label cust-label-val">
                                                {{ requestData.requestType}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.naCountry">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="naCountrylable" class="col-form-label cust-label ">
                                                {{'COUNTRY' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="naCountry" class="col-form-label cust-label-val">
                                                {{ requestData.naCountry}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.startDate">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="startDateLabel" class="col-form-label cust-label ">
                                                {{startDateLable | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="startDate" class="col-form-label cust-label-val">
                                                {{requestData.startDate| date: 'yyyy-MM-dd' }}
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requestor">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="requestorlabel" class="col-form-label cust-label ">
                                                {{'REQUESTOR' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="requestor" class="col-form-label cust-label-val">
                                                {{requestData.requestor}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.bussinessunit">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="businessUnitLabel" class="col-form-label cust-label">
                                                {{'BUSINESS_UNIT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="businessUnit" class="col-form-label cust-label-val">
                                                {{requestData.businessUnit}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.articleNumber">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="articleNumberLabel" class="col-form-label cust-label">
                                                {{'ARTICLE_NUMBER'| translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="articleNumber" class="col-form-label cust-label-val">
                                                {{requestData.articleNumber}}
                                            </label>

                                        </div>
                                    </div>
                                </div>


                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.product">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="productLabel" class="col-form-label cust-label">
                                                {{'PRODUCT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="product" class="col-form-label cust-label-val">
                                                {{requestData.product}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.mfg||fieldVisiblity.mfgDescription">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label" *ngIf="fieldVisiblity.mfg">
                                            <label for="Mfg" class="col-form-label cust-label">{{'MFG'|
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-3" *ngIf="fieldVisiblity.mfgDescription">
                                            <label for="Mfg" class="col-form-label cust-label">{{'MFG_DESCRIPTION' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="Mfg" class="col-form-label cust-label-val">
                                                {{requestData.mfg}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.freightClass">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="freightClasslabel" class="col-form-label cust-label">
                                                {{'FREIGHT_CLASS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="freightClass" class="col-form-label cust-label-val">
                                                {{requestData.freighClass}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaninCost">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="cleaningCostlabel" class="col-form-label cust-label">
                                                {{'CLEANING_COST' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="cleaningCost" class="col-form-label cust-label-val">
                                                {{requestData.cleaningCost}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.packageType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="packageTypelabel" class="col-form-label cust-label">
                                                {{'PACKAGE_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="packageType" class="col-form-label cust-label-val">
                                                {{requestData.packageType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.remarkPackageType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="remarkPackageTypelabel" class="col-form-label cust-label">
                                                {{'REMARK_PACKAGE_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="remarkPackageType" class="col-form-label cust-label-val">
                                                {{requestData.remarkPackageType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.shippingWithPallet">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="shippingWithPalletlabel" class="col-form-label cust-label">
                                                {{'SHIPPING_WITH_PALLET' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="shippingWithPallet" class="col-form-label cust-label-val">
                                                {{requestData.shippingWithPallet}}
                                            </label>

                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.palletSize">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="palletSizelabel" class="col-form-label cust-label">
                                                {{'PALLET_SIZE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="palletSize" class="col-form-label cust-label-val">
                                                {{requestData.palletSize}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ftlOrLtl">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="ftlOrLtllabel" class="col-form-label cust-label">
                                                {{'FTL_LTL' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="ftlOrLtl" class="col-form-label cust-label-val">
                                                {{requestData.ftlOrLtl}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.equipmentType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="equipmentTypelable" class="col-form-label cust-label ">
                                                {{'EQUIPMENT_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div *ngIf="!fieldVisiblity.equipmentTypeMultiSelect" class="col-md-9">

                                            <label for="equipmentType" class="col-form-label cust-label-val ">
                                                {{ requestData.equipmentType}}
                                            </label>
                                        </div>
                                        <div *ngIf="fieldVisiblity.equipmentTypeMultiSelect" class="col-md-9">

                                            <label for="equipmentType" class="col-form-label cust-label-val ">
                                                {{ requestData.equipmentTypeMultiple}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ftlTruckType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="ftlTruckTypelabel" class="col-form-label cust-label">
                                                {{'FTL_TRUCK_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="ftlTruckType" class="col-form-label cust-label-val">
                                                {{requestData.ftlTruckType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ltlRange">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="ltlRangelabel" class="col-form-label cust-label">
                                                {{'LTL_RANGE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="ltlRange" class="col-form-label cust-label-val">
                                                {{requestData.ltlRangeSelectedNames}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.allowTwoLayerStack">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="allowTwoLayerStacklabel" class="col-form-label cust-label">
                                                {{'ALLOW_TWO_LAYER_STACK' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="allowTwoLayerStack" class="col-form-label cust-label-val">
                                                {{requestData.allowTwoLayerStack}}
                                            </label>

                                        </div>
                                    </div>
                                </div>




                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.equipmentModel">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="equipmentModellable" class="col-form-label cust-label ">
                                                {{'EQUIPMENT_MODEL' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="equipmentModel" class="col-form-label cust-label-val ">
                                                {{ requestData.equipmentModel}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.modal">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="modallable" class="col-form-label cust-label ">
                                                {{'MODAL' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="modal" class="col-form-label cust-label-val ">
                                                {{ requestData.modal}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.interModel">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="intermodel" class="col-form-label cust-label">
                                                {{'IS_INTERMODAL_ACCEPT' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="equipmentType" class="col-form-label cust-label-val ">
                                                {{ requestData.internModel | translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.intermodalType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="intermodalTypelabel" class="col-form-label cust-label">
                                                {{'INTERMODAL_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="intermodalType" class="col-form-label cust-label-val">
                                                {{requestData.intermodalType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.containerType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="containerTypelabel" class="col-form-label cust-label">
                                                {{'CONTAINER_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="containerType" class="col-form-label cust-label-val">
                                                {{requestData.containerType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.seaFreightPreLeg">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="seaFreightPreLeglbl" class="col-form-label cust-label">
                                                {{'IS_THIS_A_SEA_FREIGHT_PRE_LEG' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="seaFreightPreLeg" class="col-form-label cust-label-val ">
                                                {{ requestData.seaFreightPreLeg | translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.seaFreightOnCarriage">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="seaFreightOnCarriagelbl" class="col-form-label cust-label">
                                                {{'IS_THIS_A_SEA_FREIGHT_ON_CARRIAGE' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="seaFreightOnCarriage" class="col-form-label cust-label-val ">
                                                {{ requestData.seaFreightOnCarriage | translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.generalHazmatLoad">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="generalHazmatLoadlable" class="col-form-label cust-label ">
                                                {{'IS_GENERAL_HAZMAT_LOAD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="generalHazmatLoad" class="col-form-label cust-label-val ">
                                                {{ requestData.generalHazmatLoad}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempControlGeneralHazmat">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="tempControlGeneralHazmatlbl" class="col-form-label cust-label">
                                                {{'IS_THIS_TEMP_CONTROL_GENERAL_HAZMAT' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="tempControlGeneralHazmat"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.tempControlGeneralHazmat| translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.railCarType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="railCarType" class="col-form-label cust-label ">
                                                {{'RAIL_CAR_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="railCarType" class="col-form-label cust-label-val ">
                                                {{requestData.railCartType}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.payerOption">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="payerOption" class="col-form-label cust-label">
                                                {{'PAYER_OPTION' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="payerOption1" class="col-form-label cust-label-val">
                                                {{requestData.updatedPayerOptiob}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.typeOfShipment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="typeOfShipment" class="col-form-label cust-label">
                                                {{'TYPE_OF_SHIPMENT' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="typeOfShipment" class="col-form-label cust-label-val">
                                                {{requestData.typeOfShipment | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.monthlyKms">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="monthlyKms" class="col-form-label cust-label">
                                                {{'MONTHLY_KMS' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="monthlyKms" class="col-form-label cust-label-val">
                                                {{requestData.monthlyKms}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.preferedTransportMode">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="preferedTransportMode" class="col-form-label cust-label">
                                                Prefered Transport Mode
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="preferedTransportMode" class="col-form-label cust-label-val">
                                                {{requestData.preferedTransportModeIdValue}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.twoStepBusiness">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="twoStepBusiness" class="col-form-label cust-label">
                                                2 Step Business
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="twoStepBusiness" class="col-form-label cust-label-val">
                                                {{requestData.twoStepBusiness}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <!-- label -->
                                        </div>
                                        <div class="col-md-9">
                                            <!-- control -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </p-accordionTab>
                    <!-- General Info end -->

                    <!-- Service Provided Req Start -->
                    <p-accordionTab header="{{'SERVICE_PROV_REQ' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.volumeType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="campaignSeasonalVolume"
                                                class="col-form-label cust-label">{{'VOLUME_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="campaignSeasonalVolume" class="col-form-label cust-label-val">
                                                {{requestData.volumeType | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="requestData.volumeType=='Campaign'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="csVolume" class="col-form-label cust-label ">
                                                {{'CAMPAIGN_SEA_VOLUME' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="csVolume" class="col-form-label cust-label-val">
                                                    {{requestData.campaignSeasonalVolume}}
                                                </label>
                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="csVolumeUOM" class="col-form-label cust-label-val">
                                                    {{requestData.campaignSeasonalVolumeUidValue}}
                                                </label>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group entire-campaign-label"
                                    *ngIf="requestData.volumeType=='Campaign'">
                                    <div class="row align-items-center">
                                        <div class="col-md-12">
                                            <label for="csVolumeTiming" class="col-form-label">{{'CS_VOLUME_TIMING' |
                                                translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="requestData.volumeType=='Campaign'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="csStartDatelbl" style="text-align:left"
                                                class="col-form-label cust-label ">{{'START_DATE' | translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="startDate" class="col-form-label cust-label-val">
                                                {{requestData.csStartDate | date: 'yyyy-MM-dd'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- left -->


                                <div class="form-group wrap-div" *ngIf="requestData.volumeType=='Campaign'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="csEndDatelbl" style="text-align:left"
                                                class="col-form-label cust-label ">{{'END_DATE' | translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="csEndDate" class="col-form-label cust-label-val">
                                                {{requestData.csEndDate | date: 'yyyy-MM-dd'}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->

                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.annualVolume">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="annualVolumelbl" class="col-form-label cust-label">
                                                {{'ANNUAL_VOLUME' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="annualVolume" class="col-form-label cust-label-val">
                                                    {{requestData.annualVolume}}
                                                </label>

                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">

                                                <label for="annualVolumeUOM" class="col-form-label cust-label-val">
                                                    {{requestData.annualVolumeUnit}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="requestData.volumeType =='Annual'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="annualVolumeStartDate" class="col-form-label cust-label">
                                                {{'ANNUAL_VOLUME_START' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="annualVolumeStartDate" class="col-form-label cust-label-val">
                                                {{requestData.annualVolumeStartDate | date: 'yyyy-MM-dd'}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.monthlyVolume">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="monthlyVolume" class="col-form-label cust-label ">
                                                {{'MONTHLY_VOLUME' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="monthlyVolume" class="col-form-label cust-label-val">
                                                    {{requestData.monthlyVolume}}
                                                </label>
                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="monthlyVolumeUOM" class="col-form-label cust-label-val">
                                                    {{requestData.monthlyVolumeUnit}}
                                                </label>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.totalWeightShipment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="totalWeightShipmentlbl" class="col-form-label cust-label">
                                                {{'TOTAL_WEIGHT_SHIPMENT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="totalWeightShipment" class="col-form-label cust-label-val">
                                                    {{requestData.totalWeightShipment}}
                                                </label>

                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">

                                                <label for="totalWeightShipmentUOM"
                                                    class="col-form-label cust-label-val">
                                                    {{requestData.totalWeightShipmentUidValue}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.centerRearUnload">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="unloadlbl" class="col-form-label cust-label">
                                                {{'CEN_REAR_UNLOAD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="unload" class="col-form-label cust-label-val">
                                                {{requestData.centerRearUnload | translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dropContainerId">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label ">
                                            <label for="dropContainer" class="col-form-label cust-label">
                                                {{'DROP_CONTAINER' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="dropContainer" class="col-form-label cust-label-val">
                                                {{requestData.dropContainer}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dropTrail||fieldVisiblity.days">
                                    <div class="row align-items-center">
                                        <div class="col-md-6" *ngIf="fieldVisiblity.dropTrail">
                                            <div class="row align-items-center">

                                                <div class="col-md-6">
                                                    <label for="dropTrailerlbl" class="col-form-label cust-label">
                                                        {{'DROP_TRAILER' | translate}}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="dropTrailer" class="col-form-label cust-label-val">
                                                        {{requestData.dropTrail | translate}}
                                                    </label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6" *ngIf="fieldVisiblity.days">
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <label for="dayslbl" class="col-form-label cust-label">
                                                        {{'HOW_MANY_DAYS' | translate}}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="days" class="col-form-label cust-label-val">
                                                        {{requestData.days}}
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.shipmentFrequency">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="shipmentFrequencylbl" class="col-form-label cust-label">
                                                {{'SHIPMENT_FREQUENCY' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="shipmentFrequency" class="col-form-label cust-label-val">
                                                {{requestData.shipmentFrequency}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempRequirement">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="TempReqlbl" class="col-form-label cust-label">
                                                {{'TEMP_REQ' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="TempReq" class="col-form-label cust-label-val">
                                                {{requestData.tempRequirement}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.deliveryType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="TempReqlbl" class="col-form-label cust-label">
                                                {{'DELIVERY_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="DeliveryType" class="col-form-label cust-label-val">
                                                {{requestData.deliveryTypeName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempRange">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="tempRangelbl" class="col-form-label cust-label">
                                                {{'TEMP_RANGE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="tempRange" class="col-form-label cust-label-val">
                                                {{requestData.tempRange}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.driverSupportRequired">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label ">
                                            <label for="driverSupportRequiredLabel" class="col-form-label cust-label">
                                                {{'DROP_CONTAINER' | translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="driverSupportRequired" class="col-form-label cust-label-val">
                                                {{requestData.driverSupportRequired}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.priorProductRestriction">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="prodRestrictionsLbl" class="col-form-label cust-label">
                                                {{'PRIOR_PROD_RESTR' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="prodRestrictions" class="col-form-label cust-label-val">
                                                {{requestData.priorProductRestriction | translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="requestData.priorProductRestriction=='Yes' && fieldVisiblity.priorProdDetailsRestrictions">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="prioProdDetailsRestrictionsLbl"
                                                class="col-form-label cust-label">
                                                {{'PRIOR_PROD_DETAILS_RESTRICTIONS' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="prodRestrictions" class="col-form-label cust-label-val">
                                                {{requestData.priorProdDetailsRestrictions | translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempControl">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="tempControllbl" class="col-form-label cust-label">
                                                {{'TEMP_CONTROl' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="tempControl" class="col-form-label cust-label-val">
                                                {{requestData.tempControl | translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.averageWeightOfShipment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="averageWeightOfShipmentlbl" class="col-form-label cust-label">
                                                {{'AVERAGE_WEIGHT_OF_SHIPMENT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="averageWeightOfShipment" class="col-form-label cust-label-val">
                                                {{requestData.averageWeightOfShipment}}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="averageNumberOfPalletsOrTotesPerShipmentlbl"
                                                class="col-form-label cust-label">
                                                {{'AVERAGE_NUMBER_OF_PALLETS_OR_TOTES_PER_SHIPMENT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="averageNumberOfPalletsOrTotesPerShipment"
                                                class="col-form-label cust-label-val">
                                                {{requestData.averageNumberOfPalletsOrTotesPerShipment}}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.palletDimensions">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="palletDimensionslbl" class="col-form-label cust-label">
                                                {{'PALLET_DIMENSIONS (LENGTH x WIDTH x HEIGHT)' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="palletDimensions" class="col-form-label cust-label-val">
                                                {{requestData.palletDimensions}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.hazardous">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="hazardousLbl" class="col-form-label cust-label">
                                                {{'HAZARDOUS' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">

                                            <Label for="hazardous" class="col-form-label cust-label-val">
                                                {{requestData.hazardous | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.unNumber && requestData.hazardous=='Yes'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="UnNumber" class="col-form-label cust-label">
                                                {{'UN_NUMBER' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="UnNum" class="col-form-label cust-label-val">
                                                {{requestData.unNumber}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.hazmatclassId">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="hazmatclassId"
                                                class="col-form-label cust-label">{{'HAZMAT_CLASS' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="hazmatclassId" class="col-form-label cust-label-val">
                                                {{requestData.hazardousClass}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.seasonal">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="seasonal" class="col-form-label cust-label">
                                                {{'SEASONAL' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">

                                            <Label for="seas" class="col-form-label cust-label-val">
                                                {{requestData.seasonal | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tankerEndorsement">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="tankerEndorsement" class="col-form-label cust-label">
                                                {{'TANKER_ENDORSEMENT' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">

                                            <Label for="tankerEndorsement" class="col-form-label cust-label-val"
                                                *ngIf="requestData.tankerEndorsement=='Yes'">
                                                {{'LIQUID_MATERIAL' | translate}}
                                            </Label>
                                            <Label for="tankerEndorsement" class="col-form-label cust-label-val"
                                                *ngIf="requestData.tankerEndorsement=='No'">
                                                {{'DRY_MATERIAL' | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.valueOfInvoice">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="valueOfInvoicelbl" class="col-form-label cust-label">
                                                {{'VALUE_OF_INVOICE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="valueOfInvoice" class="col-form-label cust-label-val">
                                                {{requestData.valueOfInvoice}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.seasonalComments && requestData.seasonal=='Yes'">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="SeasonalComments" class="col-form-label cust-label">
                                                {{'SEASONAL_COMMENTS' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">

                                            <Label for="seascom" class="col-form-label cust-label-fullval">
                                                {{requestData.seasonalComments}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.kosher">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="Kosherlbl" class="col-form-label cust-label">{{'KOSHER' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="Kosher" class="col-form-label cust-label-val">
                                                {{requestData.kosher | translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.materialTopLoad">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="topLoadingMaterialLbl" class="col-form-label cust-label">
                                                {{'MAT_TOP_LOAD' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="topLoadingMaterial" class="col-form-label cust-label-val">
                                                {{requestData.materialTopLoad | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.foodGradeWash">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="foodGradeWashlbl" class="col-form-label cust-label">
                                                {{'FOOD_GRADE_WASH' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="foodGradeWash" class="col-form-label cust-label-val">
                                                {{requestData.foodGradeWash |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.loadingHrs">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="loadingHrslbl" class="col-form-label cust-label">
                                                {{'LOADING_HRS' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">

                                            <Label for="loadingHrs" class="col-form-label cust-label-val">
                                                {{requestData.loadingHours}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->

                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.unloadingHrs">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="unLoadingHrslbl" class="col-form-label cust-label">
                                                {{'UNLOADING_HRS' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="unLoadingHrs" class="col-form-label cust-label-val">
                                                {{requestData.unloadingHour}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specificEquipmentType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="specificEquipmentTypelable" class="col-form-label cust-label ">
                                                Vehicle Type Required
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="specificEquipmentType" class="col-form-label cust-label-val ">
                                                {{ requestData.specificEquipmentTypeIdValue}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.nitrogenBlanket">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="nitrogenBlanketlbl" class="col-form-label cust-label">
                                                {{'NITRO_BLANKET' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="nitrogenBlanket" class="col-form-label cust-label-val">
                                                {{requestData.nitrogenBlanket | translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.weekendDelivery||fieldVisiblity.weekendDeliveryYN">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="weekendDeliverylbl" class="col-form-label cust-label">
                                                {{'WEEKEND_DELIVERY' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="weekendDelivery" class="col-form-label cust-label-val">
                                                {{requestData.weekendDelivery | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.groundVapourRecovery||fieldVisiblity.centerRear">
                                    <div class="row align-items-center">
                                        <div class="col-md-3" *ngIf="fieldVisiblity.groundVapourRecovery">
                                            <Label for="groundVapourRecoverylbl" class="col-form-label cust-label">
                                                {{'GROUND_VAP_RECOVERY' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-3" *ngIf="fieldVisiblity.groundVapourRecovery">
                                            <Label for="groundVapourRecovery" class="col-form-label cust-label-val">
                                                {{requestData.groundVapourDelivery | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-3" *ngIf="fieldVisiblity.centerRear">
                                            <Label for="centerOrRearlbl" class="col-form-label cust-label">
                                                {{'CENTER_REAR' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-3" *ngIf="fieldVisiblity.centerRear">
                                            <Label for="centerOrRear" class="col-form-label cust-label-val">
                                                {{requestData.centerRear}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.vacuumTrailer">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="VaccumTrail" class="col-form-label cust-label">
                                                {{'VACC_TRAILER' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="VaccumTrail" class="col-form-label cust-label-val">
                                                {{requestData.vaccumeTrailer | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.numberOfRailCarsRequired">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="railCarsRequired" class="col-form-label cust-label">
                                                {{'NO_RAIL_CARS_REQ' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="railCarsRequired" class="col-form-label cust-label-val">
                                                {{requestData.numberOfRailCarsRequired}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isMaterialPackagedLiquidInTotes">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="isMaterialPackagedLiquidInTotes"
                                                class="col-form-label cust-label">{{'IS_MAT_PACK_AS_LIQUID' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">

                                            <label for="isMaterialPackagedLiquidInTotes"
                                                class="col-form-label cust-label-val">
                                                {{requestData.isMaterialPackagedLiquidInTotes | translate}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaningProcedures">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="cleaningProcedures"
                                                class="col-form-label cust-label">{{'CLEANING_PROCEDURES' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">

                                            <label for="cleaningProcedures" class="col-form-label cust-label-fullval">
                                                {{requestData.cleaningProcedures}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.mandDocsInsideTruck">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="mandateDocsInsideTruck"
                                                class="col-form-label cust-label">{{'MANDAT_DOCS_INSIDE_TRUCK' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;display: inline-flex;">

                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="mandateDocsInsideTruck"
                                                    class="col-form-label cust-label-fullval">
                                                    {{requestData.mandadoryDocsInsideTruck | translate}}
                                                </label>

                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">

                                                <label for="mandatoryDocumentsInsideTruck"
                                                    class="col-form-label cust-label-val">
                                                    {{requestData.mandatoryDocsInsideTruckData}}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 attach" *ngIf="fieldVisiblity.mandDocsInsideTruckDoc">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="mandDocsInsideTruckDoc"
                                                class="cust-label col-form-label">{{'FISPQ_ATTACHMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <a *ngIf="requestData.FISPQFileName != null && requestData.FISPQFileName != undefined && requestData.FISPQFileName != ''"
                                                style="cursor: pointer;color: blue;"
                                                (click)="downLoadFISPQFile()">{{requestData.FISPQFileName}}</a>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.averageProduct">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="averageProduct"
                                                class="col-form-label cust-label">{{'AVERAGE_PRODUCT' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;display: inline-flex;">

                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <label for="averageProduct" class="col-form-label cust-label-fullval">
                                                    {{requestData.averageProduct}}
                                                </label>

                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">

                                                <label for="averageProductUOM" class="col-form-label cust-label-val">
                                                    {{requestData.averageProductIdValue}}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.pump">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="pumplable" class="col-form-label cust-label ">
                                                {{'PUMP' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="pump" class="col-form-label cust-label-val ">
                                                {{ requestData.pump}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.chassis">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="chassislable" class="col-form-label cust-label ">
                                                {{'CHASSIS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="chassis" class="col-form-label cust-label-val ">
                                                {{ requestData.chassisName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.groundOperatingEquipment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="groundOperatingEquipmentlable"
                                                class="col-form-label cust-label ">
                                                Ground Operating Equipment
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="groundOperatingEquipment"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.groundOperatingEquipment}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.onlyOnecompartments">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="onlyOnecompartmentslable" class="col-form-label cust-label ">
                                                Only 1 Compartments
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="onlyOnecompartments" class="col-form-label cust-label-val ">
                                                {{ requestData.onlyOnecompartments}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.couplings">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="couplingslbl" class="col-form-label cust-label">
                                                {{'COUPLINGS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="couplings" class="col-form-label cust-label-val">
                                                {{requestData.couplings}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.loadingPoint">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="loadingPointlable" class="col-form-label cust-label ">
                                                {{'LOADING_POINT' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="loadingPoint" class="col-form-label cust-label-val ">
                                                {{ requestData.loadingPointName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.gmp">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="gmplbl" class="col-form-label cust-label">
                                                {{'GMP' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="gmp" class="col-form-label cust-label-val">
                                                {{requestData.gmp |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.fami">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="familbl" class="col-form-label cust-label">
                                                {{'FAMI' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="fami" class="col-form-label cust-label-val">
                                                {{requestData.fami |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isopa">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="isopalbl" class="col-form-label cust-label">
                                                {{'ISOPA' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="isopa" class="col-form-label cust-label-val">
                                                {{requestData.isopa |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.halalWash">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="halalWashlbl" class="col-form-label cust-label">
                                                {{'HALAL_WASH' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="halalWash" class="col-form-label cust-label-val">
                                                {{requestData.halalWash |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.unloadingHose">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="unloadingHoselbl" class="col-form-label cust-label">
                                                {{'UNLOADING_HOSE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="unloadingHose" class="col-form-label cust-label-val">
                                                {{requestData.unloadingHose}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.onboardCompressor">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <Label for="onBoardCompressorlbl" class="col-form-label cust-label">
                                                {{'ON_BOARD_COMPRESSOR' | translate}}</Label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="onBoardCompressor" class="col-form-label cust-label-val">
                                                {{requestData.onboardCompressor |translate}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isoTankRentalRequirement">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="isoTankRentalPeriodlbl" class="col-form-label cust-label ">
                                                {{'ISO_TANK_RENTAL_REQ' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="isoTankRentalPeriod" class="col-form-label cust-label-val ">
                                                {{ requestData.isoTankRentalRequirementName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isoTankRentalPeriod">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="loadingPointlable" class="col-form-label cust-label ">
                                                {{'ISO_TANK_RENTAL_PERIOD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="loadingPoint" class="col-form-label cust-label-val ">
                                                {{ requestData.isoTankRentalPeriod}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tankType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="tankTypelable" class="col-form-label cust-label ">
                                                {{'TANK_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="tankType" class="col-form-label cust-label-val ">
                                                {{ requestData.tankTypeName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specificRemarkForTankType">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="specificRemarkForTankTypelable"
                                                class="col-form-label cust-label ">
                                                {{'SPECIFIC_REMARK_FOR_TANK_TYPE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="specificRemarkForTankType"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.specificRemarkForTankType}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.topBottomLoad">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="topBottomLoadlable" class="col-form-label cust-label ">
                                                {{'TOP_BOTTOM_LOAD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="topBottomLoad" class="col-form-label cust-label-val ">
                                                {{ requestData.topBottomLoad | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.topBottomUnload">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="topBottomUnloadlable" class="col-form-label cust-label ">
                                                {{'TOP_BOTTOM_UNLOAD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="topBottomUnload" class="col-form-label cust-label-val ">
                                                {{ requestData.topBottomUnload | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaningService">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="cleaningServicelable" class="col-form-label cust-label ">
                                                {{'CLEANING_SERVICE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="cleaningService" class="col-form-label cust-label-val ">
                                                {{ requestData.cleaningService | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity. cleaningFrequency">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for=" cleaningFrequencylable" class="col-form-label cust-label ">
                                                {{'CLEANING_FREQUENCY' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for=" cleaningFrequency" class="col-form-label cust-label-val ">
                                                {{ requestData. cleaningFrequency}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingService">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="heatingServicelable" class="col-form-label cust-label ">
                                                {{'HEATING_SERVICE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="heatingService" class="col-form-label cust-label-val ">
                                                {{ requestData.heatingService |translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingTemperature">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="heatingTemperaturelable" class="col-form-label cust-label ">
                                                {{'HEATING_TEMP' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="heatingTemperature" class="col-form-label cust-label-val ">
                                                {{ requestData.heatingTemperature}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.priorProductRestrictionDetails">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="priorProductRestrictionDetailslable"
                                                class="col-form-label cust-label ">
                                                {{'PRIOR_PROD_RESTRIC_DETAILS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="priorProductRestrictionDetails"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.priorProductRestrictionDetails}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tariffZone">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="tariffZonelable" class="col-form-label cust-label ">
                                                {{'TARIFF_ZONE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="tariffZone" class="col-form-label cust-label-val ">
                                                {{ requestData.tariffZone}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dangerousGoodsClass">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="dangerousGoodsClasslable" class="col-form-label cust-label ">
                                                {{'DANGEROUS_GOODS_CLASS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="dangerousGoodsClass" class="col-form-label cust-label-val ">
                                                {{ requestData.dangerousGoodsClassName}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.otherAdditionalServiceRequirement">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="otherAdditionalServiceRequirementlable"
                                                class="col-form-label cust-label ">
                                                {{'OTHER_ADDITIONAL_SERV_REQ' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="otherAdditionalServiceRequirement"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.otherAdditionalServiceRequirement}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.driverLanguage">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="driverLanguagelable" class="col-form-label cust-label ">
                                                Specific Driver Language
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="driverLanguage" class="col-form-label cust-label-val ">
                                                {{ requestData.driverLanguageIdValue}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requiredCleaningProcedure">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="requiredCleaningProcedurelable"
                                                class="col-form-label cust-label ">
                                                Required Cleaning Procedure
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="requiredCleaningProcedure"
                                                class="col-form-label cust-label-val ">
                                                {{ requestData.requiredCleaningProcedureIdValue}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.vacuumTestConfirmation">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="vacuumTestConfirmationlable" class="col-form-label cust-label ">
                                                Vacuum Test Confirmation
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="vacuumTestConfirmation" class="col-form-label cust-label-val ">
                                                {{ requestData.vacuumTestConfirmation}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.reactorLoading">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="reactorLoadinglable" class="col-form-label cust-label ">
                                                Reactor Loading
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="reactorLoading" class="col-form-label cust-label-val ">
                                                {{ requestData.reactorLoading}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingDuringTransport">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="heatingDuringTransportlable" class="col-form-label cust-label ">
                                                Heating During Transport
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="heatingDuringTransport" class="col-form-label cust-label-val ">
                                                {{ requestData.heatingDuringTransport}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specialCertification">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="specialCertificationlable" class="col-form-label cust-label ">
                                                Special Certification
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="specialCertification" class="col-form-label cust-label-val ">
                                                {{ requestData.specialCertificationIdValue}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.hoseLength">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="hoseLengthlable" class="col-form-label cust-label ">
                                                Hose Length
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <label for="hoseLength" class="col-form-label cust-label-val ">
                                                {{ requestData.hoseLengthIdValue}}
                                            </label>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.others">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="otherslbl" class="col-form-label cust-label">
                                                {{'OTHERS' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="others" class="col-form-label cust-label-val">
                                                {{requestData.others}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </form>
                    </p-accordionTab>
                    <!-- Service Provided Req End -->
                    <!-- Shipping details Start-->
                    <p-accordionTab header="{{'SHIPPING_DETAILS' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="row">
                                <div class="row col-md-12" style="align-items: flex-start;">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group col-md-12">
                                            <div class="row">
                                                <div class="col-md-12"
                                                    style="background-color: #E1E1E1; margin-top: 1px;">
                                                    <h6
                                                        style="text-align: center;margin-top: 5px;font-weight: 600;color: black;">
                                                        {{'ORIGIN' | translate}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.originPlant">
                                            <div class="row align-items-center">
                                                <div class="col-md-3 ">
                                                    <label for="originPlant"
                                                        class="col-form-label cust-label">{{'ORIGIN_PLANT_CODE'
                                                        | translate}}</label>
                                                </div>
                                                <div class="col-md-9">
                                                    <label for="originPlant" class="col-form-label cust-label-val">
                                                        {{requestData.originPlantCode}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.shippingName">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="OrgShipperName" class="col-form-label cust-label">
                                                        {{'SHIPPER_NAME' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="OrgShipperName" class="col-form-label cust-label-val">
                                                        {{requestData.orginName}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.orginStreetAddress">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orginStreetAddressLbl"
                                                        class="col-form-label cust-label">
                                                        {{'STREET_ADDRESS' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgCity" class="col-form-label cust-label-val">
                                                        {{requestData.orginStreetAddress}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.orginCity || fieldVisiblity.orginCityEMEA">
                                            <div class="row align-items-center">
                                                <div class="col-md-3" *ngIf="fieldVisiblity.orginCity">
                                                    <Label for="orgCityLbl" class="col-form-label cust-label">
                                                        {{'CITY' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-3" *ngIf="fieldVisiblity.orginCityEMEA">
                                                    <Label for="orgCityLbl" class="col-form-label cust-label">
                                                        Dep City
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgCity" class="col-form-label cust-label-val">
                                                        {{requestData.orginCity}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.orginState">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orgStatelbl" class="col-form-label cust-label">
                                                        {{'STATE' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgState" class="col-form-label cust-label-val">
                                                        {{requestData.orginState}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.orginZipCode || fieldVisiblity.originZipCodeMandatory ">
                                            <div class="row align-items-center">
                                                <div class="col-md-3" *ngIf="fieldVisiblity.orginZipCode">
                                                    <Label for="orgzipcodelbl" class="col-form-label cust-label">
                                                        {{'ZIP_CODE' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-3" *ngIf="fieldVisiblity.originZipCodeMandatory">
                                                    <Label for="orgzipcodelbl" class="col-form-label cust-label">
                                                        Dep Postal/Zip Code
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgzipcode" class="col-form-label cust-label-val">
                                                        {{requestData.orginZipCode}}

                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.orginCountry || fieldVisiblity.orginCountryEMEA">
                                            <div class="row align-items-center">
                                                <div class="col-md-3" *ngIf="fieldVisiblity.orginCountry">
                                                    <Label for="orgCountrylbl" class="col-form-label cust-label">
                                                        {{'COUNTRY' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-3" *ngIf="fieldVisiblity.orginCountryEMEA">
                                                    <Label for="orgCountrylbl" class="col-form-label cust-label">
                                                        Dep Country
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgCountry" class="col-form-label cust-label-val">
                                                        {{requestData.orginCountry}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.orginServingRailRoad">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orgservingRailRoad" class="col-form-label cust-label">
                                                        {{'SERV_RAIL_ROAD' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgservingRailRoad"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.orginServingRailRoad}}
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.orginAlternateServingRailRoad">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orginAlternateServingRailRoad"
                                                        class="col-form-label cust-label">
                                                        {{'ALTERNATE_SERV_RAIL_ROAD' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orginAlternateServingRailRoad"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.orginAlternateServingRailRoad}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.orginSiteCompetitivenessId">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orginSiteCompetitivenessId"
                                                        class="col-form-label cust-label">
                                                        {{'SITE_COMETETIVENESS' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orginSiteCompetitivenessId"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.orginSiteCompetitiveness}}
                                                    </Label>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.orginRailStation">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orginAlternateServingRailRoad"
                                                        class="col-form-label cust-label">
                                                        {{'RAIL_STATION' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orginRailStation" class="col-form-label cust-label-val">
                                                        {{requestData.orginRailStation}}
                                                    </Label>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.orginLocationType">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orgLocationType"
                                                        class="col-form-label cust-label">{{'LOCATION_TYPE'
                                                        | translate}}</Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgLocationType" class="col-form-label cust-label-val">
                                                        {{requestData.orginLocationType}}
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <!-- Destination -->
                                    <div class="col-12 col-md-6" *ngIf="!fieldVisiblity.multipleDestination">
                                        <div class="form-group col-md-12">
                                            <div class="row">
                                                <div class="col-md-12" style="background-color: #E1E1E1;">
                                                    <h6
                                                        style="text-align: center;margin-top: 5px;font-weight: 600;color: black;">
                                                        {{'DESTINATION' | translate}}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationPlant">
                                            <div class="row align-items-center">
                                                <div class="col-md-3 ">
                                                    <label for="destinationPlant"
                                                        class="col-form-label cust-label">{{'DESTINATION_PLANT_CODE' |
                                                        translate}}</label>
                                                </div>
                                                <div class="col-md-9">
                                                    <label for="destinationPlant"
                                                        class="col-form-label cust-label-val">{{requestData.destinationPlantCode}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.consigneeName">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="consigneeNamelbl" class="col-form-label cust-label">
                                                        {{'CONSIGNEE_NAME' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="consigneeName" class="col-form-label cust-label-val">
                                                        {{requestData.destinationName}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.destinationStreetAddress">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationStreetAddresslbl"
                                                        class="col-form-label cust-label">
                                                        {{'STREET_ADDRESS' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="orgCity" class="col-form-label cust-label-val">
                                                        {{requestData.destinationStreetAddress}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationCity">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationCitylbl" class="col-form-label cust-label">
                                                        {{'CITY' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationCity" class="col-form-label cust-label-val">
                                                        {{requestData.destinationCity}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationState">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationStatelbl" class="col-form-label cust-label">
                                                        {{'STATE' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationState" class="col-form-label cust-label-val">
                                                        {{requestData.destinationState}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationZipCode">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationZipCodeLbl"
                                                        class="col-form-label cust-label">
                                                        {{'ZIP_CODE' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationZipCode"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationZipCode}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationCountry">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationCountryIdLbl"
                                                        class="col-form-label cust-label">
                                                        {{'COUNTRY' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationCountryId"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationCountry}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.destinationServingRailRoad">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationServingRailRoad"
                                                        class="col-form-label cust-label">
                                                        {{'SERV_RAIL_ROAD' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationServingRailRoad"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationServingRailRoad}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.destinationAlternateServingRailRoad">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationAlternateServingRailRoad"
                                                        class="col-form-label cust-label">
                                                        {{'ALTERNATE_SERV_RAIL_ROAD' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationServingRailRoad"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationAlternateServingRailRoad}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.destinationSiteCompetitivenessId">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationSiteCompetitivenessId"
                                                        class="col-form-label cust-label">
                                                        {{'SITE_COMETETIVENESS' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationSiteCompetitivenessId"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationSiteCompetitiveness}}
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12" *ngIf="fieldVisiblity.destinationRailStation">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="orginAlternateServingRailRoad"
                                                        class="col-form-label cust-label">
                                                        {{'RAIL_STATION' | translate}}
                                                    </Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationRailStation"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationRailStation}}
                                                    </Label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12"
                                            *ngIf="fieldVisiblity.destinationLocationType">
                                            <div class="row align-items-center">
                                                <div class="col-md-3">
                                                    <Label for="destinationLocationType"
                                                        class="col-form-label cust-label">{{'LOCATION_TYPE' |
                                                        translate}}</Label>
                                                </div>
                                                <div class="col-md-9">
                                                    <Label for="destinationLocationType"
                                                        class="col-form-label cust-label-val">
                                                        {{requestData.destinationLocationType}}
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-12 col-md-6 mexicoShipping" style="padding-right:0px !important"
                                        *ngIf="fieldVisiblity.multipleDestination">
                                        <div class="row align-items-center col-md-12" style="padding:0px !important"
                                            *ngFor="let dest of requestData.multipleDestination;index as i;first as isFirst;last as isLast">
                                            <div class="col-12 col-md-12" style="padding:0px">
                                                <p-accordion>
                                                    <p-accordionTab header="{{'DESTINATION' | translate}} {{i+1}}"
                                                        [selected]="requestData.multipleDestination.length===(i+1)">
                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationPlant">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3 ">
                                                                    <label for="destinationPlant_{{i}}"
                                                                        class="col-form-label cust-label">{{'DESTINATION_PLANT_CODE'
                                                                        | translate}}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <label for="destinationPlant_{{i}}"
                                                                        class="col-form-label cust-label-val">{{dest.multipleDestinationPlantCode}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.consigneeName">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3">
                                                                    <Label for="consigneeNamelbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'CONSIGNEE_NAME' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="consigneeName_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.multipleDestinationConsigneeName}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationStreetAddress">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3">
                                                                    <Label for="destinationStreetAddresslbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'STREET_ADDRESS' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="orgCity_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.destinationStreetAddress}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationCity || fieldVisiblity.destinationCityEMEA">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.destinationCity">
                                                                    <Label for="destinationCitylbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'CITY' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.destinationCityEMEA">
                                                                    <Label for="destinationCitylbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        Ship to City
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="destinationCity_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.multipleDestinationCity}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationState">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3">
                                                                    <Label for="destinationStatelbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'STATE' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="destinationState_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.multipleDestinationState}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationZipCode || fieldVisiblity.distinationZipCodeMandatory">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.destinationZipCode">
                                                                    <Label for="destinationZipCodeLbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'ZIP_CODE' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.distinationZipCodeMandatory">
                                                                    <Label for="destinationZipCodeLbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        Postal/Zip Code Ship to
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="destinationZipCode_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.multipleDestinationZipCode}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group col-md-12"
                                                            *ngIf="fieldVisiblity.destinationCountry || fieldVisiblity.destinationCountryEMEA ">
                                                            <div class="row align-items-center">
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.destinationCountry">
                                                                    <Label for="destinationCountryIdLbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        {{'COUNTRY' | translate}}
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-3"
                                                                    *ngIf="fieldVisiblity.destinationCountryEMEA">
                                                                    <Label for="destinationCountryIdLbl_{{i}}"
                                                                        class="col-form-label cust-label">
                                                                        Country Ship to
                                                                    </Label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <Label for="destinationCountryId_{{i}}"
                                                                        class="col-form-label cust-label-val">
                                                                        {{dest.multipleDestinationCountry}}
                                                                    </Label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p-accordionTab>
                                                </p-accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </p-accordionTab>
                    <!-- Shipping details End-->
                    <!-- Payer Option start-->

                    <p-accordionTab header="{{'PAYER_OPTION' | translate}}" [selected]="true"
                        *ngIf="((requestData.payerOption=='No')&&(fieldVisiblity.payer|| fieldVisiblity.routing||fieldVisiblity.tariffNumber||fieldVisiblity.freightBillAddress))">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <!-- left -->


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.payer">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 cust-mandatory">
                                            <label for="payer" class="col-form-label cust-label ">
                                                {{'PAYER' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="payer" class="col-form-label cust-label-val">
                                                {{requestData.payer | translate}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.routing">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 cust-mandatory">
                                            <label for="routing" class="col-form-label cust-label ">
                                                {{'ROUTING' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="routing" class="col-form-label cust-label-val">
                                                {{requestData.routing}}
                                            </Label>
                                        </div>
                                    </div>
                                </div>


                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tariffNumber">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="tariffNumber" class="col-form-label cust-label ">
                                                {{'TARIFF_NO' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="tariffNumber" class="col-form-label cust-label-val">
                                                {{requestData.tariffNumber}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.freightBillAddress">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="freightBillAddress" class="col-form-label cust-label">
                                                {{'SEND_FREIGHTBILL_TO_ADD' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="freightBillAddress" class="col-form-label cust-label-val">
                                                {{requestData.freightBillAddress}}
                                            </Label>


                                        </div>
                                    </div>
                                </div>


                                <!-- right -->
                                <div class="form-group wrap-div">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <!-- label -->
                                        </div>
                                        <div class="col-md-9">
                                            <!-- control -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </p-accordionTab>
                    <!-- Payer Option end-->

                    <!-- Additional comments  start-->
                    <p-accordionTab header="{{'ADDITIONAL_COMMENTS_SECTION' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="row" style="margin-right: 22px;">
                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.customerPreferedCarrier">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="preferredcarrierslbl" class="col-form-label cust-label">
                                                {{'CUST_PREFERRED_CARRIER' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="preferredcarriers" class="col-form-label cust-label-val">
                                                {{ requestData.prefferedVendor}}
                                            </label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.costVsService">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="costvsservicelbl" class="col-form-label cust-label ">
                                                {{'COST_V_SERVICE' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9" style="margin-top: 5px;">
                                            <label for="costvsservice" class="col-form-label cust-label-val ">
                                                {{requestData.costVsService}} - {{requestData.priority}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12" *ngIf="fieldVisiblity.comments">
                                    <div class="row align-items-center">
                                        <div class="col-md-3">
                                            <label for="commentsLbl" class="cust-label col-form-label">{{'COMMENTS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <label for="comments"
                                                class="cust-label-val col-form-label">{{requestData.additionalComments}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.isSpecialRequirement">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="isSpecialRequirement"
                                                class="cust-label col-form-label">{{'IS_SPECIAL_REQ' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <Label for="isSpecialRequirement" class="col-form-label cust-label-val">
                                                {{requestData.isSpecialRequirement}}
                                            </Label>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 attach"
                                    *ngIf="fieldVisiblity.specailRequirementAttachment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="specialReqAttachment"
                                                class="cust-label col-form-label">{{'SPECIAL_REQ_ATTACHMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <a *ngIf="requestData.specialRequirementFileName != null && requestData.specialRequirementFileName != undefined && requestData.specialRequirementFileName != ''"
                                                style="cursor: pointer;color: blue;"
                                                (click)="downLoadSpecialReqAttachment()">{{requestData.specialRequirementFileName}}</a>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.sdsAttachment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="ssdAttachment"
                                                class="cust-label col-form-label">{{'SDS_ATTACHMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <a *ngIf="requestData.sdsFileName != null && requestData.sdsFileName != undefined && requestData.sdsFileName != ''"
                                                style="cursor: pointer;color: blue;"
                                                (click)="downloadAttachment('sds')">{{requestData.sdsFileName}}</a>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.additionalAttachment">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="additionalAttachment"
                                                class="cust-label col-form-label">{{'ADDITIONAL_ATTACH_1' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <a *ngIf="requestData.additionalFileName != null && requestData.additionalFileName != undefined && requestData.additionalFileName != ''"
                                                style="cursor: pointer;color: blue;"
                                                (click)="downloadAttachment('additionalAttachment')">
                                                {{requestData.additionalFileName}}
                                            </a>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.additionalAttachment2">
                                    <div class="row align-items-center">
                                        <div class="col-md-3 adjust-label">
                                            <label for="additionalAttachment"
                                                class="cust-label col-form-label">{{'ADDITIONAL_ATTACH_2' | translate}}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <a *ngIf="requestData.additionalFileName2 != null && requestData.additionalFileName2 != undefined && requestData.additionalFileName2 != ''"
                                                style="cursor: pointer;color: blue;"
                                                (click)="downloadAttachment('additionalAttachment2')">
                                                {{requestData.additionalFileName2}}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </p-accordionTab>
                    <!-- Additional comments  End-->
                </p-accordion>
                <div *ngIf='requestData.requestStatusId'>
                    <div>
                        <app-vendor-form-common [requestId]="requestId" [transportType]="transportType"
                            [statusId]="requestData.requestStatusId"
                            [requestDestination]="requestData.destinationCountry" (refresh)="reload($event)">
                        </app-vendor-form-common>
                    </div>
                </div>
            </div>
        </div>

        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff"
            type="line-scale-pulse-out"></ngx-spinner>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

import { TableModule } from 'primeng/table';


import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips'
import {TooltipModule} from 'primeng/tooltip'

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { LoginQaComponent } from './login-qa/login-qa.component';
import { HomeComponent } from './home/home.component';
import { TransportTypeMasterServices } from './shared/transport-type-master/transport-type-master.service';
import { RequestMasterService } from './requests/request-master.service';
import { ProductService } from './shared/product/product.service';
import { RequestFormService } from './requests/request-form.services';
import { RequestListComponent } from './requests/request-list/request-list.component';
import { VendorResponseMasterService } from './request-vendor-response/vendor-response-master.services';
import {VendorResponseServices } from './request-vendor-response/vendor-response.services';
import { TruckLoadFormComponent } from './requests/request-forms/truck-load-request-form.component';
import { TruckLoadRequestDetailsComponent } from './requests/request-details/truck-load-details-form.component';
import { RailRateRequestFormComponent } from './requests/request-forms/rail-rate-request-form.component';
import { RailRateRequestDetailsComponent } from './requests/request-details/rail-rate-details-form.components';
import { AdminComponent } from './admin/component/admin.component';
import { AdminUserManagementComponent } from './admin/user-management/components/admin-user-management.component';
import { AdminVendorManagementComponent } from './admin/vendor-management/component/admin-vendor-management.component';
import { AdminCleaningCategoryManagementComponent } from './admin/cleaning-category/component/admin-cleaning-category.component';
import { AdminArticleManagementComponent } from './admin/article-management/component/admin-article-management.component';
import { AdminSiteCompetitivenessManagementComponent } from './admin/site-competitiveness/component/admin-site-competitiveness-component';
import { AdminRailCarTypeManagementComponent } from './admin/railcar-type/component/admin-railcar-type.component';
import { UserMasterDataServices } from './admin/user-management/user-masterdata.service';
import { UserManagementServices } from './admin/user-management/user-management.service';
import { SiteCompetitivenessService } from './admin/site-competitiveness/site-competitiveness.service';
import { VenderManagementServices } from './admin/vendor-management/vendor-management.service';
import { RailCarTypeServices } from './admin/railcar-type/railcar-type.service';
import { CleaningCategoryService } from './admin/cleaning-category/cleaning-category.service';
import { ArticelManagementServices } from './admin/article-management/article-management.service';
import { TempRequirementService } from './admin/temp-requirement/temp-requirement.service';
import { DeliveryTypeService } from './admin/delivery-type/delivery-type.service';
import { AdminDeliveryTypeComponent } from './admin/delivery-type/component/delivery-type.componet';
import { AdminTempRequirementComponent } from './admin/temp-requirement/component/temp-requirement.component';
import { ISODryageTruckComponent } from './requests/request-forms/iso-dryage-truck-form.component';
import { ISODryageTruckRequestDetailsComponent } from './requests/request-details/iso_dryage_truck-details-form.component';
import { PackageContainerBoxComponent } from './requests/request-forms/package-container-box-drayage-component';
import { PackagaContainerBoxDetailsRequestComponent } from './requests/request-details/package-container-box-drayage-request-detail-form.component';
import { LiquidRequestFormNAComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-components/liquid-request-form-na.component';
import { LiquidRequestFormCommonComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-common.component';
import { LiquidRequestFormSAComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-components/liquid-request-form-sa.component';
import { LiquidRequestFormEMEAComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-components/liquid-request-form-emea.component';
import { LiquidRequestFormAPComponent } from './requests/request-forms/liquid-request-form-common/liquid-request-form-components/liquid-request-form-ap.component';
import { LiquidRequestDetailFormComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-common/liquid-request-detail-form.component';
import { LiquidRequestDetailsNAComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-components/liquid-request-detail-form-na.component';
import { LiquidRequestDetailsSAComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-components/liquid-request-details-form-sa.component';
import { LiquidRequestDetailsEMEAComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-components/liquid-request-details-form-emea.component';
import { LiquidRequestDetailsAPComponent } from './requests/request-details/liquid-request-detail/liquid-request-detail-form-components/liquid-request-detail-form-ap.component';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { DryRequestFormCommonComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-common.component';
import { DryRequestFormNAComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-components/dry-request-form-na.component';
import { DryRequestFormAPComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-components/dry-request-form-ap.component';
import { DryRequestFormEMEAComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-components/dry-request-form-emea.component';
import { DryRequestFormSAComponent } from './requests/request-forms/dry-request-form-common/dry-request-form-components/dry-request-form-sa.component';
import { DryRequestDetailFormCommonComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-common/dry-request-detail-form-common.component';
import { DryRequestDetailsAPComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-components/dry-request-details-form-ap.component';
import { DryRequestDetailsEMEAComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-components/dry-request-details-form-emea.component';
import { DryRequestDetailsNAComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-components/dry-request-details-form-na.component';
import { DryRequestDetailsSAComponent } from './requests/request-details/dry-request-details/dry-request-detail-form-components/dry-request-details-form-sa.component';
import { AuthenticationGuard } from './_helpers/authentication.guard';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CustSpinnerComponent } from './_helpers/cust-spinner/cust-spinner.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { VendorFormCommonComponent } from './request-vendor-response/vendor-form-common/vendor-form-common.component';
import { LiquidVendorUpdateComponentEMEA } from './request-vendor-response/liquid-bulk-vendor/liquid-bulk-region-wise/liquid-bulk-vendor-emea.component';
import { LiquidVendorUpdateComponentAP } from './request-vendor-response/liquid-bulk-vendor/liquid-bulk-region-wise/liquid-bulk-vendor-ap.component';
import { LiquidVendorUpdateComponentNA } from './request-vendor-response/liquid-bulk-vendor/liquid-bulk-region-wise/liquid-bulk-vendor-na.component';
import { LiquidVendorUpdateComponentSA } from './request-vendor-response/liquid-bulk-vendor/liquid-bulk-region-wise/liquid-bulk-vendor-sa.component';
import { DryVendorUpdateComponentAP } from './request-vendor-response/dry-bulk-vendor/dry-bulk-region-wise/dry-bulk-vendor-ap.component';
import { DryVendorUpdateComponentEMEA } from './request-vendor-response/dry-bulk-vendor/dry-bulk-region-wise/dry-bulk-vendor-emea.component';
import { DryVendorUpdateComponentNA } from './request-vendor-response/dry-bulk-vendor/dry-bulk-region-wise/dry-bulk-vendor-na.component';
import { DryVendorUpdateComponentSA } from './request-vendor-response/dry-bulk-vendor/dry-bulk-region-wise/dry-bulk-vendor-sa.component';
import { TruckLoadVendorUpdateComponentAP } from './request-vendor-response/truck-load-vendor/truck-load-region-wise/truck-load-vendor-ap.component';
import { TruckLoadVendorUpdateComponentEMEA } from './request-vendor-response/truck-load-vendor/truck-load-region-wise/truck-load-vendor-emea.component';
import { TruckLoadVendorUpdateComponentNA } from './request-vendor-response/truck-load-vendor/truck-load-region-wise/truck-load-vendor-na.component';
import { TruckLoadVendorUpdateComponentSA } from './request-vendor-response/truck-load-vendor/truck-load-region-wise/truck-load-vendor-sa.component';
import { RailRateVendorResponseComponentAP } from './request-vendor-response/rail-rate-vendor/rail-rate-region-wise/rail-rate-vendor-ap.component';
import { RailRateVendorResponseComponentEMEA } from './request-vendor-response/rail-rate-vendor/rail-rate-region-wise/rail-rate-vendor-emea.component';
import { RailRateVendorResponseComponentNA } from './request-vendor-response/rail-rate-vendor/rail-rate-region-wise/rail-rate-vendor-na.component';
import { RailRateVendorResponseComponentSA } from './request-vendor-response/rail-rate-vendor/rail-rate-region-wise/rail-rate-vendor-sa.component';
import { BulkDrayageVendorUpdateComponentAP } from './request-vendor-response/bulk-drayage-vendor/bulk-drayage-region-wise/bulk-drayage-vendor-ap.component';
import { BulkDrayageVendorUpdateComponentEMEA } from './request-vendor-response/bulk-drayage-vendor/bulk-drayage-region-wise/bulk-drayage-vendor-emea.component';
import { BulkDrayageVendorUpdateComponentNA } from './request-vendor-response/bulk-drayage-vendor/bulk-drayage-region-wise/bulk-drayage-vendor-na.component';
import { BulkDrayageVendorUpdateComponentSA } from './request-vendor-response/bulk-drayage-vendor/bulk-drayage-region-wise/bulk-drayage-vendor-sa.component';
import { PackageDrayageVendorUpdateComponentNA } from './request-vendor-response/package-drayage-vendor/package-drayage-region-wise/package-drayage-vendor-na.component';
import { PackageDrayageVendorUpdateComponentAP } from './request-vendor-response/package-drayage-vendor/package-drayage-region-wise/package-drayage-vendor-ap.component';
import { PackageDrayageVendorUpdateComponentEMEA } from './request-vendor-response/package-drayage-vendor/package-drayage-region-wise/package-drayage-vendor-emea.component';
import { PackageDrayageVendorUpdateComponentSA } from './request-vendor-response/package-drayage-vendor/package-drayage-region-wise/package-drayage-vendor-sa.component';
import { CheckboxModule } from 'primeng/checkbox';
import {TruckLoadRequestFormCommonComponent} from './requests/request-forms/truck-load-request-form-common/truck-load-request-form-common.component';
import { TruckLoadRequestFormAPComponent } from './requests/request-forms/truck-load-request-form-common/truck-load-request-form-components/truck-load-request-form-ap.component';
import { TruckLoadRequestFormEMEAComponent } from './requests/request-forms/truck-load-request-form-common/truck-load-request-form-components/truck-load-request-form-emea.component';
import { TruckLoadRequestFormSAComponent } from './requests/request-forms/truck-load-request-form-common/truck-load-request-form-components/truck-load-request-form-sa.component';
import { TruckLoadRequestFormNAComponent } from './requests/request-forms/truck-load-request-form-common/truck-load-request-form-components/truck-load-request-form-na.component';
import { TruckLoadRequestDetailsFormSaComponent } from './requests/request-details/truck-load-request-details/truck-load-request-details-form-components/truck-load-request-details-form-sa.component';
import { TruckLoadRequestDetailsFormNaComponent } from './requests/request-details/truck-load-request-details/truck-load-request-details-form-components/truck-load-request-details-form-na.component';
import { TruckLoadRequestDetailsFormEmeaComponent } from './requests/request-details/truck-load-request-details/truck-load-request-details-form-components/truck-load-request-details-form-emea.component';
import { TruckLoadRequestDetailsFormApComponent } from './requests/request-details/truck-load-request-details/truck-load-request-details-form-components/truck-load-request-details-form-ap.component';
import { TruckLoadRequestDetailFormCommonComponent } from './requests/request-details/truck-load-request-details/truck-load-request-detail-form-common/truck-load-request-detail-form-common.component';

import { LessThanTruckloadRequestDetailFormCommonComponent } from './requests/request-details/less-than-truckload-request-details/less-than-truckload-request-detail-form-common/less-than-truckload-request-detail-form-common.component';
import { LessThanTruckloadRequestDetailFormNaComponent } from './requests/request-details/less-than-truckload-request-details/less-than-truckload-request-detail-form-components/less-than-truckload-request-detail-form-na.component';
import { LessThanTruckloadRequestFormCommonComponent } from './requests/request-forms/less-than-truckload-request-form-common/less-than-truckload-request-form-common.component';
import { LessThanTruckloadRequestFormNaComponent } from './requests/request-forms/less-than-truckload-request-form-common/less-than-truckload-request-form-components/less-than-truckload-request-form-na.component';
import { LessThanTruckloadVendorUpdateComponentNA } from './request-vendor-response/less-than-truckload-vendor/less-than-truckload-region-wise/less-than-truckload-vendor-na.component';
import { PackageIntermodalRequestFormCommonComponent } from './requests/request-forms/package-intermodal-request-form-common/package-intermodal-request-form-common.component';
import { PackageIntermodalRequestFormNaComponent } from './requests/request-forms/package-intermodal-request-form-common/package-intermodal-request-form-components/package-intermodal-request-form-na.component';
import { PackageIntermodalRequestDetailFormCommonComponent } from './requests/request-details/package-intermodal-request-details/package-intermodal-request-detail-form-common/package-intermodal-request-detail-form-common.component';
import { PackageIntermodalRequestDetailFormNaComponent } from './requests/request-details/package-intermodal-request-details/package-intermodal-request-details-form-components/package-intermodal-request-detail-form-na.component';
import { PackageIntermodalVendorUpdateComponentNa } from './request-vendor-response/package-intermodal-vendor/package-intermodal-region-wise/package-intermodal-vendor-na.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,

    LoginQaComponent,

    LiquidRequestFormCommonComponent,
    LiquidRequestFormNAComponent,
    LiquidRequestFormSAComponent,
    LiquidRequestFormEMEAComponent,
    LiquidRequestFormAPComponent,

    LiquidRequestDetailFormComponent,
    LiquidRequestDetailsNAComponent,
    LiquidRequestDetailsSAComponent,
    LiquidRequestDetailsEMEAComponent,
    LiquidRequestDetailsAPComponent,

    RequestListComponent,
    TruckLoadFormComponent,
    TruckLoadRequestDetailsComponent,
    RailRateRequestFormComponent,
    RailRateRequestDetailsComponent,
    ISODryageTruckComponent,
    ISODryageTruckRequestDetailsComponent,
    PackageContainerBoxComponent,
    PackagaContainerBoxDetailsRequestComponent,

    AdminComponent,
    AdminUserManagementComponent,
    AdminVendorManagementComponent,
    AdminCleaningCategoryManagementComponent,
    AdminArticleManagementComponent,
    AdminSiteCompetitivenessManagementComponent,
    AdminRailCarTypeManagementComponent,
    AdminDeliveryTypeComponent, 
    AdminTempRequirementComponent,
    LandingpageComponent,
    DryRequestFormCommonComponent,

    DryRequestFormNAComponent,
    DryRequestFormSAComponent,
    DryRequestFormAPComponent,
    DryRequestFormEMEAComponent,
    DryRequestDetailFormCommonComponent,
    DryRequestDetailsNAComponent,
    DryRequestDetailsSAComponent,
    DryRequestDetailsEMEAComponent,
    DryRequestDetailsAPComponent,

    UnauthorizedComponent,

    CustSpinnerComponent,
    

    VendorFormCommonComponent,

    LiquidVendorUpdateComponentAP,
    LiquidVendorUpdateComponentEMEA,
    LiquidVendorUpdateComponentNA,
    LiquidVendorUpdateComponentSA,

    DryVendorUpdateComponentAP,
    DryVendorUpdateComponentEMEA,
    DryVendorUpdateComponentNA,
    DryVendorUpdateComponentSA,

    TruckLoadVendorUpdateComponentAP,
    TruckLoadVendorUpdateComponentEMEA,
    TruckLoadVendorUpdateComponentNA,
    TruckLoadVendorUpdateComponentSA,

    RailRateVendorResponseComponentAP,
    RailRateVendorResponseComponentEMEA,
    RailRateVendorResponseComponentNA,
    RailRateVendorResponseComponentSA,

    BulkDrayageVendorUpdateComponentAP,
    BulkDrayageVendorUpdateComponentEMEA,
    BulkDrayageVendorUpdateComponentNA,
    BulkDrayageVendorUpdateComponentSA,

    PackageDrayageVendorUpdateComponentAP,
    PackageDrayageVendorUpdateComponentEMEA,
    PackageDrayageVendorUpdateComponentNA,
    PackageDrayageVendorUpdateComponentSA,
    TruckLoadRequestFormCommonComponent,
    
    TruckLoadRequestFormAPComponent,
    TruckLoadRequestFormEMEAComponent,
    TruckLoadRequestFormSAComponent,
    TruckLoadRequestFormNAComponent,
    TruckLoadRequestDetailFormCommonComponent,
    TruckLoadRequestDetailsFormSaComponent,
    TruckLoadRequestDetailsFormNaComponent,
    TruckLoadRequestDetailsFormEmeaComponent,
    TruckLoadRequestDetailsFormApComponent,

    LessThanTruckloadRequestDetailFormCommonComponent,
    LessThanTruckloadRequestDetailFormNaComponent,
    LessThanTruckloadRequestFormCommonComponent,
    LessThanTruckloadRequestFormNaComponent,
    LessThanTruckloadVendorUpdateComponentNA,
    PackageIntermodalRequestFormCommonComponent,
    PackageIntermodalRequestFormNaComponent,
    PackageIntermodalRequestDetailFormCommonComponent,
    PackageIntermodalRequestDetailFormNaComponent,
    PackageIntermodalVendorUpdateComponentNa
   
  ],
  imports: [
    BrowserModule,

    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MultiSelectModule, 
    CheckboxModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    
    TranslateModule.forRoot({
      
      
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
    }
     }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
     
    TableModule,
    DropdownModule,
    TabViewModule,
    AccordionModule,
    CalendarModule,
    DialogModule,
    FileUploadModule,
    ButtonModule,
    PaginatorModule,
    ConfirmDialogModule,
    MultiSelectModule,
    AutoCompleteModule,
    ChipsModule,
    TooltipModule,
    OverlayPanelModule
  ],
  providers: [
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    TransportTypeMasterServices,
    RequestFormService,
    RequestMasterService,
    ProductService,
    VendorResponseMasterService,
    VendorResponseServices,
    UserMasterDataServices,
    UserManagementServices,
    SiteCompetitivenessService,
    VenderManagementServices,
    RailCarTypeServices,
    CleaningCategoryService,
    ArticelManagementServices,
    TempRequirementService,
    DeliveryTypeService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:HttpConfigInterceptor,
      multi:true
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

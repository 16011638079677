import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-package-intermodal-request-form-common',
  templateUrl: './package-intermodal-request-form-common.component.html',
  styleUrls: ['./package-intermodal-request-form-common.component.css'],
  providers:[AppServiceService]
})
export class PackageIntermodalRequestFormCommonComponent implements OnInit,OnDestroy {
  private subscriptionName: Subscription; 
    messageReceived: any;
    region !: string;
  
    constructor(private _appServ: AppServiceService) { 
       this.subscriptionName= this._appServ.getUpdate().subscribe(message => {
      this.messageReceived = message;
      this.region=sessionStorage['selectedRegion'];
      
  
      });
    }
    ngOnDestroy() { 
      this.subscriptionName.unsubscribe();
  }
    ngOnInit() {
      console.log('')
    }
  
}

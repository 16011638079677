import { VendorResponseData } from "./vendor-response.data";

export class LessThanTruckloadVendorResponseData extends VendorResponseData {
    bidAmount2: number=0;
    isSelected2:boolean=false;
    rankAssigned2:any=0;
    currencyType2:number=0;

    mexicoBidAmount:number=0;
    mexicoCurrencyType:number =0;
    mexicoBidAmount2:number=0;
    mexicoCurrencyType2:number =0;
}

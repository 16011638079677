import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { RequestMasterData } from "./request-master.data";


export class TruckLoadRequestMasterData extends RequestMasterData
{
    equipementTypeMaster:DropDownMaster[]=[];
    packageTypeMaster:DropDownMaster[]=[];
    ftlMaster:DropDownMaster[]=[];
    ltlrangeMaster:DropDownMaster[]=[];
    intermodalTypeMaster:DropDownMaster[]=[];
    containerTypeMaster:DropDownMaster[]=[];
    dangerousGoodsClassMaster:DropDownMaster[]=[];
    equipmentModelMaster:DropDownMaster[]=[];
    modalMaster:DropDownMaster[]=[];
}
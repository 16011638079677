import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-less-than-truckload-request-detail-form-common',
  templateUrl: './less-than-truckload-request-detail-form-common.component.html',
  styleUrls: ['./less-than-truckload-request-detail-form-common.component.css']
})
export class LessThanTruckloadRequestDetailFormCommonComponent {
  private subscriptionName: Subscription;
  messageReceived: any;
  region: string ='';

  constructor(private _appServ:AppServiceService){

    this.subscriptionName=this._appServ.getUpdate().subscribe(message=>{
      this.messageReceived=message;
      this.region=sessionStorage['selectedRegion'];  
    })
  }
  ngOnDestroy(): void {
    this.subscriptionName.unsubscribe();
  }

  ngOnInit(){
  console.log("");
  }


}

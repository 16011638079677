<div *ngIf="region=='NA'" id="rateForm">
 <app-truck-load-request-form-na></app-truck-load-request-form-na>
</div>
<div *ngIf="region=='SA'" id="rateForm">
    <app-truck-load-request-form-sa></app-truck-load-request-form-sa>
</div>
<div *ngIf="region=='EMEA'" id="rateForm">
    <app-truck-load-request-form-emea></app-truck-load-request-form-emea>
</div>
<div *ngIf="region=='AP'" id="rateForm">
    <app-truck-load-request-form-ap></app-truck-load-request-form-ap>
</div>


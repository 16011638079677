import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';

@Component({
  selector: 'app-truck-load-request-detail-form-common',
  templateUrl: './truck-load-request-detail-form-common.component.html',
  styleUrls: ['./truck-load-request-detail-form-common.component.css']
})
export class TruckLoadRequestDetailFormCommonComponent implements OnInit,OnDestroy {
  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  region: string ='';

  constructor(private _appServ:AppServiceService){

    this.subscriptionName=this._appServ.getUpdate().subscribe(message=>{
      this.messageReceived=message;
      this.region=sessionStorage['selectedRegion'];  
    })
  }
  ngOnDestroy(): void {
    this.subscriptionName.unsubscribe();
  }

  ngOnInit(){
  console.log("");
  }



}

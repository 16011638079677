import { DryTruckRequestFormData } from "./dry-request-form.data";

export class DryRequestDetails extends DryTruckRequestFormData {

    campaignSeasonalVolumeUidValue:string="";
    annualVolumeUnit: string="";
    monthlyVolumeUnit: string="";
    requestType: string="";
    transportTypeName: string="";
    
    
    reasonForSelection: string="";
    tempRequirement: string="";
    originPlantCode:string="";
    destinationPlantCode:string=""

    mandadoryDocsInsideTruckIdValue:string="";
    averageProductIdValue:string="";


    chassisName:string="";
    loadingPointName:string=""

    totalWeightShipmentUidValue:string="";

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    preferedTransportModeIdValue: string="";
    heatingDuringTransportIdValue:string="";
    groundOperatingEquipmentIdValue:string="";
    specialCertificationIdValue: string="";
    hoseLengthIdValue: string="";
    driverLanguageIdValue: string="";
    requiredCleaningProcedureIdValue: string="";
    vacuumTestConfirmationIdValue: string="";
    specificEquipmentTypeIdValue:string="";
}
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { TruckLoadRequestFormModel } from "src/app/requests/truck-load-request-form.model";
import { RequestDetailsComponent } from "../../request-details-form.component";
import { TruckLoadRequestDetails } from "src/app/requests/data/formdata/truck-load-request-details.data";

@Component({
  selector: 'app-truck-load-request-details-form-emea',
  templateUrl: `..\\..\\..\\shared-templates\\request-details-form-template.html`,
  styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
  encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: TruckLoadRequestFormModel }
    ]
})

export class TruckLoadRequestDetailsFormEmeaComponent extends RequestDetailsComponent implements OnInit,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  pageLanguage:string="";
    override requestData: any; 
 
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
          this.messageReceived = message;        
          let newLang=sessionStorage.getItem('selectedLanguage');
          if(this.pageLanguage!=newLang && this.pageLanguage!=""){
            this.ngOnInit();
          }
          });
    }

    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
        this.requestData = new TruckLoadRequestDetails();
        this.transportType=3;
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }

    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
      this.subscriptionName.unsubscribe();
  }
  downLoadFISPQFile(){console.log('')}
  downLoadSpecialReqAttachment(){console.log('')};
    bindfiledVisiblity()
  {
    this.fieldVisiblity.requestType=true;
    this.fieldVisiblity.startDate=true;
    this.fieldVisiblity.requestor=true;
    this.fieldVisiblity.bussinessunit=true;
    this.fieldVisiblity.product=true;
    this.fieldVisiblity.equipmentType=true;
    this.fieldVisiblity.naCountry=true;
    this.fieldVisiblity.articleNumber=true;

    // Service Provider
    this.fieldVisiblity.volumeType=true;
    this.fieldVisiblity.monthlyVolume=true;
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.tempControl=true;
    this.fieldVisiblity.dropTrail=true;
    this.fieldVisiblity.days=true;
    this.fieldVisiblity.hazardous=true;
    this.fieldVisiblity.seasonal=true;
    this.fieldVisiblity.seasonalComments=true;
    this.fieldVisiblity.unNumber=true;
    this.fieldVisiblity.tankerEndorsement=true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName=true;
    this.fieldVisiblity.orginCity=true;
    this.fieldVisiblity.orginState=true;
    this.fieldVisiblity.orginCountry=true;
    this.fieldVisiblity.orginZipCode=true;
    //shipping-> destination
    this.fieldVisiblity.consigneeName=true;
    this.fieldVisiblity.destinationCity=true;
    this.fieldVisiblity.destinationState=true;
    this.fieldVisiblity.destinationCountry=true;
    this.fieldVisiblity.destinationZipCode=true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier=true;
    this.fieldVisiblity.costVsService=true;
    this.fieldVisiblity.comments=true;

    this.fieldVisiblity.materialDoc=true;
    this.fieldVisiblity.materialLink=true;
    this.fieldVisiblity.sdsLink=true;
    this.fieldVisiblity.sdsAttachment=true;
    this.fieldVisiblity.additionalAttachment=true;
    this.fieldVisiblity.additionalAttachment2=true;

    if(this.isVendorUser){
      this.fieldVisiblity.requestor=false;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
    }
    
  }
    retrieveHeaderValues() {
        this.title = "Truckload";
      }
    bindConditionalFields(){
        console.log("")
    }
}

import { Dropdown } from "primeng/dropdown";
import { RequestFormData } from "./request-common.data";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";

export class TruckLoadRequestFormData extends RequestFormData {

    equipmentTypeId: number=0;
    equipmentModelId: number=0;
    modalId: number=0;
    naCountry :string="";
    valueOfInvoice:string="";
    internModel: string="";
    //Service provider
    days: number=0;
    dropTrail: string="";
    monthlyVolume: number=0;
    monthlyVolumeUnitId: number=0;
    hazardous: string="";
    tempControl: string="";
    unNumber: string="";
    seasonal: string="";
    seasonalComments: string="";
    tankerEndorsement: string="";

    equipmentTypeSelectedList: DropDownMaster[]=[];
    equipmentTypeSelectedIds:string="";
    packageTypeId: number=0;
    remarkPackageType: string="";
    shippingWithPallet: string="";
    palletSize: string="";
    ftlOrLtlId: number=0;
    ftlTruckType: string="";
    ltlRangeSelectedList: DropDownMaster[]=[];
    ltlRangeSelectedIds:string="";
    allowTwoLayerStack: string="";
    intermodalTypeId: number=0;
    containerTypeId: number=0;

    shipmentFrequency: number=0;
    driverSupportRequired: string="";

    dangerousGoodsClassId:number=0;

    

}

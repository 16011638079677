import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PackageIntermodalRequestFormModel } from 'src/app/requests/package-intermodal-request-form.model';
import { PackageIntermodalRequestMasterModel } from 'src/app/requests/package-intermodal-request-master.model';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { RequestMasterModel } from 'src/app/requests/request-master.model';
import { ProductModel } from 'src/app/shared/product/products.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { CommonRequestComponent } from '../../request-form-common.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppServiceService } from 'src/app/services/app-service.service';
import { PackageIntermodalRequestMasterData } from 'src/app/requests/data/masterdata/package-intermodal-request-master.data';
import { PackageIntermodalRequestFormData } from 'src/app/requests/data/formdata/package-intermodal-request-form.data';


@Component({
  selector: 'app-package-intermodal-request-form-na',
  templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
  styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, ProductModel,
    { provide: RequestFormModel, useClass: PackageIntermodalRequestFormModel },
    { provide: RequestMasterModel, useClass: PackageIntermodalRequestMasterModel },

  ]
})
export class PackageIntermodalRequestFormNaComponent extends CommonRequestComponent implements OnInit {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;

  override requestData: any
  override requestMaster: any

  pageLanguage: string = "";
  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,
    private spinners: NgxSpinnerService, route: ActivatedRoute, translate: TranslateService, private _appServ: AppServiceService) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route, translate);
    this.subscriptionName = this._appServ.getUpdate().subscribe(message => {
      this.messageReceived = message;
      let newLang=sessionStorage.getItem('selectedLanguage');

      if(this.pageLanguage!=newLang && this.pageLanguage!=""){
        this.ngOnInit();
      }
    });
  }


  ngOnInit(): void {
    let selectedLanguage=sessionStorage.getItem('selectedLanguage');
    this.transportType = 8;
    this.startDateLable = "START_DATE";
    this.pageLanguage = sessionStorage['selectedLanguage'];
    this.requestMaster = new PackageIntermodalRequestMasterData();
    this.requestData = new PackageIntermodalRequestFormData();
    this.requestData.transportType = 8;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    this.validateloggedInUser();
    this.retrieveMasterData(this.transportType);
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }
  bindfiledVisiblity() {
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.IsProductReadOnly = false;
    this.fieldVisiblity.equipmentType = true;
    this.fieldVisiblity.naCountry = true;

    // Service Provider
    this.fieldVisiblity.volumeType = true;
    this.fieldVisiblity.monthlyVolume = true;
    this.fieldVisiblity.annualVolume = true;
    this.fieldVisiblity.tempControl = true;
    this.fieldVisiblity.dropTrail = true;
    this.fieldVisiblity.days = true;
    this.fieldVisiblity.hazardousMandatory = true;
    this.fieldVisiblity.seasonal = true;
    this.fieldVisiblity.seasonalComments = true;
    this.fieldVisiblity.unNumber = true;
    this.fieldVisiblity.tankerEndorsement = true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant=true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant=true;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = true;
    this.fieldVisiblity.costVsService = true;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;
    this.fieldVisiblity.hazardousSDSMandatory = true;
  }
  handlehazardousChange(event:any)
  {
    if(this.requestData.hazardous=="No")
    {
    
      this.requestData.unNumber="";
    }
  }
  
  override handleSeasonalChange(event:any)
  {
    if(this.requestData.seasonal=="No")
    {
      this.requestData.seasonalComments="";
     
    }
  }
  retrieveHeaderValues() {
    this.title = "Package Intermodal";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  public override clearForm() {
    this.OnCancel();
  }
  public override OnCancel() {
    this.clearData(8);
    this.requestData.equipmentTypeId = 0;


    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.hazardous = "";
    this.requestData.tempControl = "";
    this.requestData.unNumber = "";
    this.requestData.seasonal = "";
    this.requestData.seasonalComments = "";
    this.requestData.tankerEndorsement = "";
    this.clearData(8);
  }
  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
    this.requestData.monthlyVolumeUnitId=this.requestData.monthlyVolumeUnitId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
    this.requestData.campaignSeasonalVolumeUid=this.requestData.campaignSeasonalVolumeUid??0;
    this.createRequest();
  }
  onAnnualVolumeChange(event: any){
    console.log('');
  }
  onArticleComplete(event:any){
    super.retrieveProductDetails(event);
    
   }
  onArticleSelected(event:any){
  
    this.processArticleNumber(event);

  }
  public override bindConditionalFields() {
    console.log("");
  }
  public override onPlantSelectPopulate(event: any, index: number) {
    console.log("");
  }
  resetArticleData() {
    console.log("")
    
  }
   GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber,
      value:this.requestData.product
    };
    
  }
  processArticleNumber(event:any){
    this.requestMaster.mfgOpts=[];
    this.selectedMFGCode="";
    this.requestData.articleNumber = event.label;
    this.requestData.product=event.value;   
    
  }
  onSpecialRequirement(event:any){  }
  requestTypeChangeEvent(event:any){
    console.log('');
}
}

import { RequestFormData } from "./request-common.data";

export class PackageIntermodalRequestFormData extends RequestFormData {
    equipmentTypeId: number=0;
    naCountry :string="";

    //Service provider
    days: number=0;
    dropTrail: string="";
    monthlyVolume: number=0;
    monthlyVolumeUnitId: number=0;
    hazardous: string="";
    tempControl: string="";
    unNumber: string="";
    seasonal: string="";
    seasonalComments: string="";
    tankerEndorsement: string="";
}

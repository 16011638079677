import { LessThanTruckloadRequestFormData } from "./less-than-truckload-request-form.data";

export class LessThanTruckloadRequestDetails extends LessThanTruckloadRequestFormData {

    campaignSeasonalVolumeUidValue:string="";
    equipmentTypeMultiple:string="";
    annualVolumeUnit: string="";
    equipmentType: string="";
    generalHazmatLoad: string="";

    reasonForSelection: string=""; 

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    transportTypeName: string="";
    originPlantCode:string="";
    destinationPlantCode:string=""
}

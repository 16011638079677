import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LessThanTruckloadRequestFormModel } from 'src/app/requests/less-than-truckload-request-form.model';
import { LessThanTruckloadRequestMasterModel } from 'src/app/requests/less-than-truckload-request-master.model';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { RequestMasterModel } from 'src/app/requests/request-master.model';
import { ProductModel } from 'src/app/shared/product/products.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';
import { CommonRequestComponent } from '../../request-form-common.component';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AppServiceService } from 'src/app/services/app-service.service';
import { LessThanTruckloadRequestMasterData } from 'src/app/requests/data/masterdata/less-than-truckload-request-master.data';
import { LessThanTruckloadRequestFormData } from 'src/app/requests/data/formdata/less-than-truckload-request-form.data';
import { RequestData } from 'src/app/requests/data/formdata/request.data';
import { TruckLoadRequestDetailFormCommonComponent } from 'src/app/requests/request-details/truck-load-request-details/truck-load-request-detail-form-common/truck-load-request-detail-form-common.component';
import { DropDownMaster } from 'src/app/shared/dropdown-master.data';

@Component({
  selector: 'app-less-than-truckload-request-form-na',
  templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
  styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, ProductModel,
    { provide: RequestFormModel, useClass: LessThanTruckloadRequestFormModel },
    { provide: RequestMasterModel, useClass: LessThanTruckloadRequestMasterModel },

  ]


})
export class LessThanTruckloadRequestFormNaComponent extends CommonRequestComponent implements OnInit, OnDestroy {


  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;

  override requestData: any
  override requestMaster: any

  pageLanguage: string = "";
  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,
    private spinners: NgxSpinnerService, route: ActivatedRoute, translate: TranslateService, private _appServ: AppServiceService) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route, translate);
    this.subscriptionName = this._appServ.getUpdate().subscribe(message => {
      this.messageReceived = message;
      let newLang=sessionStorage.getItem('selectedLanguage');

      if(this.pageLanguage!=newLang && this.pageLanguage!=""){
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    let selectedLanguage=sessionStorage.getItem('selectedLanguage');
    this.transportType = 7;
    this.startDateLable = "START_DATE";
    this.pageLanguage = sessionStorage['selectedLanguage'];
    this.requestMaster = new LessThanTruckloadRequestMasterData();
    this.requestData = new LessThanTruckloadRequestFormData();
    this.requestData.equipmentTypeSelectedIds="";
    this.requestData.transportType = 7;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    this.validateloggedInUser();
    this.retrieveMasterData(this.transportType);
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);
  }

  ngOnDestroy() {
    this.subscriptionName.unsubscribe();
  }
  bindfiledVisiblity() {
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.IsProductReadOnly = false;
    this.fieldVisiblity.equipmentType=true;
    this.fieldVisiblity.naCountry = true;

    // Service Provider
    this.fieldVisiblity.volumeType = true;
    this.fieldVisiblity.annualVolume = true;
    this.fieldVisiblity.tempControl = true;
    this.fieldVisiblity.dropTrail = true;
    this.fieldVisiblity.days = true;
    this.fieldVisiblity.hazardousMandatory = true;
    this.fieldVisiblity.unNumber = true;
    this.fieldVisiblity.tankerEndorsement = true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant = true;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;
    this.fieldVisiblity.hazardousSDSMandatory = true;
    this.fieldVisiblity.lessThanTruckLoad = true;
  }
  handlehazardousChange(event: any) {
    if (this.requestData.hazardous == "No") {

      this.requestData.unNumber = "";
    }
  }

  override handleSeasonalChange(event: any) {
    if (this.requestData.seasonal == "No") {
      this.requestData.seasonalComments = "";

    }
  }

  retrieveHeaderValues() {
    this.title = "Less-Than-Truckload";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    console.log("")
  }

  onAnnualVolumeChange(event: any) {
    console.log('');
  }
  onArticleComplete(event: any) {
    super.retrieveProductDetails(event);

  }
  onArticleSelected(event: any) {

    this.processArticleNumber(event);

  }
  public override clearForm() {
    this.OnCancel();
  }
  bindConditionalFields() {
    let LTTReqData = this.requestData;
    if (LTTReqData.naCountry === 'Canada') {
      let filteredEquipment:any = this.requestMaster.equipmentTypeMasterCombined.filter((element: any) => element.name === 'EquipmentTypeCanada');
      this.requestMaster.equipementTypeMaster=filteredEquipment.map((element:any)=>{return {label:element.label,value:element.value}});
      
      this.fieldVisiblity.interModel = true;
      this.fieldVisiblity.seaFreightPreLeg = true;
      this.fieldVisiblity.seaFreightOnCarriage = true;
      this.fieldVisiblity.averageWeightOfShipment = true;
      this.fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment = true;
      this.fieldVisiblity.palletDimensions = true;
      this.fieldVisiblity.generalHazmatLoad=false;
      this.fieldVisiblity.tempControlGeneralHazmat=false;
      this.fieldVisiblity.equipmentTypeMultiSelect=false;
      this.fieldVisiblity.isOriginPostalCodeRequired=false;
      this.fieldVisiblity.isOriginPlantCodeRequired=false;
      this.fieldVisiblity.tankerEndorsement=false;
    }
    else if(LTTReqData.naCountry==='Mexico') {
      let filteredEquipment:any = this.requestMaster.equipmentTypeMasterCombined.filter((element: any) => element.name === 'EquipmentTypeMexico');
      this.requestMaster.equipementTypeMaster=filteredEquipment.map((element:any)=>{return {label:element.label,value:element.value}});

      this.fieldVisiblity.isOriginPostalCodeRequired=true;
      this.fieldVisiblity.isOriginPlantCodeRequired=true;
      this.fieldVisiblity.interModel = false;
      this.fieldVisiblity.seaFreightPreLeg = false;
      this.fieldVisiblity.seaFreightOnCarriage = false;
      this.fieldVisiblity.averageWeightOfShipment = false;
      this.fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment = false;
      this.fieldVisiblity.palletDimensions = false;
      this.fieldVisiblity.equipmentTypeMultiSelect=true;
      this.fieldVisiblity.generalHazmatLoad=true;
      this.fieldVisiblity.tempControlGeneralHazmat=true;
      this.fieldVisiblity.tankerEndorsement=true;

      let equipmentTypeMultipleValues=this.requestData.equipmentTypeSelectedIds.split(',');
      this.requestData.equipmentTypeSelectedList=[];  
      equipmentTypeMultipleValues.forEach((element:any) => {    
        this.requestData.equipmentTypeSelectedList.push(this.requestMaster.equipementTypeMaster.find((dd:any)=>dd.value==parseInt(element)));
      });
    }
    
  }

  override onCountryCheck(event: any) {
    console.log(event.value);
    this.requestMaster.equipementTypeMaster=[];
    if (event.value === 'Canada') {
      let filteredEquipment:any = this.requestMaster.equipmentTypeMasterCombined.filter((element: any) => element.name === 'EquipmentTypeCanada');
      this.requestMaster.equipementTypeMaster=filteredEquipment.map((element:any)=>{return {label:element.label,value:element.value}});
      this.requestMaster.originCountryMaster=this.requestMaster.generalCountryMaster.filter((element:DropDownMaster)=>element.label==='Canada');
      this.fieldVisiblity.interModel = true;
      this.fieldVisiblity.seaFreightPreLeg = true;
      this.fieldVisiblity.seaFreightOnCarriage = true;
      this.fieldVisiblity.averageWeightOfShipment = true;
      this.fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment = true;
      this.fieldVisiblity.palletDimensions = true;
      this.fieldVisiblity.generalHazmatLoad=false;
      this.fieldVisiblity.tempControlGeneralHazmat=false;
      this.requestData.generalHazmatLoadId=0;
      this.requestData.tempControlGeneralHazmat="";
      this.fieldVisiblity.equipmentTypeMultiSelect=false;
      this.requestData.equipmentTypeSelectedList=[];
      this.requestData.equipmentTypeSelectedIds="";
      this.fieldVisiblity.isOriginPlantCodeRequired=false;
      this.fieldVisiblity.isOriginPostalCodeRequired=false;
      this.fieldVisiblity.tankerEndorsement=false;
    }
    else if(event.value==='Mexico') {
      let filteredEquipment:any = this.requestMaster.equipmentTypeMasterCombined.filter((element: any) => element.name === 'EquipmentTypeMexico');
      this.requestMaster.equipementTypeMaster=filteredEquipment.map((element:any)=>{return {label:element.label,value:element.value}});
      this.requestMaster.originCountryMaster=this.requestMaster.generalCountryMaster.filter((element:DropDownMaster)=>element.label==='Mexico');
      this.fieldVisiblity.interModel = false;
      this.fieldVisiblity.seaFreightPreLeg = false;
      this.fieldVisiblity.seaFreightOnCarriage = false;
      this.fieldVisiblity.averageWeightOfShipment = false;
      this.fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment = false;
      this.fieldVisiblity.palletDimensions = false;
      this.fieldVisiblity.generalHazmatLoad=true;
      this.fieldVisiblity.tempControlGeneralHazmat=true;
      this.fieldVisiblity.tankerEndorsement=true;
      this.requestData.internModel="";
      this.requestData.seaFreightPreLeg="";
      this.requestData.seaFreightOnCarriage="";
      this.requestData.averageWeightOfShipment = 0;
      this.requestData.averageNumberOfPalletsOrTotesPerShipment = 0;
      this.requestData.palletDimensions = "";
      this.requestData.equipmentTypeId=0;
      this.fieldVisiblity.equipmentTypeMultiSelect=true;
      this.fieldVisiblity.isOriginPlantCodeRequired=true;
      this.fieldVisiblity.isOriginPostalCodeRequired=true;
    }
   
  }
  public override onPlantSelectPopulate(event: any, index: number) {
    console.log('');
  }
  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber,
      value: this.requestData.product
    };

  }

  processArticleNumber(event: any) {
    this.requestMaster.mfgOpts = [];
    this.selectedMFGCode = "";
    this.requestData.articleNumber = event.label;
    this.requestData.product = event.value;

  }
  override OnCancel() {
    this.clearData(7);
    this.requestData.equipmentTypeId = 0;
    this.requestData.internModel="";
    this.requestData.seaFreightPreLeg="";
    this.requestData.seaFreightOnCarriage="";
    this.requestData.averageWeightOfShipment="";
    this.requestData.averageNumberOfPalletsOrTotesPerShipment="";
    this.requestData.palletDimensions="";
    this.requestData.equipmentTypeSelectedList=[];
    this.requestData.equipmentTypeSelectedIds="";
    this.requestData.equipmentTypeId=0;
    this.requestData.naCountry="";
    this.requestData.articleNumber="";
    this.requestData.generalHazmatLoadId=0;
    this.requestData.tempControlGeneralHazmat="";

    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.hazardous = "";
    this.requestData.tempControl = "";
    this.requestData.unNumber = "";
    this.requestData.seasonal = "";
    this.requestData.seasonalComments = "";
    this.requestData.tankerEndorsement = "";
    this.clearData(7);
    this.resetArticleData();
  }

  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.equipmentTypeId = this.requestData.equipmentTypeId ?? 0;
    this.requestData.monthlyVolumeUnitId = this.requestData.monthlyVolumeUnitId ?? 0;
    this.requestData.annualVolumeUnitId = this.requestData.annualVolumeUnitId ?? 0;
    this.requestData.campaignSeasonalVolumeUid = this.requestData.campaignSeasonalVolumeUid ?? 0;
    if(this.requestData.naCountry==='Mexico'){
    this.requestData.equipmentTypeSelectedIds=this.requestData.equipmentTypeSelectedList.map((element:any)=>element.value).join(',');
    }
    this.createRequest();
  }


  onSpecialRequirement(event: any) { }
  requestTypeChangeEvent(event: any) {
    console.log('');
  }
  onIntermodalAcceptChange(event:any){
console.log();
  }
  onTempControlChange(event:any){
    console.log();
  }
}

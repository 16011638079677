import { TruckLoadRequestFormData } from "./truck-load-request-form.data";

export class TruckLoadRequestDetails extends TruckLoadRequestFormData {

    campaignSeasonalVolumeUidValue:string="";

    annualVolumeUnit: string="";
    equipmentType: string="";
    equipmentModel:string="";
    modal: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string=""; 

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    transportTypeName: string="";
    originPlantCode:string="";
    destinationPlantCode:string="";

    equipmentTypeMultiple:string="";
    packageType: string="";
    ftlOrLtl: string="";
    ltlRangeSelectedNames: string="";
    intermodalType: string="";
    containerType: string="";

    dangerousGoodsClassName:string="";


}

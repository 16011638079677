import { PackageIntermodalRequestFormData } from "./package-intermodal-request-form.data";

export class PackageIntermodalRequestDetails extends PackageIntermodalRequestFormData{

    campaignSeasonalVolumeUidValue:string="";

    annualVolumeUnit: string="";
    equipmentType: string="";
    monthlyVolumeUnit: string="";
    reasonForSelection: string=""; 

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    
    requestType: string="";
    transportTypeName: string="";
    originPlantCode:string="";
    destinationPlantCode:string=""
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { RequestMasterData } from "./data/masterdata/request-master.data";

import { RequestMasterModel } from "./request-master.model";
import { RequestMasterService } from "./request-master.service";
import { TruckLoadRequestMasterData } from "./data/masterdata/truck-load-request-master.data";


@Injectable()

export class TruckLoadRequestMasterModel extends  RequestMasterModel
{

    constructor( service:RequestMasterService)
    {
        super(service);
        console.log("constructor")
    }
    public responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>) {
        let datacollection: TruckLoadRequestMasterData = new TruckLoadRequestMasterData();
        datacollection.equipementTypeMaster=this.convertResponsetoArray(apiResponse.data.equipementTypeMaster,false);
        datacollection.packageTypeMaster=this.convertResponsetoArray(apiResponse.data.packageTypeMaster,false);
        datacollection.ftlMaster=this.convertResponsetoArray(apiResponse.data.ftlMaster,false);
        datacollection.ltlrangeMaster=this.convertResponsetoArray(apiResponse.data.ltlrangeMaster,false);
        datacollection.intermodalTypeMaster=this.convertResponsetoArray(apiResponse.data.intermodalTypeMaster,false);
        datacollection.containerTypeMaster=this.convertResponsetoArray(apiResponse.data.containerTypeMaster,false);
        datacollection.dangerousGoodsClassMaster=this.convertResponsetoArray(apiResponse.data.dangerousGoodsClassMaster,false);

        datacollection.equipmentModelMaster=this.convertResponsetoArray(apiResponse.data.equipmentModelMaster,false);
        datacollection.modalMaster=this.convertResponsetoArray(apiResponse.data.modalMaster,false);
        datacollection = this.processCommonResult(apiResponse, datacollection) as TruckLoadRequestMasterData;
        subject.next(datacollection);
    }

}



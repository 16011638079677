<div *ngIf="userAccessPermission.site_access.lpAccess" class="container-fluid" id="rateForm">

    <div class="container container-Top">
        <div class="req-heading">
            <!-- Heading -->
            <h5>
                {{title | translate}}
            </h5>
            <!--End of Heading-->

            <div class="formDiv request-form">
                <p-accordion [multiple]="true">
                    <!-- General Info Start -->
                    <p-accordionTab header="{{'GEN_INFO' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <!-- left -->


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requestType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="requestType" class="col-form-label cust-label">{{'REQUEST_TYPE'
                                                | translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="requestType" class="cust-dropdown"
                                                [options]="requestMaster.requestTypeMaster"
                                                [(ngModel)]="requestData.requestTypeId"
                                                [showClear]="isDropdownClear && requestData.requestTypeId"
                                                placeholder="{{'Select the Request Type' | translate}}"
                                                (onChange)="requestTypeChangeEvent($event)">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.naCountry">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="naCountry" class="col-form-label cust-label">{{'COUNTRY' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-dropdown name="naCountry" styleClass="cust-dropdown"
                                                [options]="fieldVisiblity.lessThanTruckLoad?requestMaster.generalCountryMaster:requestMaster.countryMaster"
                                                [(ngModel)]="requestData.naCountry" (onChange)="onCountryCheck($event)"
                                                placeholder="{{'Select the Country' | translate}}"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.startDate">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="startDate" class="col-form-label cust-label ">{{startDateLable |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-calendar name="startDate" [showIcon]="true" inputid="icon"
                                                dateFormat="yy-mm-dd" [minDate]="minDateValue" [locale]="lang"
                                                [(ngModel)]="requestData.startDate" (onSelect)="setStartDate($event)">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>


                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requestor">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="requestor" class="col-form-label cust-label ">{{'REQUESTOR' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" name="requestor"
                                                id="requestor" [(ngModel)]="requestData.requestor" readonly>

                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.bussinessunit">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="businessUnit"
                                                class="col-form-label cust-label">{{'BUSINESS_UNIT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" id="businessUnit"
                                                name="businessUnit" [(ngModel)]="requestData.businessUnit" readonly>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.articleNumberfreeText">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="articleNumber"
                                                class="col-form-label cust-label">{{'ARTICLE_NUMBER'|
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <input type="text" autocomplete="off" class="form-control"
                                                id="articleNumber" name="articleNumber"
                                                [(ngModel)]="requestData.articleNumber">

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.articleNumber">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12"
                                            [ngClass]="fieldVisiblity.articleNumberMandatory?'cust-mandatory':''">
                                            <label for="articleNumber"
                                                class="col-form-label cust-label">{{'ARTICLE_NUMBER'|
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <div *ngIf="!artMode">
                                                <p-autoComplete id="articleNumber" (onClear)="onArticleClear($event)"
                                                    [forceSelection]="true" name="articleNumber"
                                                    [(ngModel)]="selectedArticle" styleClass="w-full"
                                                    inputStyleClass="w-full"
                                                    [suggestions]="this.requestMaster.articleOpts" field="label"
                                                    (onSelect)="onArticleSelected($event)" [minLength]="4"
                                                    (completeMethod)="onArticleComplete($event)"></p-autoComplete>
                                            </div>
                                            <div *ngIf="editMode && artMode">
                                                <p style="margin-top:7px">{{requestData.articleNumber}} <span
                                                        style="color:blue; cursor:pointer"
                                                        (click)="ViewArticleInput()">{{'EDIT_ARTICLE' |
                                                        translate}}</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.product">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="product" class="col-form-label cust-label">{{'PRODUCT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="fieldVisiblity.articleNumber">
                                            <input type="text" autocomplete="off" class="form-control" id="product"
                                                name="product" [(ngModel)]="requestData.product"
                                                [readonly]="fieldVisiblity.IsProductReadOnly">
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="!fieldVisiblity.articleNumber">
                                            <input type="text" autocomplete="off" class="form-control" id="product"
                                                name="product" [(ngModel)]="requestData.product">
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.mfg||fieldVisiblity.mfgDescription">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12" *ngIf="fieldVisiblity.mfg"
                                            [ngClass]="fieldVisiblity.mfgMandatory?'cust-mandatory':''">
                                            <label for="Mfg" class="col-form-label cust-label adjust-label">{{'MFG'|
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-3 col-sm-12" *ngIf="fieldVisiblity.mfgDescription">
                                            <label for="Mfg" class="col-form-label cust-label">{{'MFG_DESCRIPTION' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="!fieldVisiblity.mfgDropdown">
                                            <input type="text" autocomplete="off" class="form-control" id="MFGCode"
                                                name="MFGCode" [(ngModel)]="requestData.mfg" readonly>
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="fieldVisiblity.mfgDropdown">
                                            <p-dropdown name="mfg" class="cust-dropdown"
                                                [options]="requestMaster.mfgOpts" [showClear]="isDropdownClear"
                                                [(ngModel)]="selectedMFGCode" appendTo="body"
                                                placeholder="{{'Select the MFG' | translate}}"
                                                (onChange)="onMFGChange($event)">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.freightClass">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="freightClass"
                                                class="col-form-label cust-label">{{'FREIGHT_CLASS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" id="freightClass"
                                                name="freightClass" [(ngModel)]="requestData.freighClass" readonly>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaninCost">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="cleaningCost"
                                                class="col-form-label cust-label">{{'CLEANING_COST' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" id="cleaningCost"
                                                name="cleaningCost" [(ngModel)]="requestData.cleaningCost" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.packageType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="packageType" class="col-form-label cust-label ">{{'PACKAGE_TYPE'
                                                | translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="packageType" class="cust-dropdown"
                                                [options]="requestMaster.packageTypeMaster"
                                                [showClear]="isDropdownClear && requestData.preferedTransportModeId"
                                                [(ngModel)]="requestData.packageTypeId" appendTo="body"
                                                placeholder="{{'Select the Package Type' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.remarkPackageType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="remarkPackageType"
                                                class="col-form-label cust-label">{{'REMARK_PACKAGE_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" maxlength="100" autocomplete="off"
                                                name="remarkPackageType" id="remarkPackageType" class="form-control"
                                                [(ngModel)]="requestData.remarkPackageType">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.shippingWithPallet">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="shippingWithPallet"
                                                class="col-form-label cust-label">{{'SHIPPING_WITH_PALLET' | translate}}
                                            </Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="shippingWithPallet1" id="shippingWithPallet1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.shippingWithPallet">
                                                <label class="custom-control-label" for="shippingWithPallet1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="shippingWithPallet2" id="shippingWithPallet2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.shippingWithPallet">
                                                <label class="custom-control-label" for="shippingWithPallet2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.palletSize">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="palletSize" class="col-form-label cust-label">{{'PALLET_SIZE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" maxlength="100" autocomplete="off" name="palletSize"
                                                id="palletSize" class="form-control"
                                                [(ngModel)]="requestData.palletSize">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ftlOrLtl">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="ftlOrLtl" class="col-form-label cust-label ">{{'FTL_LTL' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="ftlOrLtl" class="cust-dropdown"
                                                [options]="requestMaster.ftlMaster"
                                                [showClear]="isDropdownClear && requestData.ftlOrLtlId"
                                                [(ngModel)]="requestData.ftlOrLtlId" appendTo="body"
                                                placeholder="{{'Select the FTL/LTL' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.equipmentType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="equipmentType"
                                                class="col-form-label cust-label ">{{'EQUIPMENT_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div *ngIf="!fieldVisiblity.equipmentTypeMultiSelect"
                                            class="col-md-8 col-sm-12">

                                            <p-dropdown name="equipmentType" class="cust-dropdown"
                                                [options]="requestMaster.equipementTypeMaster"
                                                [(ngModel)]="requestData.equipmentTypeId" appendTo="body"
                                                placeholder="{{'Select the Equipment Type' | translate}}">

                                            </p-dropdown>
                                        </div>
                                        <div *ngIf="fieldVisiblity.equipmentTypeMultiSelect" class="col-md-8 col-sm-12">

                                            <p-multiSelect name="equipmentType" class="cust-dropdown vol-class"
                                                [options]="requestMaster.equipementTypeMaster" optionLabel="label"
                                                [(ngModel)]="requestData.equipmentTypeSelectedList"
                                                aria.selectAll="all selected"
                                                defaultLabel="{{'Select the Equipment Type' | translate}}">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ftlTruckType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="ftlTruckType"
                                                class="col-form-label cust-label">{{'FTL_TRUCK_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" maxlength="100" autocomplete="off" name="ftlTruckType"
                                                id="ftlTruckType" class="form-control"
                                                [(ngModel)]="requestData.ftlTruckType">
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.ltlRange">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="ltlRange" class="col-form-label cust-label ">{{'LTL_RANGE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-multiSelect name="ltlRange" class="cust-dropdown vol-class"
                                                [options]="requestMaster.ltlrangeMaster" optionLabel="label"
                                                [(ngModel)]="requestData.ltlRangeSelectedList"
                                                aria.selectAll="all selected"
                                                defaultLabel="{{'Select the LTL Range' | translate}}">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.allowTwoLayerStack">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="allowTwoLayerStack"
                                                class="col-form-label cust-label">{{'ALLOW_TWO_LAYER_STACK' |
                                                translate}} </Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="allowTwoLayerStack1" id="allowTwoLayerStack1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.allowTwoLayerStack">
                                                <label class="custom-control-label" for="allowTwoLayerStack1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="allowTwoLayerStack2" id="allowTwoLayerStack2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.allowTwoLayerStack">
                                                <label class="custom-control-label" for="allowTwoLayerStack2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.equipmentModel">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="equipmentModel"
                                                class="col-form-label cust-label ">{{'EQUIPMENT_MODEL' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="equipmentModel" class="cust-dropdown"
                                                [options]="requestMaster.equipmentModelMaster"
                                                [(ngModel)]="requestData.equipmentModelId" appendTo="body"
                                                placeholder="{{'Select the Equipment Model' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.modal">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="modal" class="col-form-label cust-label ">{{'MODAL' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="modal" class="cust-dropdown"
                                                [options]="requestMaster.modalMaster" [(ngModel)]="requestData.modalId"
                                                appendTo="body" placeholder="{{'Select the Modal' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.generalHazmatLoad">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="generalHazmatLoad"
                                                class="col-form-label cust-label ">{{'IS_GENERAL_HAZMAT_LOAD' |
                                                translate}}</label>
                                        </div>
                                        <div
                                            class="col-md-8 col-sm-12">

                                            <p-dropdown name="generalHazmatLoad" class="cust-dropdown"
                                                [options]="requestMaster.generalHazmatLoadMaster"
                                                [(ngModel)]="requestData.generalHazmatLoadId" appendTo="body"
                                                placeholder="{{'Select the General/Hazmat' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="form-group wrap-div"
                                        *ngIf="fieldVisiblity.tempControlGeneralHazmat">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <Label for="tempControlGeneralHazmat"
                                                    class="col-form-label cust-label">{{'IS_THIS_TEMP_CONTROL_GENERAL_HAZMAT'
                                                    |
                                                    translate}} </Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="tempControlGeneralHazmat"
                                                        id="tempControlGeneralHazmat1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.tempControlGeneralHazmat">
                                                    <label class="custom-control-label"
                                                        for="tempControlGeneralHazmat1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="tempControlGeneralHazmat"
                                                        id="tempControlGeneralHazmat2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.tempControlGeneralHazmat">
                                                    <label class="custom-control-label"
                                                        for="tempControlGeneralHazmat">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.interModel">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <Label for="intermodel"
                                                    class="col-form-label cust-label">{{'IS_INTERMODAL_ACCEPT' |
                                                    translate}}
                                                </Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="internModel1" id="internModel1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.internModel"
                                                        (change)="onIntermodalAcceptChange($event)">
                                                    <label class="custom-control-label" for="internModel1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="internModel" id="internModel2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.internModel"
                                                        (change)="onIntermodalAcceptChange($event)">
                                                    <label class="custom-control-label" for="internModel2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.seaFreightPreLeg">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <Label for="seaFreightPreLeg"
                                                    class="col-form-label cust-label">{{'IS_THIS_A_SEA_FREIGHT_PRE_LEG'
                                                    |
                                                    translate}} </Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="seaFreightPreLeg" id="seaFreightPreLeg1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.seaFreightPreLeg">
                                                    <label class="custom-control-label" for="seaFreightPreLeg1">{{'YES'
                                                        |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="seaFreightPreLeg" id="seaFreightPreLeg2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.seaFreightPreLeg">
                                                    <label class="custom-control-label" for="seaFreightPreLeg2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.seaFreightOnCarriage">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <Label for="seaFreightOnCarriage"
                                                    class="col-form-label cust-label">{{'IS_THIS_A_SEA_FREIGHT_ON_CARRIAGE'
                                                    | translate}} </Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="seaFreightOnCarriage"
                                                        id="seaFreightOnCarriage1" class="custom-control-input"
                                                        value="Yes" [(ngModel)]="requestData.seaFreightOnCarriage">
                                                    <label class="custom-control-label"
                                                        for="seaFreightOnCarriage1">{{'YES'
                                                        | translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="seaFreightOnCarriage"
                                                        id="seaFreightOnCarriage2" class="custom-control-input"
                                                        value="No" [(ngModel)]="requestData.seaFreightOnCarriage">
                                                    <label class="custom-control-label"
                                                        for="seaFreightOnCarriage2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.railCarType">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <label for="railCarType"
                                                    class="col-form-label cust-label ">{{'RAIL_CAR_TYPE' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-8 col-sm-12">

                                                <p-dropdown name="railCarType" class="cust-dropdown"
                                                    [options]="requestMaster.railCarType"
                                                    [(ngModel)]="requestData.railCartTypeId"
                                                    placeholder="{{'Select the Rail Car Type' | translate}}">

                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.preferedTransportMode">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 ">
                                                <label for="preferedTransportMode"
                                                    class="col-form-label cust-label ">Prefered Transport Mode</label>
                                            </div>
                                            <div class="col-md-8 col-sm-12">

                                                <p-dropdown name="preferedTransportMode" class="cust-dropdown"
                                                    [options]="requestMaster.preferedTransportModeMaster"
                                                    [showClear]="isDropdownClear && requestData.preferedTransportModeId"
                                                    [(ngModel)]="requestData.preferedTransportModeId" appendTo="body"
                                                    placeholder="{{'Select the Prefered Transport Mode' | translate}} ">

                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.twoStepBusiness">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <Label for="twoStepBusiness" class="col-form-label cust-label">2 Step
                                                    Business </Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="twoStepBusiness1" id="twoStepBusiness1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.twoStepBusiness">
                                                    <label class="custom-control-label" for="twoStepBusiness1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="twoStepBusiness2" id="twoStepBusiness2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.twoStepBusiness">
                                                    <label class="custom-control-label" for="twoStepBusiness2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.payerOption">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12  cust-mandatory">
                                                <Label for="payerOption"
                                                    class="col-form-label cust-label ">{{'PAYER_OPTION'
                                                    | translate}}</Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="payerOption" id="payerOption1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.payerOption"
                                                        (change)="payerOptionChange($event)">
                                                    <label class="custom-control-label" for="payerOption1">{{'BASF' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="payerOption" id="payerOption2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.payerOption"
                                                        (change)="payerOptionChange($event)">
                                                    <label class="custom-control-label"
                                                        for="payerOption2">{{'THIRD_PARTY' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.typeOfShipment">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <Label for="typeOfShipment"
                                                    class="col-form-label cust-label ">{{'TYPE_OF_SHIPMENT' |
                                                    translate}}</Label>
                                            </div>
                                            <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="typeOfShipment" id="typeOfShipment1"
                                                        class="custom-control-input" value="Spot"
                                                        [(ngModel)]="requestData.typeOfShipment"
                                                        (change)="onShipmentTypeChange($event)">
                                                    <label class="custom-control-label"
                                                        for="typeOfShipment1">{{'SPOT_SHIPMENT' | translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="typeOfShipment" id="typeOfShipment2"
                                                        class="custom-control-input" value="Dedicated"
                                                        [(ngModel)]="requestData.typeOfShipment"
                                                        (change)="onShipmentTypeChange($event)">
                                                    <label class="custom-control-label"
                                                        for="typeOfShipment2">{{'DEDICATED_SHIPMENT' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.monthlyKms">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <label for="monthlyKms"
                                                    class="col-form-label cust-label">{{'MONTHLY_KMS' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-8 col-sm-12">
                                                <input type="text" maxlength="20" autocomplete="off" name="monthlyKms"
                                                    id="monthlyKms" class="form-control"
                                                    [(ngModel)]="requestData.monthlyKms"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.intermodalType">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <label for="intermodalType"
                                                    class="col-form-label cust-label ">{{'INTERMODAL_TYPE' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-8 col-sm-12">

                                                <p-dropdown name="intermodalType" class="cust-dropdown"
                                                    [options]="requestMaster.intermodalTypeMaster"
                                                    [showClear]="isDropdownClear && requestData.intermodalTypeId"
                                                    [(ngModel)]="requestData.intermodalTypeId" appendTo="body"
                                                    placeholder="{{'Select the Intermodal Type' | translate}} ">

                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group wrap-div" *ngIf="fieldVisiblity.containerType">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 cust-mandatory">
                                                <label for="containerType"
                                                    class="col-form-label cust-label ">{{'CONTAINER_TYPE' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-8 col-sm-12">

                                                <p-dropdown name="containerType" class="cust-dropdown"
                                                    [options]="requestMaster.containerTypeMaster"
                                                    [showClear]="isDropdownClear && requestData.containerTypeId"
                                                    [(ngModel)]="requestData.containerTypeId" appendTo="body"
                                                    placeholder="{{'Select the Container Type' | translate}} ">

                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- right -->
                                    <div class="form-group wrap-div">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12">
                                                <!-- label -->
                                            </div>
                                            <div class="col-md-8 col-sm-12">
                                                <!-- control -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        </form>
                    </p-accordionTab>
                    <!-- General Info end -->

                    <!-- Service Provided Req Start -->
                    <p-accordionTab header="{{'SERVICE_PROV_REQ' | translate}}" [selected]="true">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <!--left-->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.volumeType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="" class="col-form-label cust-label">{{'VOLUME_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="csVolume1" id="csVolume1"
                                                    class="custom-control-input" value="Annual"
                                                    [(ngModel)]="requestData.volumeType"
                                                    (change)="onCsVolumeRequiredChange()">
                                                <label class="custom-control-label" for="csVolume1">{{'Annual' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="csVolume2" id="csVolume2"
                                                    class="custom-control-input" value="Campaign"
                                                    [(ngModel)]="requestData.volumeType"
                                                    (change)="onCsVolumeRequiredChange()">
                                                <label class="custom-control-label" for="csVolume2">{{'Campaign' |
                                                    translate}}</label>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.volumeType && (requestData.volumeType=='Campaign')">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12"
                                            [ngClass]="requestData.volumeType=='Campaign'?'cust-mandatory':''">
                                            <label for="csVolume"
                                                class="col-form-label cust-label">{{'CAMPAIGN_SEA_VOLUME' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <input type="text" maxlength="20" autocomplete="off"
                                                    class="form-control" id="csVolume" name="csVolume"
                                                    [(ngModel)]="requestData.campaignSeasonalVolume"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <p-dropdown class="cust-dropdown vol-class" name="csVolumeUOM"
                                                    [options]="requestMaster.unitMaster"
                                                    [(ngModel)]="requestData.campaignSeasonalVolumeUid"
                                                    placeholder="{{'Select the UOM' | translate}}"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group entire-campaign-label"
                                    *ngIf="fieldVisiblity.csVolumeTiming && (requestData.volumeType =='Campaign') ">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 cust-mandatory"
                                            [ngClass]="requestData.volumeType =='Campaign'?'cust-mandatory':''">
                                            <label style="text-align:left" for="csVolumeTiming"
                                                class="col-form-label adjust-label">{{'CS_VOLUME_TIMING' |
                                                translate}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.csVolumeTiming && (requestData.volumeType =='Campaign') ">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory"
                                            [ngClass]="requestData.volumeType =='Campaign'?'cust-mandatory':''">
                                            <label for="csStartDate" class="col-form-label cust-label ">{{'START_DATE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-calendar name="csStartDate" [showIcon]="true" inputid="icon"
                                                dateFormat="yy-mm-dd" [minDate]="minDateValue"
                                                (onSelect)="oncsStartDateSelect()" [locale]="lang"
                                                [(ngModel)]="requestData.csStartDate">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.csVolumeTiming && (requestData.volumeType =='Campaign') ">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory"
                                            [ngClass]="requestData.volumeType =='Campaign'?'cust-mandatory':''">
                                            <label for="csEndDate" class="col-form-label cust-label ">{{'END_DATE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-calendar name="csEndDate" [showIcon]="true" inputid="icon"
                                                dateFormat="yy-mm-dd" [minDate]="minEndDate" [locale]="lang"
                                                [(ngModel)]="requestData.csEndDate">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </div>


                                <!-- left -->

                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.annualVolume">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12"
                                            [ngClass]="(fieldVisiblity.annualVolume && requestData.volumeType=='Campaign')?'':'cust-mandatory'">
                                            <label for="annualVolume"
                                                class="col-form-label cust-label">{{'ANNUAL_VOLUME' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0 !important;">
                                                <input type="text" maxlength="20" autocomplete="off"
                                                    class="form-control" id="annualVolume" name="annualVolume"
                                                    [(ngModel)]="requestData.annualVolume"
                                                    (change)="onAnnualVolumeChange($event)"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                            <div class="col-md-6" style="padding: 0 !important;">
                                                <p-dropdown class="cust-dropdown vol-class" name="annualVolumeUOM"
                                                    [options]="requestMaster.unitMaster"
                                                    [(ngModel)]="requestData.annualVolumeUnitId"
                                                    placeholder="{{'Select the UOM' | translate}}"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.volumeType && requestData.volumeType =='Annual'">

                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 " *ngIf="requestData.volumeType =='Annual'"
                                            [ngClass]="requestData.volumeType =='Annual'?'cust-mandatory':''">
                                            <label for="annVolStartDate"
                                                class="col-form-label cust-label">{{'ANNUAL_VOLUME_START' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p-calendar name="annVolStartDate" [showIcon]="true" inputid="icon"
                                                dateFormat="yy-mm-dd" [minDate]="minDateValue" [locale]="lang"
                                                [(ngModel)]="requestData.annualVolumeStartDate">
                                            </p-calendar>
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.monthlyVolume">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="monthlyVolume"
                                                class="col-form-label cust-label ">{{'MONTHLY_VOLUME' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0 !important;">
                                                <input type="text" maxlength="20" autocomplete="off"
                                                    class="form-control" id="monthlyVolume" name="monthlyVolume"
                                                    [(ngModel)]="requestData.monthlyVolume"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                            <div class="col-md-6" style="padding: 0 !important;">

                                                <p-dropdown class="cust-dropdown vol-class" name="monthlyVolumeUOM"
                                                    [options]="requestMaster.unitMaster"
                                                    [(ngModel)]="requestData.monthlyVolumeUnitId"
                                                    placeholder="{{'Select the UOM' | translate}}"></p-dropdown>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.totalWeightShipment">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="totalWeightShipment"
                                                class="col-form-label cust-label">{{'TOTAL_WEIGHT_SHIPMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <input type="text" maxlength="20" autocomplete="off"
                                                    class="form-control" id="totalWeightShipment"
                                                    name="totalWeightShipment"
                                                    [(ngModel)]="requestData.totalWeightShipment"
                                                    (change)="onAnnualVolumeChange($event)"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <p-dropdown class="cust-dropdown vol-class"
                                                    name="totalWeightShipmentUOM"
                                                    [options]="requestMaster.totalWeightShipmentVolumeUnitMaster"
                                                    [(ngModel)]="requestData.totalWeightShipmentUid"
                                                    placeholder="{{'Select the UOM' | translate}}"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.centerRearUnload">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 "
                                            [ngClass]="fieldVisiblity.centerRearUnloadMandatory?'cust-mandatory':''">
                                            <label for="unload" class="col-form-label cust-label">{{'CEN_REAR_UNLOAD' |
                                                translate}}</label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="unload1" id="unload1"
                                                    class="custom-control-input" value="Center"
                                                    [(ngModel)]="requestData.centerRearUnload">
                                                <label class="custom-control-label" for="unload1">{{'CENTER' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="unload2" id="unload2"
                                                    class="custom-control-input" value="Rear"
                                                    [(ngModel)]="requestData.centerRearUnload">
                                                <label class="custom-control-label" for="unload2">{{'REAR' |
                                                    translate}}</label>
                                            </div>
                                            <div *ngIf="fieldVisiblity.centerRearOptionIndistinct"
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="unload3" id="unload3"
                                                    class="custom-control-input" value="Indistinct"
                                                    [(ngModel)]="requestData.centerRearUnload">
                                                <label class="custom-control-label" for="unload3">{{'IN_DISTINCT' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.shipmentFrequency">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="shipmentFrequency"
                                                class="col-form-label cust-label">{{'SHIPMENT_FREQUENCY' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="number" min='0' autocomplete="off" name="shipmentFrequency"
                                                id="shipmentFrequency" class="form-control"
                                                [(ngModel)]="requestData.shipmentFrequency">
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempControl">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12  cust-mandatory">
                                            <Label for="Temp Control" class="col-form-label cust-label">{{'TEMP_CONTROl'
                                                | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="tempControl1" id="tempControl1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.tempControl"
                                                    (change)="onTempControlChange($event)">
                                                <label class="custom-control-label" for="tempControl1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="tempControl" id="tempControl2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.tempControl"
                                                    (change)="onTempControlChange($event)">
                                                <label class="custom-control-label" for="tempControl2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.averageWeightOfShipment">
                                    <div class="row">
                                        <div class="col-md-3 cust-mandatory">
                                            <label for="averageWeightOfShipment"
                                                class="col-form-label cust-label">{{'AVERAGE_WEIGHT_OF_SHIPMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" autocomplete="off" class="form-control"
                                                name="averageWeightOfShipment" id="averageWeightOfShipment"
                                                [(ngModel)]="requestData.averageWeightOfShipment"
                                                (keypress)="numericOnly($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.averageNumberOfPalletsOrTotesPerShipment">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="averageNumberOfPalletsOrTotesPerShipment"
                                                class="col-form-label cust-label">{{'AVERAGE_NUMBER_OF_PALLETS_OR_TOTES_PER_SHIPMENT'
                                                | translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control"
                                                name="averageNumberOfPalletsOrTotesPerShipment"
                                                id="averageNumberOfPalletsOrTotesPerShipment"
                                                [(ngModel)]="requestData.averageNumberOfPalletsOrTotesPerShipment"
                                                (keypress)="numericOnly($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.palletDimensions">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="palletDimensions"
                                                class="col-form-label cust-label">{{'PALLET_DIMENSIONS (LENGTH x WIDTH x
                                                HEIGHT)' | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="palletDimensions"
                                                id="palletDimensions" class="form-control"
                                                [(ngModel)]="requestData.palletDimensions">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dropContainerId">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="dropContainer"
                                                class="col-form-label cust-label adjust-label">{{'DROP_CONTAINER' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control"
                                                name="dropContainer" id="dropContainer"
                                                [(ngModel)]="requestData.dropContainer">
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dropTrail">
                                    <div class="row">
                                        <div class="col-md-3"
                                            [ngClass]="fieldVisiblity.dropTrailMandatory?'cust-mandatory':''">
                                            <label for="dropTrailer" class="col-form-label cust-label">{{'DROP_TRAILER'
                                                | translate}}</label>
                                        </div>

                                        <div class="col-md-8" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="dropTrailer1" id="dropTrailer1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.dropTrail">
                                                <label class="custom-control-label" for="dropTrailer1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="dropTrailer2" id="dropTrailer2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.dropTrail">
                                                <label class="custom-control-label" for="dropTrailer2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.driverSupportRequired">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="driverSupportRequired"
                                                class="col-form-label cust-label">{{'DRIVER_SUPPORT_REQUIRED_FOR_UNLOAD'
                                                | translate}} </Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="driverSupportRequired1"
                                                    id="driverSupportRequired1" class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.driverSupportRequired">
                                                <label class="custom-control-label" for="driverSupportRequired1">{{'YES'
                                                    | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="driverSupportRequired2"
                                                    id="driverSupportRequired2" class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.driverSupportRequired">
                                                <label class="custom-control-label" for="driverSupportRequired2">{{'NO'
                                                    | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.days">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label for="days" class="col-form-label cust-label">{{'HOW_MANY_DAYS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" autocomplete="off" class="form-control" name="days"
                                                id="days" [(ngModel)]="requestData.days"
                                                (keypress)="numericOnly($event)">
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempRequirement">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="TempReq" class="col-form-label cust-label">{{'TEMP_REQ' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-dropdown name="tempRequirements" styleClass="cust-dropdown"
                                                [options]="requestMaster.tempRequirementMaster"
                                                [(ngModel)]="requestData.tempRequirementId"
                                                placeholder="{{'Select the Temp Requirements' | translate}} "></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.deliveryType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="TempReq" class="col-form-label cust-label">{{'DELIVERY_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-dropdown name="deliveryType" styleClass="cust-dropdown"
                                                [options]="requestMaster.delivery_type_master"
                                                [(ngModel)]="requestData.deliveryType"
                                                placeholder="{{'Select the Delivery Type' | translate}}"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tempRange">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="tempRange"
                                                class="col-form-label cust-label adjust-label">{{'TEMP_RANGE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="tempRange" id="tempRange"
                                                class="form-control" [(ngModel)]="requestData.tempRange">
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.priorProductRestriction">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="prodRestrictions"
                                                class="col-form-label cust-label adjust-label">{{'PRIOR_PROD_RESTR' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="prodRestrictions1" id="prodRestrictions1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.priorProductRestriction"
                                                    (change)="onPriorProductRestrionChange($event)">
                                                <label class="custom-control-label" for="prodRestrictions1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="prodRestrictions2" id="prodRestrictions2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.priorProductRestriction"
                                                    (change)="onPriorProductRestrionChange($event)">
                                                <label class="custom-control-label" for="prodRestrictions2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="requestData.priorProductRestriction=='Yes' && fieldVisiblity.priorProdDetailsRestrictions">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="prioProdDetailsRestrictionsLbl"
                                                class="col-form-label cust-label adjust-label">{{'PRIOR_PROD_DETAILS_RESTRICTIONS'
                                                | translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="textarea" autocomplete="off"
                                                name="priorProdDetailsRestrictions" id="priorProdDetailsRest"
                                                class="form-control"
                                                [(ngModel)]="requestData.priorProdDetailsRestrictions">
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.hazardous||fieldVisiblity.hazardousMandatory">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 " *ngIf="fieldVisiblity.hazardous">
                                            <Label for="hazardous"
                                                class="col-form-label cust-label adjust-label">{{'HAZARDOUS' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-3 col-sm-12  cust-mandatory"
                                            *ngIf="fieldVisiblity.hazardousMandatory">
                                            <Label for="hazardous"
                                                class="col-form-label cust-label adjust-label">{{'HAZARDOUS' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">

                                            <div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="hazardous" id="hazardous1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.hazardous"
                                                        (change)="handlehazardousChange($event)">
                                                    <label class="custom-control-label" for="hazardous1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="hazardous" id="hazardous2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.hazardous"
                                                        (change)="handlehazardousChange($event)">
                                                    <label class="custom-control-label" for="hazardous2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3"
                                            *ngIf="fieldVisiblity.hazardousSDSMandatory && requestData.hazardous=='Yes'">
                                            <label class="col-form-label cust-label" for="hazardousSDSMandatory"
                                                style="color: red;">{{'if_yes_must_add_SDS' | translate}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.unNumber && requestData.hazardous=='Yes'">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="UnNumber" class="col-form-label cust-label">{{'UN_NUMBER' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="UnNumber" id="UnNumber"
                                                class="form-control" [(ngModel)]="requestData.unNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.hazmatclassId  && requestData.hazardous=='Yes'">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="hazmatclassId"
                                                class="col-form-label cust-label">{{'HAZMAT_CLASS' | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <p-dropdown name="hazmatclass" styleClass="cust-dropdown"
                                                [options]="requestMaster.hazmatclassMaster"
                                                [(ngModel)]="requestData.hazmatclassId"
                                                placeholder="{{'Select the Hazmat Class' | translate}}"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.seasonal">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="seasonal" class="col-form-label cust-label">{{'SEASONAL' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="seasonal1" id="seasonal1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.seasonal"
                                                    (change)="handleSeasonalChange($event)">
                                                <label class="custom-control-label" for="seasonal1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="seasonal" id="seasonal2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.seasonal"
                                                    (change)="handleSeasonalChange($event)">
                                                <label class="custom-control-label" for="seasonal2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tankerEndorsement">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="tankerEndorsement"
                                                class="col-form-label cust-label">{{'TANKER_ENDORSEMENT' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="tankerEndorsement1" id="tankerEndorsement1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.tankerEndorsement">
                                                <label class="custom-control-label"
                                                    for="tankerEndorsement1">{{'LIQUID_MATERIAL' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="tankerEndorsement2" id="tankerEndorsement2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.tankerEndorsement">
                                                <label class="custom-control-label"
                                                    for="tankerEndorsement2">{{'DRY_MATERIAL' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.valueOfInvoice">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label for="valueOfInvoice"
                                                class="col-form-label cust-label">{{'VALUE_OF_INVOICE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" autocomplete="off" class="form-control"
                                                name="valueOfInvoice" id="valueOfInvoice"
                                                [(ngModel)]="requestData.valueOfInvoice">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.seasonalComments && requestData.seasonal=='Yes'">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="SeasonalComments"
                                                class="col-form-label cust-label">{{'SEASONAL_COMMENTS' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="SeasonalComments"
                                                id="SeasonalComments" class="form-control"
                                                [(ngModel)]="requestData.seasonalComments">
                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.kosher">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="Kosher" class="col-form-label cust-label">{{'KOSHER' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="Kosher1" id="Kosher1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.kosher">
                                                <label class="custom-control-label" for="Kosher1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="Kosher2" id="Kosher2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.kosher">
                                                <label class="custom-control-label" for="Kosher2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.materialTopLoad">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="topLoadingMaterial"
                                                class="col-form-label cust-label">{{'MAT_TOP_LOAD' | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <div style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="MaterialTopLoad" id="MaterialTopLoad1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.materialTopLoad">
                                                    <label class="custom-control-label" for="MaterialTopLoad1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="MaterialTopLoad" id="MaterialTopLoad2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.materialTopLoad">
                                                    <label class="custom-control-label" for="MaterialTopLoad2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.foodGradeWash">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="foodGradeWash"
                                                class="col-form-label cust-label">{{'FOOD_GRADE_WASH' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="foodGradeWash1" id="foodGradeWash1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.foodGradeWash">
                                                <label class="custom-control-label" for="foodGradeWash1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="foodGradeWash2" id="foodGradeWash2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.foodGradeWash">
                                                <label class="custom-control-label" for="foodGradeWash2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.loadingHrs">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="loadingHrs" class="col-form-label cust-label">{{'LOADING_HRS' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="loadingHrs" id="loadingHrs"
                                                class="form-control" [(ngModel)]="requestData.loadingHours">
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->

                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.unloadingHrs">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="unLoadingHrs"
                                                class="col-form-label cust-label">{{'UNLOADING_HRS' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="unLoadingHrs" id="unLoadingHrs"
                                                class="form-control" [(ngModel)]="requestData.unloadingHour">
                                        </div>
                                    </div>
                                </div>

                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.nitrogenBlanket">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="nitrogenBlanket"
                                                class="col-form-label cust-label">{{'NITRO_BLANKET' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="nitrogenBlanket1" id="nitrogenBlanket1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.nitrogenBlanket">
                                                <label class="custom-control-label" for="nitrogenBlanket1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="nitrogenBlanket2" id="nitrogenBlanket2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.nitrogenBlanket">
                                                <label class="custom-control-label" for="nitrogenBlanket2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.weekendDelivery||fieldVisiblity.weekendDeliveryYN">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="weekendDelivery"
                                                class="col-form-label cust-label">{{'WEEKEND_DELIVERY' |
                                                translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="fieldVisiblity.weekendDelivery">
                                            <input type="text" autocomplete="off" name="weekendDelivery"
                                                id="weekendDelivery" class="form-control"
                                                [(ngModel)]="requestData.weekendDelivery">
                                        </div>
                                        <div class="col-md-8 col-sm-12" *ngIf="fieldVisiblity.weekendDeliveryYN">
                                            <div style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="weekendDelivery" id="weekendDelivery1"
                                                        class="custom-control-input" value="Yes"
                                                        [(ngModel)]="requestData.weekendDelivery">
                                                    <label class="custom-control-label" for="weekendDelivery1">{{'YES' |
                                                        translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="weekendDelivery" id="weekendDelivery2"
                                                        class="custom-control-input" value="No"
                                                        [(ngModel)]="requestData.weekendDelivery">
                                                    <label class="custom-control-label" for="weekendDelivery2">{{'NO' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.groundVapourRecovery">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <Label for="groundVapourRecovery"
                                                class="col-form-label cust-label">{{'GROUND_VAP_RECOVERY' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="groundVapourRecovery1"
                                                    id="groundVapourRecovery1" class="custom-control-input"
                                                    value="Center" [(ngModel)]="requestData.groundVapourDelivery">
                                                <label class="custom-control-label"
                                                    for="groundVapourRecovery1">{{'CENTER' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="groundVapourRecovery2"
                                                    id="groundVapourRecovery2" class="custom-control-input" value="Rear"
                                                    [(ngModel)]="requestData.groundVapourDelivery">
                                                <label class="custom-control-label" for="groundVapourRecovery2">{{'REAR'
                                                    | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.centerRear">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <Label for="centerOrRear" class="col-form-label cust-label">{{'CENTER_REAR'
                                                | translate}}</Label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" autocomplete="off" name="centerOrRear" id="centerOrRear"
                                                class="form-control" [(ngModel)]="requestData.centerRear">
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.vacuumTrailer">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="VaccumTrail" class="col-form-label cust-label">{{'VACC_TRAILER'
                                                | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">

                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="vaccumeTrailer" id="vaccumeTrailer1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.vaccumeTrailer">
                                                <label class="custom-control-label" for="vaccumeTrailer1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="vaccumeTrailer" id="vaccumeTrailer2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.vaccumeTrailer">
                                                <label class="custom-control-label" for="vaccumeTrailer2">{{'NO' |
                                                    translate}}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.numberOfRailCarsRequired">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="railCarsRequired"
                                                class="col-form-label cust-label adjust-label">{{'NO_RAIL_CARS_REQ' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="railCarsRequired"
                                                id="railCarsRequired" class="form-control"
                                                [(ngModel)]="requestData.numberOfRailCarsRequired" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isMaterialPackagedLiquidInTotes">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="isMaterialPackagedLiquidInTotes"
                                                class="col-form-label cust-label adjust-label">{{'IS_MAT_PACK_AS_LIQUID'
                                                | translate}}</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">

                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="isMaterialPackagedLiquidInTotes"
                                                    id="isMaterialPackagedLiquidInTotes1" class="custom-control-input"
                                                    value="Yes"
                                                    [(ngModel)]="requestData.isMaterialPackagedLiquidInTotes">
                                                <label class="custom-control-label"
                                                    for="isMaterialPackagedLiquidInTotes1">{{'YES' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="isMaterialPackagedLiquidInTotes"
                                                    id="isMaterialPackagedLiquidInTotes2" class="custom-control-input"
                                                    value="No"
                                                    [(ngModel)]="requestData.isMaterialPackagedLiquidInTotes">
                                                <label class="custom-control-label"
                                                    for="isMaterialPackagedLiquidInTotes2">{{'NO' | translate}}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaningProcedures">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="cleaningProcedures"
                                                class="col-form-label cust-label">{{'CLEANING_PROCEDURES' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="cleaningProcedures"
                                                id="cleaningProcedures" class="form-control"
                                                [(ngModel)]="requestData.cleaningProcedures">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.mandDocsInsideTruck">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="mandateDocsInsideTruck"
                                                class="col-form-label cust-label">{{'MANDAT_DOCS_INSIDE_TRUCK' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12 mandateDocsFields">
                                            <div class="col-md-4 radio-fields" style="padding-left: 0px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="mandateDocsInsideTruck"
                                                        id="mandateDocsInsideTruck1" class="custom-control-input"
                                                        value="Yes" [(ngModel)]="requestData.mandadoryDocsInsideTruck"
                                                        (change)="onmandadoryDocsInsideTruck($event)">
                                                    <label class="custom-control-label"
                                                        for="mandateDocsInsideTruck1">{{'YES' | translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="mandateDocsInsideTruck"
                                                        id="mandateDocsInsideTruck2" class="custom-control-input"
                                                        value="No" [(ngModel)]="requestData.mandadoryDocsInsideTruck"
                                                        (change)="onmandadoryDocsInsideTruck($event)">
                                                    <label class="custom-control-label"
                                                        for="mandateDocsInsideTruck2">{{'NO' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4" style="padding: 0px !important;"
                                                *ngIf="!fieldVisiblity.mandDocsInsideTruckSa">
                                                <p-dropdown class="cust-dropdown vol-class"
                                                    name="mandatoryDocumentsInsideTruck"
                                                    [options]="requestMaster.mandatoryDocumentsInsideTruck"
                                                    [(ngModel)]="requestData.mandadoryDocsInsideTruckId"
                                                    placeholder="{{'Select the documents' | translate}}"></p-dropdown>
                                            </div>
                                            <div class="col-md-4 multiSelectStyle" style="padding: 0px !important;"
                                                *ngIf="fieldVisiblity.mandDocsInsideTruckSa">
                                                <p-multiSelect name="mandatoryDocumentsInsideTruck"
                                                    class="cust-dropdown vol-class"
                                                    [options]="requestMaster.mandatoryDocumentsInsideTruck"
                                                    [(ngModel)]="requestData.mandadoryDocsInsideTruckIdSelected"
                                                    aria.selectAll="all selected"
                                                    defaultLabel="{{'Select the documents' | translate}}"
                                                    (onChange)="onmandDocsChangeEvent($event)">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 attach" *ngIf="fieldVisiblity.mandDocsInsideTruckDoc">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="mandDocsInsideTruckDoc"
                                                class="cust-label col-form-label">{{'FISPQ_ATTACHMENT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <span *ngIf="is_FISPQ_fileUpload">{{requestData.FISPQFileName}}
                                                <span *ngIf="is_FISPQ_fileUpload" (click)="pFISPQFileUpload.clear()"
                                                    style="margin-left: 10px; cursor:pointer;color: blue;"
                                                    class="pi pi-close">{{'REMOVE' | translate}}</span>
                                            </span>
                                            <p-fileUpload #pFISPQFileUpload mode="basic" name="myfile[]"
                                                [showUploadButton]="false" [customUpload]="true"
                                                accept=".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx" [auto]="true"
                                                chooseLabel="{{'CLICK_BROWSE' | translate}}"
                                                (onClear)="OnRemoveFISPQAttachment()"
                                                (uploadHandler)="OnFISPQFileUpload($event,pFISPQFileUpload)">
                                            </p-fileUpload>
                                        </div>
                                    </div>
                                </div>


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.averageProduct">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="averageProduct"
                                                class="col-form-label cust-label">{{'AVERAGE_PRODUCT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="display: inline-flex;">
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="averageProduct" name="averageProduct"
                                                    [(ngModel)]="requestData.averageProduct"
                                                    (change)="onAnnualVolumeChange($event)"
                                                    (keypress)="numericOnly($event)">
                                            </div>
                                            <div class="col-md-6" style="padding: 0px !important;">
                                                <p-dropdown class="cust-dropdown vol-class" name="averageProductUOM"
                                                    [options]="requestMaster.averageProduct"
                                                    [(ngModel)]="requestData.averageProductId"
                                                    placeholder="{{'Select the UOM' | translate}}"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specificEquipmentType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="specificEquipmentType"
                                                class="col-form-label cust-label ">Vehicle Type Required </label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="specificEquipmentType" class="cust-dropdown"
                                                [options]="requestMaster.specificEquipementTypeMaster"
                                                [showClear]="isDropdownClear && requestData.specificEquipmentTypeId"
                                                [(ngModel)]="requestData.specificEquipmentTypeId" appendTo="body"
                                                placeholder="{{'Select the Specific Equipment Type' | translate}}">


                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.pump">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="pump" class="col-form-label cust-label">Pump</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="pump1" id="pump1" class="custom-control-input"
                                                    value="Yes" [(ngModel)]="requestData.pump">
                                                <label class="custom-control-label" for="pump1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="pump2" id="pump2" class="custom-control-input"
                                                    value="No" [(ngModel)]="requestData.pump">
                                                <label class="custom-control-label" for="pump2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.chassis">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="chassis" class="col-form-label cust-label ">{{'CHASSIS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="chassis" class="cust-dropdown"
                                                [options]="requestMaster.chassisMaster"
                                                [showClear]="isDropdownClear && requestData.chassisId"
                                                [(ngModel)]="requestData.chassisId" appendTo="body"
                                                placeholder="{{'Select the Chassis' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.groundOperatingEquipment">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="groundOperatingEquipment"
                                                class="col-form-label cust-label">Ground Operating Equipment</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="groundOperatingEquipment1"
                                                    id="groundOperatingEquipment1" class="custom-control-input"
                                                    value="Required" [(ngModel)]="requestData.groundOperatingEquipment">
                                                <label class="custom-control-label"
                                                    for="groundOperatingEquipment1">{{'Required' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="groundOperatingEquipment2"
                                                    id="groundOperatingEquipment2" class="custom-control-input"
                                                    value="Not required"
                                                    [(ngModel)]="requestData.groundOperatingEquipment">
                                                <label class="custom-control-label"
                                                    for="groundOperatingEquipment2">{{'Not required' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.onlyOnecompartments">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="onlyOnecompartments" class="col-form-label cust-label">Only 1
                                                Compartments</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="onlyOnecompartments1"
                                                    id="onlyOnecompartments1" class="custom-control-input"
                                                    value="Only one" [(ngModel)]="requestData.onlyOnecompartments">
                                                <label class="custom-control-label" for="onlyOnecompartments1">{{'Only
                                                    one' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="onlyOnecompartments2"
                                                    id="onlyOnecompartments2" class="custom-control-input"
                                                    value="No Restrictions"
                                                    [(ngModel)]="requestData.onlyOnecompartments">
                                                <label class="custom-control-label" for="onlyOnecompartments2">{{'No
                                                    Restrictions' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.couplings">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="couplings" class="col-form-label cust-label">{{'COUPLINGS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="couplings" id="couplings"
                                                class="form-control" [(ngModel)]="requestData.couplings">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.loadingPoint">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="loadingPoint"
                                                class="col-form-label cust-label ">{{'LOADING_POINT' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="loadingPoint" class="cust-dropdown"
                                                [options]="requestMaster.loadingPointMaster"
                                                [(ngModel)]="requestData.loadingPointId" appendTo="body"
                                                placeholder="{{'Select the Loading Point' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.gmp">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="gmp" class="col-form-label cust-label">{{'GMP' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="gmp1" id="gmp1" class="custom-control-input"
                                                    value="Yes" [(ngModel)]="requestData.gmp">
                                                <label class="custom-control-label" for="gmp1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="gmp2" id="gmp2" class="custom-control-input"
                                                    value="No" [(ngModel)]="requestData.gmp">
                                                <label class="custom-control-label" for="gmp2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.fami">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="fami" class="col-form-label cust-label">{{'FAMI' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="fami1" id="fami1" class="custom-control-input"
                                                    value="Yes" [(ngModel)]="requestData.fami">
                                                <label class="custom-control-label" for="fami1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="fami2" id="fami2" class="custom-control-input"
                                                    value="No" [(ngModel)]="requestData.fami">
                                                <label class="custom-control-label" for="fami2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isopa">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="isopa" class="col-form-label cust-label">{{'ISOPA' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="isopa1" id="isopa1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.isopa">
                                                <label class="custom-control-label" for="isopa1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="isopa2" id="isopa2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.isopa">
                                                <label class="custom-control-label" for="isopa2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.halalWash">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="halalWash" class="col-form-label cust-label">{{'HALAL_WASH' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="halalWash1" id="halalWash1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.halalWash">
                                                <label class="custom-control-label" for="halalWash1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="halalWash2" id="halalWash2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.halalWash">
                                                <label class="custom-control-label" for="halalWash2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.unloadingHose">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="unloadingHose"
                                                class="col-form-label cust-label">{{'UNLOADING_HOSE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="unloadingHose"
                                                id="unloadingHose" class="form-control"
                                                [(ngModel)]="requestData.unloadingHose">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.onboardCompressor">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="onBoardCompressor"
                                                class="col-form-label cust-label">{{'ON_BOARD_COMPRESSOR' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="onBoardCompressor1" id="onBoardCompressor1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.onboardCompressor">
                                                <label class="custom-control-label" for="onBoardCompressor1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="onBoardCompressor2" id="onBoardCompressor2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.onboardCompressor">
                                                <label class="custom-control-label" for="onBoardCompressor2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isoTankRentalRequirement">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="isoTankRentalRequirement"
                                                class="col-form-label cust-label ">{{'ISO_TANK_RENTAL_REQ' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="isoTankRentalRequirement" class="cust-dropdown"
                                                [options]="requestMaster.isoTankRentalRequirementMaster"
                                                [(ngModel)]="requestData.isoTankRentalRequirementId" appendTo="body"
                                                placeholder="{{'Select the ISO Tank Rental Requirement' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.isoTankRentalPeriod">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="isoTankRentalPeriod"
                                                class="col-form-label cust-label">{{'ISO_TANK_RENTAL_PERIOD' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="isoTankRentalPeriod"
                                                id="isoTankRentalPeriod" class="form-control"
                                                [(ngModel)]="requestData.isoTankRentalPeriod">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tankType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="tankType" class="col-form-label cust-label ">{{'TANK_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="tankType" class="cust-dropdown"
                                                [options]="requestMaster.tankTypeMaster"
                                                [(ngModel)]="requestData.tankTypeId" appendTo="body"
                                                placeholder="{{'Select the Tank Type' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specificRemarkForTankType">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="specificRemarkForTankType"
                                                class="col-form-label cust-label">{{'SPECIFIC_REMARK_FOR_TANK_TYPE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="specificRemarkForTankType"
                                                id="specificRemarkForTankType" class="form-control"
                                                [(ngModel)]="requestData.specificRemarkForTankType">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.topBottomLoad">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="topBottomLoad"
                                                class="col-form-label cust-label">{{'TOP_BOTTOM_LOAD' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="topBottomLoad1" id="topBottomLoad1"
                                                    class="custom-control-input" value="Top"
                                                    [(ngModel)]="requestData.topBottomLoad">
                                                <label class="custom-control-label" for="topBottomLoad1">{{'TOP' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="topBottomLoad2" id="topBottomLoad2"
                                                    class="custom-control-input" value="Bottom"
                                                    [(ngModel)]="requestData.topBottomLoad">
                                                <label class="custom-control-label" for="topBottomLoad2">{{'BOTTOM' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.topBottomUnload">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="topBottomUnload"
                                                class="col-form-label cust-label">{{'TOP_BOTTOM_UNLOAD' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="topBottomUnload1" id="topBottomUnload1"
                                                    class="custom-control-input" value="Top"
                                                    [(ngModel)]="requestData.topBottomUnload">
                                                <label class="custom-control-label" for="topBottomUnload1">{{'TOP' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="topBottomUnload2" id="topBottomUnload2"
                                                    class="custom-control-input" value="Bottom"
                                                    [(ngModel)]="requestData.topBottomUnload">
                                                <label class="custom-control-label" for="topBottomUnload2">{{'BOTTOM' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaningService">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <Label for="cleaningService"
                                                class="col-form-label cust-label">{{'CLEANING_SERVICE' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="cleaningService1" id="cleaningService1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.cleaningService">
                                                <label class="custom-control-label" for="cleaningService1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="cleaningService2" id="cleaningService2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.cleaningService">
                                                <label class="custom-control-label" for="cleaningService2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.cleaningFrequency">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="cleaningFrequency"
                                                class="col-form-label cust-label">{{'CLEANING_FREQUENCY' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="cleaningFrequency"
                                                id="cleaningFrequency" class="form-control"
                                                [(ngModel)]="requestData.cleaningFrequency">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingService">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="heatingService"
                                                class="col-form-label cust-label">{{'HEATING_SERVICE' |
                                                translate}}</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="heatingService1" id="heatingService1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.heatingService">
                                                <label class="custom-control-label" for="heatingService1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="heatingService2" id="heatingService2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.heatingService">
                                                <label class="custom-control-label" for="heatingService2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingTemperature">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="heatingTemperature"
                                                class="col-form-label cust-label">{{'HEATING_TEMP' | translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="heatingTemperature"
                                                id="heatingTemperature" class="form-control"
                                                [(ngModel)]="requestData.heatingTemperature">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.priorProductRestrictionDetails">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="priorProductRestrictionDetails"
                                                class="col-form-label cust-label">{{'PRIOR_PROD_RESTRIC_DETAILS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="priorProductRestrictionDetails"
                                                id="priorProductRestrictionDetails" class="form-control"
                                                [(ngModel)]="requestData.priorProductRestrictionDetails">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tariffZone">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="tariffZone" class="col-form-label cust-label">{{'TARIFF_ZONE' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="tariffZone" id="tariffZone"
                                                class="form-control" [(ngModel)]="requestData.tariffZone">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.dangerousGoodsClass">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="dangerousGoodsClass"
                                                class="col-form-label cust-label ">{{'DANGEROUS_GOODS_CLASS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="dangerousGoodsClass" class="cust-dropdown"
                                                [options]="requestMaster.dangerousGoodsClassMaster"
                                                [(ngModel)]="requestData.dangerousGoodsClassId" appendTo="body"
                                                placeholder="{{'Select the Dangerous Good Class' | translate}}">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.driverLanguage">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="driverLanguage" class="col-form-label cust-label ">Specific
                                                Driver Language</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="driverLanguage" class="cust-dropdown"
                                                [options]="requestMaster.driverLanguageMaster"
                                                [showClear]="isDropdownClear && requestData.driverLanguageId"
                                                [(ngModel)]="requestData.driverLanguageId" appendTo="body"
                                                placeholder="{{'Select the Driver Language' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.requiredCleaningProcedure">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="requiredCleaningProcedure"
                                                class="col-form-label cust-label ">Required Cleaning Procedure</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="requiredCleaningProcedure" class="cust-dropdown"
                                                [options]="requestMaster.requiredCleaningProcedureMaster"
                                                [showClear]="isDropdownClear && requestData.requiredCleaningProcedureId"
                                                [(ngModel)]="requestData.requiredCleaningProcedureId" appendTo="body"
                                                placeholder="{{'Select the required Cleaning Procedure' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.vacuumTestConfirmation">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="vacuumTestConfirmation" class="col-form-label cust-label">Vacuum
                                                Test Confirmation</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="vacuumTestConfirmation1"
                                                    id="vacuumTestConfirmation1" class="custom-control-input"
                                                    value="Required" [(ngModel)]="requestData.vacuumTestConfirmation">
                                                <label class="custom-control-label"
                                                    for="vacuumTestConfirmation1">{{'Required' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="vacuumTestConfirmation2"
                                                    id="vacuumTestConfirmation2" class="custom-control-input"
                                                    value="Not required"
                                                    [(ngModel)]="requestData.vacuumTestConfirmation">
                                                <label class="custom-control-label" for="vacuumTestConfirmation2">{{'Not
                                                    required' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.reactorLoading">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="reactorLoading" class="col-form-label cust-label">Reactor
                                                Loading</Label>
                                        </div>

                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="reactorLoading1" id="reactorLoading1"
                                                    class="custom-control-input" value="Yes"
                                                    [(ngModel)]="requestData.reactorLoading">
                                                <label class="custom-control-label" for="reactorLoading1">{{'YES' |
                                                    translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="reactorLoading2" id="reactorLoading2"
                                                    class="custom-control-input" value="No"
                                                    [(ngModel)]="requestData.reactorLoading">
                                                <label class="custom-control-label" for="reactorLoading2">{{'NO' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.heatingDuringTransport">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <Label for="heatingDuringTransport"
                                                class="col-form-label cust-label">Heating During Transport</Label>
                                        </div>
                                        <div class="col-md-8 col-sm-12" style="margin-top: 5px;">
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="heatingDuringTransport1"
                                                    id="heatingDuringTransport1" class="custom-control-input"
                                                    value="Required" [(ngModel)]="requestData.heatingDuringTransport">
                                                <label class="custom-control-label"
                                                    for="heatingDuringTransport1">{{'Required' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="heatingDuringTransport2"
                                                    id="heatingDuringTransport2" class="custom-control-input"
                                                    value="Not required"
                                                    [(ngModel)]="requestData.heatingDuringTransport">
                                                <label class="custom-control-label" for="heatingDuringTransport2">{{'Not
                                                    required' | translate}}</label>
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                <input type="radio" name="heatingDuringTransport3"
                                                    id="heatingDuringTransport3" class="custom-control-input"
                                                    value="Not allowed"
                                                    [(ngModel)]="requestData.heatingDuringTransport">
                                                <label class="custom-control-label" for="heatingDuringTransport3">{{'Not
                                                    allowed' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.specialCertification">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="specialCertification" class="col-form-label cust-label ">Special
                                                Certification</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="specialCertification" class="cust-dropdown"
                                                [options]="requestMaster.specialCertificationMaster"
                                                [showClear]="isDropdownClear && requestData.specialCertificationId"
                                                [(ngModel)]="requestData.specialCertificationId" appendTo="body"
                                                placeholder="{{'Select the Special Certification' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.hoseLength">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 ">
                                            <label for="hoseLength" class="col-form-label cust-label ">Hose
                                                Length</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">

                                            <p-dropdown name="hoseLength" class="cust-dropdown"
                                                [options]="requestMaster.hoseLengthMaster"
                                                [showClear]="isDropdownClear && requestData.hoseLengthId"
                                                [(ngModel)]="requestData.hoseLengthId" appendTo="body"
                                                placeholder="{{'Select the Hose Length' | translate}} ">

                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group wrap-div"
                                    *ngIf="fieldVisiblity.otherAdditionalServiceRequirement">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="otherAdditionalServiceRequirement"
                                                class="col-form-label cust-label">{{'OTHER_ADDITIONAL_SERV_REQ' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off"
                                                name="otherAdditionalServiceRequirement"
                                                id="otherAdditionalServiceRequirement" class="form-control"
                                                [(ngModel)]="requestData.otherAdditionalServiceRequirement">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.others">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <label for="others" class="col-form-label cust-label">{{'OTHERS' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" name="others" id="others"
                                                class="form-control" [(ngModel)]="requestData.others">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </p-accordionTab>
                    <!-- Service Provided Req End -->
                    <!-- Shipping details Start-->
                    <div id="shipping-detail">
                        <p-accordionTab header="{{'SHIPPING_DETAILS' | translate}}" [selected]="true">
                            <form autocomplete="off">
                                <div class="row">
                                    <div class="row col-md-12" style="align-items: baseline;">
                                        <div class="col-12 col-md-6" id="shipping-content"
                                            style="position: relative;top: -2px;">
                                            <div class="form-group col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12" style="background-color: #E1E1E1;">
                                                        <h6
                                                            style="text-align: center;margin-top: 5px;font-weight: 600;color: black;">
                                                            {{'ORIGIN' | translate}}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.originPlant" >
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom"
                                                     [ngClass]="fieldVisiblity.isOriginPlantCodeRequired?'cust-mandatory':''">
                                                        <label for="originPlant"
                                                            class="col-form-label cust-label">{{'ORIGIN_PLANT_CODE' | translate}}</label>
                                                    </div>
                                                    <div class="col-md-8">

                                                        <div *ngIf="!originPlantMode">
                                                            <p-autoComplete id="originPlant"
                                                                (onClear)="resetOriginPlantCodeData()"
                                                                [forceSelection]="true" name="originPlant"
                                                                [(ngModel)]="selectedOriginPlantCode"
                                                                [suggestions]="plantCodeShippingDetails.originPlantCodeMaster"
                                                                (onSelect)="onPlantSelected($event,false,0)"
                                                                [minLength]="3" field="label"
                                                                (completeMethod)="onPlantCodeCompleted($event,true,false,0)"></p-autoComplete>
                                                        </div>
                                                        <div *ngIf="editMode && originPlantMode">
                                                            <p style="margin-top:7px">{{requestData.originPlantCode}}
                                                                <span style="color:blue; cursor:pointer"
                                                                    (click)="ViewPlantComplete(true,false,0)">{{'EDIT_PLANT_CODE'
                                                                    | translate}}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.shippingName">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom">
                                                        <Label for="OrgShipperName"
                                                            class="col-form-label cust-label">{{'SHIPPER_NAME' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="OrgShipperName"
                                                            id="OrgShipperName" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginName">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginStreetAddress">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orgCity"
                                                            class="col-form-label cust-label">{{'STREET_ADDRESS' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="orginStreetAddress"
                                                            id="orginStreetAddress" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginStreetAddress">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginCity || fieldVisiblity.orginCityEMEA">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom"
                                                        *ngIf="fieldVisiblity.orginCity">
                                                        <Label for="orgCity" class="col-form-label cust-label">{{'CITY'
                                                            | translate}}</Label>
                                                    </div>
                                                    <div class="col-md-3 cust-mandatory ml-custom"
                                                        *ngIf="fieldVisiblity.orginCityEMEA">
                                                        <Label for="orgCity" class="col-form-label cust-label">Dep
                                                            City</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="orgCity"
                                                            id="orgCity" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginCity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginState">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom">
                                                        <Label for="orgState"
                                                            class="col-form-label cust-label">{{'STATE' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="orgState"
                                                            id="orgState" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginState">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginZipCode || fieldVisiblity.originZipCodeMandatory ">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom "
                                                        *ngIf="fieldVisiblity.orginZipCode" [ngClass]="{'cust-mandatory':fieldVisiblity.isOriginPostalCodeRequired}" >
                                                        <Label for="orgzipcode"
                                                            class="col-form-label cust-label">{{'ZIP_CODE' |
                                                            translate}}</Label>
                                                    </div>


                                                    <div class="col-md-3 col-sm-12  cust-mandatory ml-custom"
                                                        *ngIf="fieldVisiblity.originZipCodeMandatory">
                                                        <Label for="orgzipcode" class="col-form-label cust-label">Dep
                                                            Postal/Zip Code</Label>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="orgzipcode"
                                                            id="orgzipcode" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginZipCode">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginCountry || fieldVisiblity.orginCountryEMEA">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom"
                                                        *ngIf="fieldVisiblity.orginCountry">
                                                        <Label for="orgCountry"
                                                            class="col-form-label cust-label">{{'COUNTRY' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-3 cust-mandatory ml-custom"
                                                        *ngIf="fieldVisiblity.orginCountryEMEA">
                                                        <Label for="orgCountry" class="col-form-label cust-label">Dep
                                                            Country</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="orgCountry" styleClass="w-custom"
                                                            [options]="fieldVisiblity.lessThanTruckLoad?requestMaster.originCountryMaster:requestMaster.countryMaster"
                                                            [showClear]="isDropdownClear"
                                                            [(ngModel)]="requestData.orginCountry"
                                                            placeholder="{{'Select the Country' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginServingRailRoad">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orgzipcode"
                                                            class="col-form-label cust-label">{{'SERV_RAIL_ROAD' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="orginServingRailRoad" id="orginServingRailRoad"
                                                            class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginServingRailRoad">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginAlternateServingRailRoad">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orginAlternateServingRailRoad"
                                                            class="col-form-label cust-label adjust-label">{{'ALTERNATE_SERV_RAIL_ROAD'
                                                            | translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="orginAlternateServingRailRoad"
                                                            id="orginAlternateServingRailRoad"
                                                            class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginAlternateServingRailRoad">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginSiteCompetitivenessId">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orginSiteCompetitivenessId"
                                                            class="col-form-label cust-label">{{'SITE_COMETETIVENESS' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="orginSiteCompetitivenessId"
                                                            styleClass="w-custom"
                                                            [options]="requestMaster.siteCompetitiveness"
                                                            [(ngModel)]="requestData.orginSiteCompetitivenessId"
                                                            placeholder="{{'Select the Site Competitiveness' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginRailStation">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orginAlternateServingRailRoad"
                                                            class="col-form-label cust-label">{{'RAIL_STATION' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="orginRailStation"
                                                            id="orginRailStation" class="form-control w-custom"
                                                            [(ngModel)]="requestData.orginRailStation">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.orginLocationType">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orgLocationType"
                                                            class="col-form-label cust-label">{{'LOCATION_TYPE' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="orgLocationType" styleClass="w-custom"
                                                            [options]="requestMaster.locationTypeMaster"
                                                            [(ngModel)]="requestData.orginLocationTypeId"
                                                            placeholder="{{'Select the Location Type' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <!-- Destination -->
                                        <div class="col-12 col-md-6" id="shipping-content"
                                            style="position: relative;top: -2px;"
                                            *ngIf="!fieldVisiblity.multipleDestination">

                                            <div class="form-group col-md-12 ship-margin">
                                                <div class="row">
                                                    <div class="col-md-12" style="background-color: #E1E1E1;">
                                                        <h6
                                                            style="text-align: center;margin-top: 5px;font-weight: 600;color: black;">
                                                            {{'DESTINATION' | translate}}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationPlant">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <label for="destinationPlant"
                                                            class="col-form-label cust-label">{{'DESTINATION_PLANT_CODE'
                                                            | translate}}</label>
                                                    </div>
                                                    <div class="col-md-8">

                                                        <div *ngIf="!destinationPlantMode">
                                                            <p-autoComplete id="destinationPlant"
                                                                (onClear)="resetDestinationPlantCodeData()"
                                                                [forceSelection]="true" name="destinationPlant"
                                                                styleClass=" w-custom"
                                                                [(ngModel)]="selectedDestinationPlantCode"
                                                                [suggestions]="plantCodeShippingDetails.destinationPlantCodeMaster"
                                                                (onSelect)="onPlantSelected($event,false,0)"
                                                                [minLength]="6" field="label"
                                                                (completeMethod)="onPlantCodeCompleted($event,false,false,0)"></p-autoComplete>
                                                        </div>
                                                        <div *ngIf="editMode && destinationPlantMode">
                                                            <p style="margin-top:7px" class=" w-custom">
                                                                {{requestData.destinationPlantCode}} <span
                                                                    style="color:blue; cursor:pointer"
                                                                    (click)="ViewPlantComplete(false,false,0)">{{'EDIT_PLANT_CODE'
                                                                    | translate}}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.consigneeName">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom">
                                                        <Label for="consigneeName"
                                                            class="col-form-label cust-label">{{'CONSIGNEE_NAME' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="consigneeName"
                                                            id="consigneeName" class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationName">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationStreetAddress">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationStreetAddress"
                                                            class="col-form-label cust-label">{{'STREET_ADDRESS' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="destinationStreetAddress"
                                                            id="destinationStreetAddress" class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationStreetAddress">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationCity">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom">
                                                        <Label for="destinationCity"
                                                            class="col-form-label cust-label">{{'CITY' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="destinationCity"
                                                            id="destinationCity" class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationCity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationState">
                                                <div class="row">
                                                    <div class="col-md-3 cust-mandatory ml-custom">
                                                        <Label for="destinationState"
                                                            class="col-form-label cust-label">{{'STATE' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="destinationState"
                                                            id="destinationState" class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationState">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationZipCode || fieldVisiblity.distinationZipCodeMandatory">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom"
                                                        *ngIf="fieldVisiblity.destinationZipCode">
                                                        <Label for="destinationZipCode"
                                                            class="col-form-label cust-label">{{'ZIP_CODE' |
                                                            translate}}</Label>
                                                    </div>


                                                    <div class="col-md-3 col-sm-12  cust-mandatory"
                                                        *ngIf="fieldVisiblity.distinationZipCodeMandatory">
                                                        <Label for="destinationZipCode"
                                                            class="col-form-label cust-label">Postal/Zip Code Ship
                                                            to</Label>
                                                    </div>


                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off" name="destinationZipCode"
                                                            id="destinationZipCode" class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationZipCode">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationCountry">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationCountryId"
                                                            class="col-form-label cust-label">{{'COUNTRY' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="destinationCountryId" styleClass="w-custom"
                                                            [options]="fieldVisiblity.lessThanTruckLoad?requestMaster.destinationCountryMaster:requestMaster.countryMaster"
                                                            [showClear]="isDropdownClear"
                                                            [(ngModel)]="requestData.destinationCountry"
                                                            placeholder="{{'Select the Country' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationServingRailRoad">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationServingRailRoad"
                                                            class="col-form-label cust-label">{{'SERV_RAIL_ROAD' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="destinationServingRailRoad"
                                                            id="destinationServingRailRoad"
                                                            class="form-control  w-custom"
                                                            [(ngModel)]="requestData.destinationServingRailRoad">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationAlternateServingRailRoad">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationAlternateServingRailRoad"
                                                            class="col-form-label cust-label adjust-label">{{'ALTERNATE_SERV_RAIL_ROAD'
                                                            | translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="destinationAlternateServingRailRoad"
                                                            id="destinationAlternateServingRailRoad"
                                                            class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationAlternateServingRailRoad">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationSiteCompetitivenessId">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationSiteCompetitivenessId"
                                                            class="col-form-label cust-label">{{'SITE_COMETETIVENESS' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="destinationSiteCompetitivenessId"
                                                            styleClass="w-custom"
                                                            [options]="requestMaster.siteCompetitiveness"
                                                            [(ngModel)]="requestData.destinationSiteCompetitivenessId"
                                                            placeholder="{{'Select the Site Competitiveness' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationRailStation">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="orginAlternateServingRailRoad"
                                                            class="col-form-label cust-label">{{'RAIL_STATION' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <input type="text" autocomplete="off"
                                                            name="destinationRailStation" id="destinationRailStation"
                                                            class="form-control w-custom"
                                                            [(ngModel)]="requestData.destinationRailStation">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-12 ship-margin"
                                                *ngIf="fieldVisiblity.destinationLocationType">
                                                <div class="row">
                                                    <div class="col-md-3 ml-custom">
                                                        <Label for="destinationLocationType"
                                                            class="col-form-label cust-label">{{'LOCATION_TYPE' |
                                                            translate}}</Label>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <p-dropdown name="destinationLocationType" styleClass="w-custom"
                                                            [options]="requestMaster.locationTypeMaster"
                                                            [(ngModel)]="requestData.destinationLocationTypeId"
                                                            placeholder="{{'Select the Location Type' | translate}}"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-12 col-md-6" id="shipping-content"
                                            *ngIf="fieldVisiblity.multipleDestination">
                                            <div class="row col-md-12 mexicoShipping"
                                                *ngFor="let dest of requestData.multipleDestination;index as i;first as isFirst;last as isLast">
                                                <div class="col-11 col-md-11">
                                                    <p-accordion>

                                                        <p-accordionTab header="{{'DESTINATION' | translate}} {{i+1}}"
                                                            [selected]="requestData.multipleDestination.length===(i+1)">
                                                            <p-header>
                                                                {{'DESTINATION' | translate}} {{i+1}}
                                                            </p-header>
                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationPlant">
                                                                <div class="row">
                                                                    <div class="col-md-3">
                                                                        <label for="destinationPlant_{{i}}"
                                                                            class="col-form-label cust-label">{{'DESTINATION_PLANT_CODE'
                                                                            | translate}}</label>
                                                                    </div>
                                                                    <div class="col-md-9">

                                                                        <div *ngIf="!dest.destinationPlantMode">
                                                                            <p-autoComplete id="destinationPlant_{{i}}"
                                                                                (onClear)="resetMultipleDestinationPlantCodeData(i)"
                                                                                [forceSelection]="true"
                                                                                name="destinationPlant_{{i}}"
                                                                                [(ngModel)]="dest.selectedDestinationPlantCode"
                                                                                [suggestions]="plantCodeShippingDetails.destinationPlantCodeMaster"
                                                                                (onSelect)="onPlantSelected($event,true,i)"
                                                                                [minLength]="6" field="label"
                                                                                (completeMethod)="onPlantCodeCompleted($event,false,true,i)"></p-autoComplete>
                                                                        </div>
                                                                        <div
                                                                            *ngIf="editMode && dest.destinationPlantMode">
                                                                            <p style="margin-top:7px">
                                                                                {{dest.multipleDestinationPlantCode}}
                                                                                <span style="color:blue; cursor:pointer"
                                                                                    (click)="ViewPlantComplete(false,true,i)">{{'EDIT_PLANT_CODE'
                                                                                    | translate}}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.consigneeName">
                                                                <div class="row">
                                                                    <div class="col-md-3 cust-mandatory">
                                                                        <Label for="consigneeName_{{i}}"
                                                                            class="col-form-label cust-label">{{'CONSIGNEE_NAME'
                                                                            | translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <input type="text" autocomplete="off"
                                                                            name="consigneeName_{{i}}"
                                                                            id="consigneeName_{{i}}"
                                                                            class="form-control"
                                                                            [(ngModel)]="dest.multipleDestinationConsigneeName">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationStreetAddress">
                                                                <div class="row">
                                                                    <div class="col-md-3">
                                                                        <Label for="destinationStreetAddress_{{i}}"
                                                                            class="col-form-label cust-label">{{'STREET_ADDRESS'
                                                                            | translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <input type="text" autocomplete="off"
                                                                            name="destinationStreetAddress_{{i}}"
                                                                            id="destinationStreetAddress_{{i}}"
                                                                            class="form-control"
                                                                            [(ngModel)]="dest.destinationStreetAddress">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationCity || fieldVisiblity.destinationCityEMEA">
                                                                <div class="row">
                                                                    <div class="col-md-3 cust-mandatory"
                                                                        *ngIf="fieldVisiblity.destinationCity">
                                                                        <Label for="destinationCity_{{i}}"
                                                                            class="col-form-label cust-label">{{'CITY' |
                                                                            translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-3 cust-mandatory"
                                                                        *ngIf="fieldVisiblity.destinationCityEMEA">
                                                                        <Label for="destinationCity_{{i}}"
                                                                            class="col-form-label cust-label">Ship to
                                                                            City</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <input type="text" autocomplete="off"
                                                                            name="destinationCity_{{i}}"
                                                                            id="destinationCity_{{i}}"
                                                                            class="form-control"
                                                                            [(ngModel)]="dest.multipleDestinationCity">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationState">
                                                                <div class="row">
                                                                    <div class="col-md-3 cust-mandatory">
                                                                        <Label for="destinationState_{{i}}"
                                                                            class="col-form-label cust-label">{{'STATE'
                                                                            | translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <input type="text" autocomplete="off"
                                                                            name="destinationState_{{i}}"
                                                                            id="destinationState_{{i}}"
                                                                            class="form-control"
                                                                            [(ngModel)]="dest.multipleDestinationState">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationZipCode || fieldVisiblity.distinationZipCodeMandatory">
                                                                <div class="row">
                                                                    <div class="col-md-3 "
                                                                        *ngIf="fieldVisiblity.destinationZipCode">
                                                                        <Label for="destinationZipCode_{{i}}"
                                                                            class="col-form-label cust-label">{{'ZIP_CODE'
                                                                            | translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-3 cust-mandatory"
                                                                        *ngIf="fieldVisiblity.distinationZipCodeMandatory">
                                                                        <Label for="destinationZipCode_{{i}}"
                                                                            class="col-form-label cust-label">Postal/Zip
                                                                            Code Ship to</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <input type="text" autocomplete="off"
                                                                            name="destinationZipCode_{{i}}"
                                                                            id="destinationZipCode_{{i}}"
                                                                            class="form-control"
                                                                            [(ngModel)]="dest.multipleDestinationZipCode">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="form-group col-md-12 ship-margin"
                                                                *ngIf="fieldVisiblity.destinationCountry || fieldVisiblity.destinationCountryEMEA">
                                                                <div class="row">
                                                                    <div class="col-md-3"
                                                                        *ngIf="fieldVisiblity.destinationCountry">
                                                                        <Label for="destinationCountryId_{{i}}"
                                                                            class="col-form-label cust-label">{{'COUNTRY'
                                                                            | translate}}</Label>
                                                                    </div>
                                                                    <div class="col-md-3"
                                                                        *ngIf="fieldVisiblity.destinationCountryEMEA">
                                                                        <Label for="destinationCountryId_{{i}}"
                                                                            class="col-form-label cust-label">Country
                                                                            Ship to</Label>
                                                                    </div>
                                                                    <div class="col-md-9">
                                                                        <p-dropdown name="destinationCountryId_{{i}}"
                                                                            styleClass="cust-dropdown"
                                                                            [options]="requestMaster.countryMaster"
                                                                            [showClear]="isDropdownClear"
                                                                            [(ngModel)]="dest.multipleDestinationCountry"
                                                                            placeholder="{{'Select the Country' | translate}}"></p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </p-accordionTab>
                                                    </p-accordion>

                                                </div>

                                                <div class="col-md-1" *ngIf="isFirst">
                                                    <button pButton type="button" icon="pi pi-plus"
                                                        class="ui-button-success cust-icons"
                                                        style="color: white;background-color:#34A835 !important;"
                                                        (click)="onMultipleDestinationAdd()"></button>
                                                </div>

                                                <div class="col-md-1" *ngIf="!isFirst">
                                                    <button pButton type="button" icon="pi pi-times"
                                                        class="ui-button-danger cust-icons"
                                                        style=" color: white;background-color:#c01120 !important"
                                                        (click)="onMultipleDestinationRemove(i)"></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </p-accordionTab>
                    </div>
                    <!-- Shipping details End-->
                    <!-- Payer Option start-->

                    <p-accordionTab header="{{'PAYER_OPTION' | translate}}" [selected]="true"
                        *ngIf="((requestData.payerOption=='No')&&(fieldVisiblity.payer|| fieldVisiblity.routing||fieldVisiblity.tariffNumber||fieldVisiblity.freightBillAddress))">
                        <form autocomplete="off">
                            <div class="container-Wrap">
                                <!-- left -->


                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.payer">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="payer" class="col-form-label cust-label ">{{'PAYER' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" name="payer"
                                                id="payer" [(ngModel)]="requestData.payer">
                                        </div>
                                    </div>
                                </div>
                                <!-- right -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.routing">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="routing" class="col-form-label cust-label ">{{'ROUTING' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control" name="routing"
                                                id="routing" [(ngModel)]="requestData.routing">
                                        </div>
                                    </div>
                                </div>


                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.tariffNumber">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 cust-mandatory">
                                            <label for="tariffNumber" class="col-form-label cust-label ">{{'TARIFF_NO' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control"
                                                name="tariffNumber" id="tariffNumber"
                                                [(ngModel)]="requestData.tariffNumber">
                                        </div>
                                    </div>
                                </div>
                                <!-- left -->
                                <div class="form-group wrap-div" *ngIf="fieldVisiblity.freightBillAddress">
                                    <div class="row">
                                        <div class="col-md-3 cust-mandatory">
                                            <label for="freightBillAddress"
                                                class="col-form-label cust-label">{{'SEND_FREIGHTBILL_TO_ADD' |
                                                translate}}</label>
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <input type="text" autocomplete="off" class="form-control"
                                                id="freightBillAddress" name="freightBillAddress"
                                                [(ngModel)]="requestData.freightBillAddress">
                                        </div>
                                    </div>
                                </div>


                                <!-- right -->
                                <div class="form-group wrap-div">
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12">
                                            <!-- label -->
                                        </div>
                                        <div class="col-md-8 col-sm-12">
                                            <!-- control -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </p-accordionTab>
                    <!-- Payer Option end-->

                    <!-- Additional comments  start-->
                    <div id="comment-section">
                        <p-accordionTab header="{{'ADDITIONAL_COMMENTS_SECTION' | translate}}" [selected]="true">
                            <form autocomplete="off">
                                <div class="row" style="margin-right: 56px;">
                                    <div class="form-group col-md-12 attach"
                                        *ngIf="fieldVisiblity.customerPreferedCarrier">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12">
                                                <label for="preferredcarriers"
                                                    class="col-form-label cust-label">{{'CUST_PREFERRED_CARRIER' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <input type="text" autocomplete="off" name="vendordsInput"
                                                    id="vendordsInput" class="form-control"
                                                    [(ngModel)]="requestData.prefferedVendor">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach" *ngIf="fieldVisiblity.costVsService">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12 cust-mandatory">
                                                <label for="costvsservice"
                                                    class="col-form-label cust-label ">{{'COST_V_SERVICE' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <div class="custom-control custom-radio custom-control-inline"
                                                    style="z-index: 0 !important;">
                                                    <label class="custom-control-label "
                                                        style="margin-top:3px;margin-right:15px"
                                                        for="costvsservice2">{{'COST' |
                                                        translate}}</label>
                                                    <input type="radio" id="costvsservice1" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input "
                                                        value="c3" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label "
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice1">+3</label>
                                                    <input type="radio" id="costvsservice2" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input "
                                                        value="c2" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice2">+2</label>
                                                    <input type="radio" id="costvsservice3" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input"
                                                        value="c1" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice3">+1</label>
                                                    <input type="radio" id="costvsservice4" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input"
                                                        value="0" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice4">0</label>
                                                    <input type="radio" id="costvsservice5" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input"
                                                        value="s1" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice5">+1</label>
                                                    <input type="radio" id="costvsservice6" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input"
                                                        value="s2" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice6">+2</label>
                                                    <input type="radio" id="costvsservice7" name="costvsservice"
                                                        style="z-index: 0 !important;" class="custom-control-input"
                                                        value="s3" [(ngModel)]="requestData.costVsService">
                                                    <label class="custom-control-label"
                                                        style="margin-right:15px;margin-top:3px;"
                                                        for="costvsservice7">+3</label>
                                                    <label style="margin-left: 10px;"
                                                        style="margin-top:3px;margin-right:15px"
                                                        for="costvsservice2">{{'SERVICE' |
                                                        translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12" *ngIf="fieldVisiblity.comments">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12">
                                                <label for="comments" class="cust-label col-form-label">{{'COMMENTS' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <textarea rows="4" autocomplete="off" name="comments"
                                                    [(ngModel)]="requestData.additionalComments"
                                                    class="form-control w-full" style="width: 100%;"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12" *ngIf="fieldVisiblity.isSpecialRequirement">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12 cust-mandatory">
                                                <Label for="isSpecialRequirement"
                                                    class="col-form-label cust-label">{{'IS_SPECIAL_REQ' |
                                                    translate}}</Label>
                                            </div>
                                            <div class="col-md-10 col-sm-12" style="margin-top: 5px;">
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="isSpecialRequirement1"
                                                        id="isSpecialRequirement1" class="custom-control-input"
                                                        value="Yes" [(ngModel)]="requestData.isSpecialRequirement"
                                                        (change)="onSpecialRequirement($event)">
                                                    <label class="custom-control-label"
                                                        for="isSpecialRequirement1">{{'YES' | translate}}</label>
                                                </div>
                                                <div
                                                    class="custom-control custom-radio custom-control-inline req-radiobtndiv">
                                                    <input type="radio" name="isSpecialRequirement2"
                                                        id="isSpecialRequirement2" class="custom-control-input"
                                                        value="No" [(ngModel)]="requestData.isSpecialRequirement"
                                                        (change)="onSpecialRequirement($event)">
                                                    <label class="custom-control-label"
                                                        for="isSpecialRequirement2">{{'NO' | translate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach"
                                        *ngIf="fieldVisiblity.specailRequirementAttachment">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12 cust-mandatory">
                                                <label for="specailReqAttachment"
                                                    class="cust-label col-form-label">{{'SPECIAL_REQ_ATTACHMENT' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <span
                                                    *ngIf="is_SpecialReq_fileUpload">{{requestData.specialRequirementFileName}}
                                                    <span *ngIf="is_SpecialReq_fileUpload"
                                                        (click)="pSRFileUpload.clear()"
                                                        style="margin-left: 10px; cursor:pointer;color: blue;"
                                                        class="pi pi-close">{{'REMOVE' |
                                                        translate}}</span>
                                                </span>
                                                <p-fileUpload #pSRFileUpload mode="basic" name="myfile[]"
                                                    [showUploadButton]="false" [customUpload]="true"
                                                    accept=".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx" [auto]="true"
                                                    chooseLabel="{{'CLICK_BROWSE' | translate}}"
                                                    (onClear)="OnRemoveSpecialReqAttachment()"
                                                    (uploadHandler)="OnSpecialReqFileUpload($event,pSRFileUpload)">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach">
                                        <div *ngIf="fieldVisiblity.materialDoc && (userAccessPermission.roleId == 1||userAccessPermission.roleId==2)"
                                            class="row">
                                            <div class="col-md-2 col-sm-12">
                                                <label for="comments"
                                                    class="cust-label col-form-label">{{'MAT_&_SDS_DOCS' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12" style="padding-top: 8px;">

                                                <a target="_blank" style="color:blue" *ngIf="fieldVisiblity.sdsLink"
                                                    href="https://sds.basf.com/#/?q={{requestData.articleNumber}}&f=en_materialName,en_materialName,materialNumber&z=0&p=0">{{'PROD_SAFETY_DOC'|
                                                    translate}}</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach pt-5" *ngIf="fieldVisiblity.sdsAttachment">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12"
                                                [ngClass]="fieldVisiblity.is_sds_fileUpload_mandatory?'cust-mandatory':''">
                                                <label for="ssdAttachment"
                                                    class="cust-label col-form-label">{{'SDS_ATTACHMENT' |
                                                    translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <span *ngIf="is_sds_fileUpload">{{requestData.sdsFileName}}
                                                    <span *ngIf="is_sds_fileUpload" (click)="pFileUpload.clear()"
                                                        style="margin-left: 10px; cursor:pointer;color: blue;"
                                                        class="pi pi-close">{{'REMOVE' | translate}}</span>
                                                </span>
                                                <p-fileUpload #pFileUpload mode="basic" name="myfile[]"
                                                    [showUploadButton]="false" [customUpload]="true"
                                                    accept=".pdf, .doc, .docx" [auto]="true"
                                                    chooseLabel="{{'CLICK_BROWSE' | translate}}"
                                                    (onClear)="OnRemoveSDSAttachment()"
                                                    (uploadHandler)="OnSDSFileUpload($event,pFileUpload)">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10 offset-md-2 col-sm-12" *ngIf="fieldVisiblity.sdsNote">
                                                <label for="ssdAttachmentNote"
                                                    class="col-form-label note">{{'SDS_ATTACHMENT_NOTE' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach pt-5"
                                        *ngIf="fieldVisiblity.additionalAttachment">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12">
                                                <label for="additionalAttachment" class="cust-label col-form-label">
                                                    {{'ADDITIONAL_ATTACH_1' | translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <span
                                                    *ngIf="is_Additional_fileUpload1">{{requestData.additionalFileName}}<span
                                                        *ngIf="is_Additional_fileUpload1"
                                                        (click)="pAdditioanlFileUpload.clear()"
                                                        style="margin-left: 10px; cursor:pointer;color: blue;"
                                                        class="pi pi-close">{{'REMOVE' | translate}}</span></span>
                                                <p-fileUpload #pAdditioanlFileUpload mode="basic" name="myfile[]"
                                                    [showUploadButton]="false" [customUpload]="true"
                                                    accept=".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx" [auto]="true"
                                                    chooseLabel="{{'CLICK_BROWSE_ADDITIONAL_FILE' | translate}}"
                                                    (onClear)="OnRemoveAdditionalAttachment1()"
                                                    (uploadHandler)="OnAdditionalFileUpload1($event,pAdditioanlFileUpload)">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10 offset-md-2 col-sm-12"
                                                *ngIf="fieldVisiblity.additionalremarkNote">
                                                <label for="additionalAttachmentNote"
                                                    class="col-form-label note">{{'ADD_ATTACHMENT_NOTE' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12 attach pt-5"
                                        *ngIf="fieldVisiblity.additionalAttachment2">
                                        <div class="row">
                                            <div class="col-md-2 col-sm-12">
                                                <label for="additionalAttachment" class="cust-label col-form-label">
                                                    {{'ADDITIONAL_ATTACH_2' | translate}}</label>
                                            </div>
                                            <div class="col-md-10 col-sm-12">
                                                <span
                                                    *ngIf="is_Additional_fileUpload2">{{requestData.additionalFileName2}}<span
                                                        *ngIf="is_Additional_fileUpload2"
                                                        (click)="pAdditioanlFileUpload2.clear()"
                                                        style="margin-left: 10px; cursor:pointer;color: blue;"
                                                        class="pi pi-close">{{'REMOVE' | translate}}</span></span>
                                                <p-fileUpload #pAdditioanlFileUpload2 mode="basic" name="myfile[]"
                                                    [showUploadButton]="false" [customUpload]="true"
                                                    accept=".pdf, .doc, .docx, .txt, .csv, .xls, .xlsx" [auto]="true"
                                                    chooseLabel="{{'CLICK_BROWSE_ADDITIONAL_FILE' | translate}}"
                                                    (onClear)="OnRemoveAdditionalAttachment2()"
                                                    (uploadHandler)="OnAdditionalFileUpload2($event,pAdditioanlFileUpload2)">
                                                </p-fileUpload>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </p-accordionTab>
                    </div>
                    <!-- Additional comments  End-->
                </p-accordion>
                <div class="row" style="margin-top: 20px;">
                    <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
                        <p style="text-align: right">
                            <input type="button" class="btn btn-secondary cust-btns" value="{{'CANCEL' | translate}}"
                                (click)="OnCancel()">
                    </div>
                    <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
                        <p style="text-align: left">
                            <input *ngIf="!editMode" type="button" class="btn btn-primary cust-btns"
                                style="background-color: #004A96 !important;" value="{{'SUBMIT'| translate}}"
                                (click)="onSubmitRequest()">
                            <input *ngIf="editMode" type="button" class="btn btn-primary cust-btns"
                                style="background-color: #004A96 !important;" value="{{'UPDATE' | translate}}"
                                (click)="onSubmitRequest()">
                        </p>
                        <span *ngIf="!validSubmit" class="err">{{'MANDATORY_FIELDS_MSG' | translate}}</span>
                    </div>
                </div>
            </div>

        </div>

        <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff"
            type="line-scale-pulse-out"></ngx-spinner>
    </div>
</div>